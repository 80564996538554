import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'profile-cell',
  templateUrl: "./profile-renderer.component.html",
  
})
export class ProfileRenderer implements ICellRendererAngularComp {
  private params: any;
  private base64 : string;
  protected signedURLThumbnail: string = "";
  agInit(params: any): void {
    this.params = params;
    if(this.params.data.PrimaryPhotoArray.length > 0){
      this.signedURLThumbnail =  this.params.data.PrimaryPhotoArray[0].SignedURLThumbnail;
    } else {
      this.signedURLThumbnail = '../../assets/profile-picture/profileImage.jpg'
    }   
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  
}
