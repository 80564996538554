import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { OktaAuthService } from "@okta/okta-angular";
import { UserService } from "../services/user.service";
import { AuthService } from "../services/auth.service";
import { NotificationService } from "../services/notification.service";

@Injectable()
export class ViewControlGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const path = route.url[0].path;

    // // guarding routes for Master Data
    // if (path === "master-data") {
    //   if (
    //     this.userService.userFunctions["BuildingView"] ||
    //     this.userService.userFunctions["EntryPointView"] ||
    //     this.userService.userFunctions["EntryPointGroupView"] ||
    //     this.userService.userFunctions["EntryPointZoneView"] ||
    //     this.userService.userFunctions["ParkingLotView"] ||
    //     this.userService.userFunctions["ParkingLotGroupView"] ||
    //     this.userService.userFunctions["OrganizationView"]
    //   ) {
    //     return true;
    //   }
    // }

    // // guarding routes for Security
    // else if (path === "security") {
    //   if (
    //     this.userService.userFunctions["UserView"] ||
    //     this.userService.userFunctions["RoleView"]
    //   ) {
    //     return true;
    //   }
    // }

    // // guarding routes for Temporary Badge
    // else if (path === "temporary-badge") {
    //   if (this.userService.userFunctions["TemporaryBadge"]) {
    //     return true;
    //   }
    // }

    // // guarding routes for Person Of Interest
    // else if (path === "person-of-interest") {
    //   if (this.userService.userFunctions["POIView"]) {
    //     return true;
    //   }
    // }

    // // guarding routes for Call In Pass
    // else if (path === "call-in-pass") {
    //   if (this.userService.userFunctions["CallInPassView"]) {
    //     return true;
    //   }
    // }

    // // guarding routes for My Requests
    // else if (path === "my-requests") {
    //   if (this.userService.userFunctions["LimitRequestView"]) {
    //     return true;
    //   }
    // }

    // // guarding routes for Audits
    // else if (path === "audit") {
    //   if (this.userService.userFunctions["ViewPassAudit"]) {
    //     return true;
    //   }
    // }

    return false;
  }
}
