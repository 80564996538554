// Author: T4professor

import { Component, Inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DialogService } from '../services/dialog.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-button-renderer',  
  styleUrls: ["./button-renderer.component.css"],
  templateUrl: "./button-renderer.component.html",
 
})

export class ButtonRendererComponent implements ICellRendererAngularComp {
  params;
  label: string;
  constructor(@Inject(MAT_DIALOG_DATA)
  
  protected dialog: MatDialog,
  private dialogService: DialogService){
      
  }
  

  agInit(params): void {
    this.params = params;
    console.log(this.params+"on Init")
  }

  refresh(params: any): boolean {
    return true;
  }

  onClick($event) {
    console.log(this.params.data);
    var colId = this.params.colDef.field;
    var selectedData = this.params.api.getSelectedRows();
       //this.params.api.updateRowsData({remove: selectedData});
      console.log(selectedData);
        this.dialogService
        .openConfirmDialog(
          "Remove Attachment",
          "Are you sure you want to remove the attachment: "+this.params.data.LogicalFileName+" ?"
        )
        .afterClosed()
        .subscribe((confirm: boolean) => {
          if(confirm){
            this.params.node.setDataValue("Active",false);
          }
        });
        
   
  }
}