import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatAutocompleteTrigger,
} from "@angular/material";
import { PersonOfInterest } from "src/app/models/person-of-interest.model";
import { PersonOfInterestComponent } from "../../person-of-interest.component";
import { NgForm, FormGroup, FormControl, Validators } from "@angular/forms";
import { RequiredFieldsService } from "src/app/services/required-fields.service";
import { PersonOfInterestService } from "src/app/services/person-of-interest.service";
import { CommonService } from "src/app/services/common.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { DialogService } from "src/app/services/dialog.service";
import { HyperLinkComponent } from "src/app/hyperlink.component";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { GridApi } from "ag-grid-community";
import { PickList } from "src/app/models/pickList.model";
import { NotificationService } from "src/app/services/notification.service";
import { Base64Service } from "src/app/services/base64.service";
import { HttpErrorResponse } from "@angular/common/http";
import { POIRequester } from "src/app/models/poi-requester.model";
import moment from "moment";
import { Attachments } from "src/app/models/attachments.model";
import { Photos } from "src/app/models/photo.model";
import { UserService } from "src/app/services/user.service";
import { ViewPhotoModalComponent } from "src/app/renderer/view-photo/view-photo-modal.component";
import { SearchService } from "src/app/services/search.service";
import { NgxImageCompressService } from "ngx-image-compress";
import { basename } from "path";
import { cloneDeep } from "lodash";
import { Observable } from "rxjs";
import { map, startWith, min } from "rxjs/operators";
import { UtilityService } from "src/app/services/utility.service";
import { Phone } from "src/app/models/phone.model";
import { Address } from "src/app/models/address.model";
import { AddressModalComponent } from "src/app/renderer/address/address.modal.component";
// import { Notes } from "src/app/models/note.model";
import { EventModalComponent } from "src/app/renderer/event/event.modal.component";
import { Event } from "src/app/models/event.model";
import { DatePipe } from "@angular/common";
import { SliderRendererComponent } from "src/app/renderer/slider-renderer.component";
import { timeStamp } from "console";
import { TreeDropdownDynamicDatabaseService } from "src/app/services/tree-dropdown-dynamic-database.service";

@Component({
  selector: "app-create-person-of-interest",
  templateUrl: "./create-person-of-interest-modal.component.html",
  styleUrls: ["./create-person-of-interest-modal.component.css"],
})
export class CreatePersonOfInterestModalComponent implements OnInit {
  emailPattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  emailFormControl = new FormControl("", [
    Validators.email,
    Validators.pattern(this.emailPattern),
  ]);
  protected step = 0;

  protected thumbnailBase64: string = "";
  protected thumbnailSrc: string = "";
  protected personOfInterest: PersonOfInterest;
  protected genderList: PickList[];
  // protected restrictedReasonList: PickList[];
  protected restrictedTypeList: PickList[];
  protected restrictedTypeDropDown: PickList[];
  protected raceList: PickList[];
  protected hairList: PickList[];
  protected eyesList: PickList[];
  protected countryList: PickList[];
  protected stateList: PickList[];
  protected cityList: PickList[];
  protected isEditable:boolean = true;

  protected filteredCountries: Observable<string[]>;
  protected filteredStates: Observable<string[]>;
  protected filteredCities: Observable<string[]>;
  protected addedAliases: Array<String> = [];
  protected alias: string ='';
  protected phone : Phone = new Phone();
  protected restricted: boolean = false;
  // protected isRestrictedReasonloading: boolean = false;
  protected isGenderLoading: boolean = false;
  protected addressGridApi: GridApi;
  protected selectedAddressIndex : number = 0;
  protected selectedEventIndex: number = 0;
  protected addressRowSelected : boolean = false;
  protected eventRowSelected: boolean = false;
  protected eventGridApi: GridApi;
   protected attachmentGridApi: GridApi;
  protected defaultImageIndex = undefined;
  protected maxDate;

  protected birthYearRange: Array<number>;
  private minYear = 1900;
  private maxYear = new Date().getFullYear();

  protected birthMonthRange: Array<string>;
  protected birthDayRange: Array<number>;
  protected feet: number;
  protected inches: number;
  protected height: number;
  protected fileNames: Array<String> = [];
  //protected newNotes: string;
  protected POIRequesters: POIRequester[]= [];
  protected rowClassRules;

  viewOutput: MatDialogRef<ViewPhotoModalComponent>; // set values photo as default or Remove photo

  frameworkComponents: any;
  rowDataClicked = {};
  primaryPhotoId: number = 1;
  photoArray: Photos[] = [];
  protected addressCountry: any;
  protected addressState: any;
  protected addressCity: any;
  protected addressZip: any;

  path: string;
  attachmentArray: Array<Attachments> = [];
  rowData: Array<Attachments> = [];
  addressRowData : Array<Address> = [];
  eventRowData : Array<Event> = []
  protected countryNames: string[] = [];
  protected stateNames: string[] = [];
  protected cityNames: string[] = [];
  @ViewChild("formRef") createPoiForm: NgForm;
  @ViewChild("countryTrigger")
  @ViewChild("cityTrigger")
  @ViewChild("stateTrigger")
  countryAutoCompleteTrigger: MatAutocompleteTrigger;
  cityAutoCompleteTrigger: MatAutocompleteTrigger;
  statesAutoCompleteTrigger: MatAutocompleteTrigger;
  regionObjects : Array<{
    PersonOfInterestRegionID: number
    RegionID: string
    Active: number
  }> = [];
  addressColumnDefs = [

    
    {
      headerName: "",
      checkboxSelection: true,
      cellStyle: { color: "#000" },
      hide: false,     
      sortable: true,
      resizable: true,
      width: 30,
    },
    
    {
      headerName: "Address Type",
      field: "AddressType",
      cellStyle: { color: "#000" },
      hide: false,     
      sortable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.getPickListData(
         this.searchService.getTableName(),
        "AddressType"
      ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressType ? addressType : null;
        }
      },
      resizable: true,
      width: 100,
    },
    {
      headerName: "Default",     
      field:"Default",
      resizable: true,
      cellRenderer:(data) => {
        if(data.value){
          return "Yes";
        }else{
          return "No";
        }        
      },
      width: 70,
    },
    {
      headerName: "Address",
      field: "Address",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      width: 467,
    },
    {
      headerName: "Address 2",
      field: "Address2",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      width: 467,
    },    
    {
      headerName: "Country",
      field: "Country",
       hide: false,
      sortable: true,
      resizable: true,
       cellRenderer: (data) => {
        if (data) {
          this.addressCountry = this.commonService.getPickListData(
         this.searchService.getTableName(),
        "Country"
      ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return this.addressCountry ? this.addressCountry : null;
        }
      },
      width: 100,
    },
    
    {
      headerName: "State",
      field: "State",
      hide: false,
      sortable: true,
      resizable: true,
       cellRenderer: (data) => {
        if (data) {
        this.addressState = this.commonService.getPickListData(
        this.searchService.getTableName(),
        "State"
      ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return this.addressState ? this.addressState : null;
        }
      },
      width: 100,
    },
   

    {
      headerName: "City",
      field: "City",
      hide: false,
      sortable: true,
      resizable: true,
       cellRenderer: (data) => {
        if (data) {
         this.addressCity = this.commonService.getPickListData(
         this.searchService.getTableName(),
        "City"
      ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return this.addressCity ? this.addressCity : null;
        }
      },
      width: 100,
    },
    
    {
      headerName: "Zip",
      field: "Zip",
      hide: false,
      sortable: true,
      resizable: true,
       cellRenderer: (data) => {
        if (data) {
          this.addressZip = this.commonService.getPickListData(
         this.searchService.getTableName(),
        "Zip"
      ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return this.addressZip ? this.addressZip : null;
        }
      },
      width: 100,
    },
    
  ];

  eventColumnDefs = [
    {
      headerName: "Notes Type",
      field: "Type",
      checkboxSelection: true,
      cellStyle: { color: "#000" },
      hide: false,     
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let eventType = this.commonService.getPickListData(
         this.searchService.getTableName(),
        "EventType"
      ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return eventType ? eventType : null;
        }
      },
      width: 100,
    },
    
    {
      headerName: "Site",
      field: "SiteID",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.siteList.filter((e) => e.SiteID == +data.value)
            .map((e) => e.Name)[0];
          return addressType ? addressType : null;
        }
      },
      resizable: true,
      width: 100,
    },
    {
      headerName: "Description",
      field: "Description",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      width: 467,
    },    
    {
      headerName: "Start Time",
      field: "StartTime",
       hide: false,
       cellRenderer: (data) => {
            const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy");
            return formattedDate ? formattedDate : null;
          },
      sortable: true,
      resizable: true,
      width: 100,
    },
    {
      headerName: "End Time",
      field: "EndTime",
       hide: false,
       cellRenderer: (data) => {
            const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy");
            return formattedDate ? formattedDate : null;
          },
      sortable: true,
      resizable: true,
      width: 100,
    },
    
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: PersonOfInterest,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<CreatePersonOfInterestModalComponent>,
    protected requiredFieldService: RequiredFieldsService,
    protected personOfInterestService: PersonOfInterestService,
    protected commonService: CommonService,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected base64Service: Base64Service,
    protected userService: UserService,
    protected searchService: SearchService,
    protected imageCompress: NgxImageCompressService,
    protected utilService: UtilityService,
    protected datePipe: DatePipe,
    protected treeDropdownService: TreeDropdownDynamicDatabaseService
  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      hyperLinkComponent: HyperLinkComponent,
      sliderRenderer:SliderRendererComponent
    };
    this.step = 0;
    this.restricted = false;

     this.rowClassRules = {
      "row-ignore": (params) => {
        var condition = params.data.Default === true;
        return condition;
      }
    };

    this.birthYearRange = [];

    // populating the birth year range array []
    for (let i = this.minYear; i <= this.maxYear; i++) {
      this.birthYearRange[i - this.minYear] = i;
    }

    // birth month range
    this.birthMonthRange = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    this.birthDayRange = [];

    // populating birth day range
    for (let i = 0; i <= 30; i++) {
      this.birthDayRange[i] = i + 1;
    }
  }
  columnDefs = [
    {
      headerName: "Name",
      field: "BaseName",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      editable: true,
      resizable: true,
      width: 200,
    },
    {
      headerName: "Name",
      field: "LogicalFileName",
      cellStyle: { color: "#000" },
      hide: true,
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Description",
      field: "Description",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      editable: true,
      resizable: true,
      width: 467,
    },
    {
      headerName: "",
      cellRenderer: "hyperLinkComponent",
      resizable: true,
      cellRendererParams: {
        onClick: this.onBtnClick.bind(this),
        label: "Remove",
      },
      width: 100,
    },

    {
      headerName: "",
      cellRenderer: "buttonRenderer",
      resizable: true,
      cellRendererParams: {
        onClick: this.onBtnClick.bind(this),
        label: "Remove",
      },
      width: 100,
    },
    {
      headerName: "",
      field: "Active",
      resizable: true,
      hide: true,
    },
  ];

  

  onBtnClick(e) {
    this.rowDataClicked = e.rowData;
  }

  onSliderClick(e, x) {
    this.rowDataClicked = e.rowData;
  }

  onDateClick(e) {
    this.rowDataClicked = e.rowData;
  }

  ngDoCheck() {
    let regionObject = [];
    this.regionObjects = [];
    this.treeDropdownService.getSelectedDataRegions().subscribe( data => {
      data.forEach(region => {
        let object = {
          "PersonOfInterestRegionID": 0, 
          "RegionID": region.itemId, 
          "Active": 1
        }
        let isExist = false;
        regionObject.forEach(ele => {
          if(ele.RegionID === object.RegionID) {
            isExist = true;
          }
        });
        if(!isExist){
          regionObject.push(object);
        }
      });
      this.regionObjects = regionObject;
    });
  }

  ngOnInit(): void {
    this.maxDate = new Date();
    this.step = 0;
    this.personOfInterest = new PersonOfInterest(
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      null,
      null,
      "",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      null,
      null,
      "",
      [],
      new POIRequester(0, "", "", "", "", "", "", "", ""),
      [],
      [],
      "",
      "",
      [],
      [],
      [],
      [],
      [],
      []
    );
    this.searchService.setPageName("POIRequester");
    this.searchService
    .smartSearchAPI(null)
      .then(() => {
        this.POIRequesters = this.searchService.searchRecords;
        
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
    //intializes dropdowns
    this.searchService.setPageName("Person Of Interest");
    if (
      this.commonService.pickList != undefined &&
      this.commonService.pickList.filter(
        (f) => f.TableName === this.searchService.getPageName()
      ).length > 0
    ) {
      this.genderList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Gender"
      );
      this.raceList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Race"
      );
      this.hairList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Hair"
      );
      this.eyesList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Eyes"
      );
      // this.restrictedReasonList = this.commonService.getPickListData(
      //   "PersonOfInterest",
      //   "RestrictedReason"
      // );
      this.restrictedTypeList = this.commonService.getPickListData(
        "PersonOfInterest",
        "RestrictionType"
      );
      this.countryList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Country"
      );
      this.countryNames = this.countryList.map(function (item) {
        return item["DisplayValue"];
      });

      this.genderList.unshift(new PickList());
      this.raceList.unshift(new PickList());
      this.hairList.unshift(new PickList());
      this.eyesList.unshift(new PickList());
      this.restrictedTypeList.unshift(new PickList());
    //  this.restrictedReasonList.unshift(new PickList());
      this.countryList.unshift(new PickList());
    } else {
      this.commonService
        .getPickListApi()
        .then(() => {
          if (
            this.commonService.pickList != undefined &&
            this.commonService.pickList.length > 0
          ) {
            this.genderList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Gender"
            );
            this.raceList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Race"
            );
            this.hairList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Hair"
            );
            this.eyesList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Eyes"
            );
            this.restrictedTypeList = this.commonService.getPickListData(
              "PersonOfInterest",
              "RestrictionType"
            );
            this.restrictedTypeDropDown = this.restrictedTypeList.filter(obj => Object.keys(obj).length !== 0);
            this.countryList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Country"
            );
            this.countryNames = this.countryList.map(function (item) {
              return item["DisplayValue"];
            });
            this.genderList.unshift(new PickList());
            this.raceList.unshift(new PickList());
            this.hairList.unshift(new PickList());
            this.eyesList.unshift(new PickList());
            this.restrictedTypeList.unshift(new PickList());
         //   this.restrictedReasonList.unshift(new PickList());
            this.countryList.unshift(new PickList());
          }
        })
        .catch((err) => {
          this.notificationService.failure(err);
        });
    }
    
  }

  ngAfterViewChecked() {
     
    // this.requiredFieldService.setRequiredFields(
    //   this.searchService.getTableName(),
    //   this.createPoiForm
    // );
  
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

 

  onCellValueChanged(params) {
    var selectedRow = this.attachmentGridApi.getSelectedRows();

    if (params.colDef.field === "Active")
      this.removeAttachment(params.node.rowIndex);
    if (params.colDef.field === "BaseName") {
      params.node.setDataValue(
        "LogicalFileName",
        params.data.BaseName + "." + params.data.Ext
      );
    }
    if (params.colDef.field === "LogicalFileName") {
    }
  }


  onAddressCellValueChanged(params) {
    var index :number = 0;
   if(params.colDef.field == "Default"){
      this.addressRowData.forEach(element => {  
      if(params.node.rowIndex != index){
       element.Default = false;
      }else{
       element.Default = true;
      }       
      index++;
    });
    this.addressGridApi.setRowData(this.addressRowData); 
   }
  }


  

  
  onRowSelected(params) {
    var slctdData = this.attachmentGridApi.getSelectedRows();
  }

   onAddressRowSelected(params) {
    var slctdData = this.addressGridApi.getSelectedRows();
    this.selectedAddressIndex = params.rowIndex;
    this.addressRowSelected = !this.addressRowSelected;

  }

  onEventRowSelected(params) {
    var slctdData = this.eventGridApi.getSelectedRows();
    this.selectedEventIndex = params.rowIndex;
    this.eventRowSelected = !this.eventRowSelected;

  }

  makeDefault(event){
    var defaultIndex;
    var defaultAddress;
    var index :number = 0;  
    this.addressRowData.forEach(element => {            
      if(this.selectedAddressIndex != index){
       element.Default = false;
      }else{
       element.Default = true;
       defaultIndex = index;
       defaultAddress = element;
      }       
      index++;
    });   
    this.addressRowData.splice(defaultIndex,1)
    this.addressRowData.unshift(defaultAddress)
    this.addressGridApi.setRowData(this.addressRowData); 
    this.addressRowSelected  = !this.addressRowSelected
   
  }

  onCreatePOI() {
    this.personOfInterest.PhotosArray = this.photoArray.filter(
      (f) => f.Active == true
    );
    this.personOfInterest.AttachmentsArray = this.attachmentArray.filter(
      (f) => f.Active == true
    );
    var photoCount = 0;
    var attachmentCount = 0;
    this.personOfInterest.PhotosArray.forEach((element) => {
      element.Index = photoCount;
      element.LogicalFileName = element.BaseName + "." + element.Ext;
      photoCount++;
    });
    this.personOfInterest.AttachmentsArray.forEach((element) => {
      element.Index = attachmentCount;
      attachmentCount++;
    });
    

    // this.personOfInterest.BirthDay = this.createPoiForm.form.value.birthDayNum;
    // this.personOfInterest.BirthMonth = this.createPoiForm.form.value.birthMonthNum;
    // this.personOfInterest.BirthYear = this.createPoiForm.form.value.birthYearNum;
    
    // if(this.newNotes){
    //   this.personOfInterest.POINote.push({NoteID:0,Note:this.newNotes,XID:"",Active:true,CreatedBy:null,CreatedDate:null})
    // }
    this.personOfInterest.POIAddress = this.addressRowData;
    this.personOfInterest.POIEvent = this.eventRowData;
    this.personOfInterest.POIRegion = this.regionObjects; 
    this.personOfInterest.RestrictedOn = moment(this.personOfInterest.RestrictedOn).format("YYYY-MM-DD");
    this.personOfInterest.RestrictedEndDate = moment(this.personOfInterest.RestrictedEndDate).format("YYYY-MM-DD");

    this.personOfInterestService.createPersonOfInterest(
      this.personOfInterest,
      this.addedAliases
    );

    this.onNoClick();
  }

  

  compressFile(sourceImage) {
    console.warn(
      "Size in bytes was:",
      this.imageCompress.byteCount(sourceImage)
    );

    this.imageCompress.compressFile(sourceImage, 50, 50).then((result) => {
      console.warn(
        "Size in bytes is now:",
        this.imageCompress.byteCount(result)
      );
      alert("<img [src]='result' alt=''>");
    });
  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  addFieldValue() {
    this.addedAliases.push(this.alias);
    this.alias = "";
  }

  // Remove Alias array UI
  deleteFieldValue(index: number) {
    this.addedAliases = this.addedAliases.filter(
      (e) => e !== this.addedAliases[index]
    );
  }

  addPhoneValue() {
    this.phone.Active = 1;
    this.phone.PhoneID = 0;
    this.phone.XID = "";
    this.personOfInterest.POIPhone.push(this.phone);
    this.phone = new Phone();
  }

  // Remove Phone from UI
  deletePhoneValue(index: number) {    
     this.personOfInterest.POIPhone = this.personOfInterest.POIPhone.filter(
      (e) => e !== this.personOfInterest.POIPhone[index]
    );
  }

  addAddress() {
    // open widget configure dialog
    var address = new Address();
    address.AddressID = 0;
    address.AddressType = 0;
    address.Address = "";
    address.Address2 = "";
    address.Active = true;
    address.Default = false;
    address.City = 0;
    address.Country = 0;
    address.State = 0;
    address.Zip = 0;
    address.isEdit = false;

    const dialogRef = this.dialog
      .open(AddressModalComponent, {
        disableClose: true,
        data: address,        
      })
      .afterClosed()
      .subscribe((data) => {
        if(data){
          if(this.addressRowData.length == 0)data.Default = true;
          this.loadAddressDetails();
          setTimeout(() => {
            this.addressRowData.push(data);
            this.addressGridApi.setRowData(this.addressRowData);
          }, 2000)
        }
      });
  }

  editAddress() {
    var data = this.addressGridApi.getSelectedRows()[0];
    data.isEdit = true;
    const dialogRef = this.dialog
      .open(AddressModalComponent, {
        disableClose: true,
        data: this.addressGridApi.getSelectedRows()[0],
        // backdropClass: "backdropBackground",
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.addressRowData[this.selectedAddressIndex] = data;
          this.loadAddressDetails();
          setTimeout(() => {
            this.addressGridApi.setRowData(this.addressRowData);
            this.addressRowSelected = !this.addressRowSelected
          }, 2000)
        } else {
          this.addressRowData[this.selectedAddressIndex] = data;
          this.addressGridApi.setRowData(this.addressRowData);
          this.addressRowSelected = !this.addressRowSelected
        }

      });
  }

  loadAddressDetails() {
    this.commonService
      .getPickListApi()
      .then(() => {
        this.addressCountry = this.commonService.getPickListData(
          this.searchService.getTableName(),
          "Country"
        );
        this.addressState = this.commonService.getPickListData(
          this.searchService.getTableName(),
          "State"
        );
        this.addressCity = this.commonService.getPickListData(
          this.searchService.getTableName(),
          "City"
        );
        this.addressZip = this.commonService.getPickListData(
          this.searchService.getTableName(),
          "Zip"
        )
      })
      .catch((err) => {
        this.notificationService.failure(err);
      });
  }

  editEvent() {
    var data = this.eventGridApi.getSelectedRows()[0];
    data.IsEdit  = true;
    const dialogRef = this.dialog
      .open(EventModalComponent, {
        disableClose: true,
        data: this.eventGridApi.getSelectedRows()[0],
       // backdropClass: "backdropBackground",
      })
      .afterClosed()
      .subscribe((data) => {    
        if(data)  {
          this.eventRowData[this.selectedEventIndex] = data;
          this.eventGridApi.setRowData(this.eventRowData); 
          this.eventRowSelected  = !this.eventRowSelected
        }else{
          this.eventRowData[this.selectedEventIndex] = data;
          this.eventGridApi.setRowData(this.eventRowData); 
          this.eventRowSelected  = !this.eventRowSelected
        }
      
      });
  }

  async uploadPhotos(event) {
    var count: number = 0;
    var thumbnail;
    var orientation = -1;
    if (event.target.files) {
      for (var i = 0; i < event.target.files.length; i++) {
        const base64: any = await this.base64Service.encodeFileToBase64(
          event.target.files[i]
        );
        await this.imageCompress
          .compressFile(base64, orientation, 32, 32)
          .then((result) => {
            var file = this.dataURLtoFile(
              result,
              "Thumbnail_" + event.target.files[0].name
            );
            this.photoArray.push({
              XID: "",
              Active: true,
              PhotoID: 0,
              Description: "",
              LogicalFileName: event.target.files[i].name,
              S3KeyName: "",
              UserID: this.userService.userID,
              IsPrimary: false,
              SignedURL: base64,
              SignedURLThumbnail: "",
              File: event.target.files[i],
              ThumbnailFile: file,
              Index: count++,
              BaseName: "",
              Ext: "",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    var index: number = 0;
    this.photoArray.forEach((element) => {
      element.BaseName = element.LogicalFileName.replace(/\.[^/.]+$/, "");
      element.Ext =
        element.LogicalFileName.substring(
          element.LogicalFileName.lastIndexOf(".") + 1,
          element.LogicalFileName.length
        ) || "";
      element.Index = index;
      index++;
    });
    this.photoArray[0].IsPrimary = true;
  }

  setThumbnail(base64Thumbnail) {
  }

  thumbnailify(base64Image, targetSize, callback) {
    var img = new Image();

    img.onload = function () {
      var width = img.width,
        height = img.height,
        canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      canvas.width = canvas.height = targetSize;

      ctx.drawImage(
        img,
        width > height ? (width - height) / 2 : 0,
        height > width ? (height - width) / 2 : 0,
        width > height ? height : width,
        width > height ? height : width,
        0,
        0,
        targetSize,
        targetSize
      );
      callback(canvas.toDataURL());
    };

    img.src = base64Image;
  }

  private _filterCountries(value: string): string[] {
    const filterValue = value.toLowerCase();  

    return this.utilService.searchSortKeyUtil(this.countryNames, value);
  }

  private _filterStates(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.stateNames.filter((state) =>
      state.toLowerCase().includes(filterValue)
    );

    //return this.utilService.searchSortKeyUtil(this.stateNames, value);
  }

  private _filterCities(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.cityNames.filter((city) =>
      city.toLowerCase().includes(filterValue)
    );

    //return this.utilService.searchSortKeyUtil(this.cityNames, value);
  }

  //entry point type ahead
  displayPoints(point?: string): string | undefined {
    return point ? point : undefined;
  }

  async addAttachment(event) {
    if (event.target.files) {
      const filesLength: number = event.target.files.length;

      this.rowData = [];
      for (var i = 0; i < filesLength; i++) {
        this.path = "";
        var blob = new Blob([event.target.files[i]]);
        var url = window.URL.createObjectURL(blob);

        await this.attachmentArray.push({
          Active: true,
          AttachmentID: 0,
          Description: "",
          LogicalFileName: event.target.files[i].name,
          S3KeyName: "",
          UserID: this.userService.userID,
          XID: "",
          Path: event.target.files[i],
          SignedURL: url,
          File: event.target.files[i],
          Index: 0,
          BaseName: event.target.files[i].name.replace(/\.[^/.]+$/, ""),
          Ext:
            event.target.files[i].name.substring(
              event.target.files[i].name.lastIndexOf(".") + 1,
              event.target.files[i].name.length
            ) || "",
          OrginalFileName: event.target.files[i],
        });
      }
      this.rowData = this.attachmentArray;
      this.attachmentGridApi.setRowData(this.rowData);
      this.attachmentGridApi.refreshCells();
    }
  }

  viewImg(photo) {
    this.viewOutput = this.dialog.open(ViewPhotoModalComponent, {
      data: photo,
    });
    this.viewOutput.afterClosed().subscribe((action) => {
      if (action) {
        const index = this.photoArray.indexOf(photo);
        if (index !== -1) {
          const oldData = photo;
          if (action === "setPrimayPhoto") {
            this.photoArray.forEach((element) => {
              element.IsPrimary = false;
            });
            //this.photoArray.splice(photo);
            this.photoArray.splice(index, 1);
            this.photoArray.unshift({
              XID: oldData.XID,
              Active: oldData.Active,
              PhotoID: oldData.PhotoID,
              Description: oldData.Description,
              LogicalFileName: oldData.LogicalFileName,
              S3KeyName: oldData.S3KeyName,
              UserID: this.userService.userID,
              IsPrimary: true,
              SignedURL: oldData.SignedURL,
              SignedURLThumbnail: oldData.SignedURLThumbnail,
              File: oldData.File,
              ThumbnailFile: oldData.ThumbnailFile,
              Index: oldData.Index,
              BaseName: oldData.BaseName,
              Ext: oldData.Ext,
            });
            var i: number = 0;
            this.photoArray.forEach((element) => {
              element.Index = i;
              i++;
            });
            this.photoArray = cloneDeep(this.photoArray);
          } else if (action === "removePhoto") {
            this.photoArray[index] = {
              XID: oldData.XID,
              Active: false,
              PhotoID: oldData.PhotoID,
              Description: oldData.Description,
              LogicalFileName: oldData.LogicalFileName,
              S3KeyName: oldData.S3KeyName,
              UserID: this.userService.userID,
              IsPrimary: oldData.IsPrimary,
              SignedURL: oldData.SignedURL,
              SignedURLThumbnail: oldData.SignedURLThumbnail,
              File: oldData.File,
              ThumbnailFile: oldData.ThumbnailFile,
              Index: oldData.Index,
              BaseName: oldData.BaseName,
              Ext: oldData.Ext,
            };
            var i: number = 0;
            this.photoArray.forEach((element) => {
              element.Index = i;
              i++;
            });
            this.photoArray = cloneDeep(this.photoArray);
          } else if (action === "removeDefault") {
            this.photoArray[index] = {
              XID: oldData.XID,
              Active: oldData.Active,
              PhotoID: oldData.PhotoID,
              Description: oldData.Description,
              LogicalFileName: oldData.LogicalFileName,
              S3KeyName: oldData.S3KeyName,
              UserID: this.userService.userID,
              IsPrimary: false,
              SignedURL: "",
              SignedURLThumbnail: "",
              File: oldData.File,
              ThumbnailFile: oldData.ThumbnailFile,
              Index: oldData.Index,
              BaseName: oldData.BaseName,
              Ext: oldData.Ext,
            };
            var i: number = 0;
            this.photoArray.forEach((element) => {
              element.Index = i;
              i++;
            });
            this.photoArray = cloneDeep(this.photoArray);
          }
        }
      }
    });
  }

  //make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  //get grid
  onAttachmentGridReady(params) {
    this.attachmentGridApi = params.api;
  }

  onAddressGridReady(params) {
    this.addressGridApi = params.api;
  }
  onEventGridReady(params) {
    this.eventGridApi = params.api;
  }
  

  //sets the default image
  public setDefaultImage(imageIndex: number) {
    this.defaultImageIndex = imageIndex;
  }

  //removes the attachment from the Array

  public removeAttachment(index: number) {
    this.rowData.splice(index, 1);
    this.attachmentArray.splice(index, 1);
    this.attachmentGridApi.setRowData(this.rowData);
    this.attachmentGridApi.refreshCells();
  }

  protected validateDOBDate() {
    const birthYear: number =
      this.createPoiForm.form.value.birthYearNum === ""
        ? undefined
        : +this.createPoiForm.form.value.birthYearNum;

    const birthMonth: number =
      this.createPoiForm.form.value.birthMonthNum === ""
        ? undefined
        : +this.createPoiForm.form.value.birthMonthNum;

    let birthDay: number =
      this.createPoiForm.form.value.birthDayNum === ""
        ? undefined
        : +this.createPoiForm.form.value.birthDayNum;

    this.resetBirthDayRange(birthMonth, birthYear);
  }

  // resets the birth day range after the DOB drop down is changed
  protected resetBirthDayRange(monthNum?: number, year?: number) {
    let maxDate = -1;

    // month and year is not specified
    if (monthNum === undefined && year === undefined) {
      maxDate = 31;
    }

    // only year is specified
    else if (monthNum === undefined && year !== undefined) {
      maxDate = 31;
    }
    // its feb and year not specified
    else if (monthNum === 2 && year === undefined) {
      maxDate = 29;
    }

    // not feb and year is not specified
    else if (monthNum !== 2 && year === undefined) {
      maxDate = new Date(new Date().getFullYear(), monthNum, 0).getDate();
    }

    // everything is set
    else {
      maxDate = new Date(year, monthNum, 0).getDate();
    }

    this.birthDayRange = [];
    for (let i = 1; i <= maxDate; i++) {
      this.birthDayRange[i - 1] = i;
    }
  }
  //Convert base64 to file
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  onCountryAutocompleteFocus() {
    this.countryAutoCompleteTrigger._onChange("");
    this.countryAutoCompleteTrigger.openPanel();
  }

  convertPoundToKilo(){
    this.personOfInterest.WeightKilograms = +(this.personOfInterest.WeightPounds/2.2046).toFixed(2);
  }

  convertKiloToPound(){
    this.personOfInterest.WeightPounds= +(this.personOfInterest.WeightKilograms*2.2046).toFixed(2);
  }

  convertInchToMeter(){
    this.personOfInterest.HeightMeters = +(this.personOfInterest.HeightInches/39.370).toFixed(2);
  }

  convertMeterToInch(){
    this.personOfInterest.HeightInches = +(this.personOfInterest.HeightMeters*39.370).toFixed(2);
  }
 

  

  
 

  addEvent() {
    // open widget configure dialog
    const data = new Event();
    data.IsEdit = false;
    const dialogRef = this.dialog
      .open(EventModalComponent, {
        disableClose: true,
        data: data,
      })
      .afterClosed()
      .subscribe((data) => {
        if(data){
            data.Active = true;
          this.eventRowData.unshift(data);
          this.eventGridApi.setRowData(this.eventRowData); 
        }
      
       
      });
  }

  // removeNotes(index:number){
  //   this.personOfInterest.POINote.splice(index,1);
  // }

  // On POIRequestor Change

  onPoiRequesterChange(event){
    this.isEditable = false;
    this.personOfInterest.POIRequesterArray = event.value;
  }
  panelExpanded(){}

  formatSSNValue(value: string) {
    // Remove any non-alphanumeric characters
    value = value.replace(/\W/g, '');

    //for adding - after 1st 3 & next 2 cahracters
    if (value.length > 3 && value[3] !== '-') {
      value = value.substring(0, 3) + '-' + value.substring(3);
    }
    if (value.length > 6 && value[6] !== '-') {
      value = value.substring(0, 6) + '-' + value.substring(6);
    }
    // Limit to 9 characters
    this.personOfInterest.SSN = value.substring(0, 11);
  }
}
