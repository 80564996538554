import { Injectable, EventEmitter, Output } from "@angular/core";
import { HttpHeaders, HttpResponse, HttpClient } from "@angular/common/http";
import { SpinnerService } from "./spinner.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public userID: number;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public applicationId: number;
  public userApplications: Array<{
    ApplicationID: number;
    Name: string;
    Route: string;
  }>;
  public userEmail: string;
  private registeredUserGroups: Array<string> = [];
  private oktaUserInfo;
  private userAssociationInfo: {
    UserID: number;
    OKTAID: number;
    EmployeeRef: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Suffix: string;
    Email: string;
    Phone: string;
    PrefEmail: string;
    PrefPhone: string;
    Status: string;
    PIN: string;
    Active: number;
    ApplicationList: Array<{
      ADGroup: string;
      Description: string;
      ApplicationID: number;
      ApplicationName: string;
    }>;
  };

  public userAccessibleApplication: Array<any> = [];

  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService
  ) {
    // TODO: change the hard coded user values after OKTA integration
    // this.userID = 1;
    // this.applicationId = 1; //user application 1 => Driver, 2 => PersonOfInterest
    // this.userApplications = [
    //   { ApplicationID: 1, Name: "Driver" },
    //   { ApplicationID: 2, Name: "Person Of Interest" },
    // ];

    this.userApplications = [];
  }

  public getRegisteredUserGroups() {
    return [...this.registeredUserGroups];
  }

  public setRegisteredUserGroups(adGroups: Array<string>) {
    this.registeredUserGroups = [...adGroups];
  }

  // the method is invoked to display the initials on the nav bar
  public getInitials(): string {
    return (this.firstName.charAt(0) + this.lastName.charAt(0)).toUpperCase();
  }

  // the method is invoked to while the user tries to login to the application to get the User ID and associated application list
  public userLoginAPI() {
    const apiURL = environment.getAPI("verifyUserADAndGetApplicationList");

    const body = this.oktaUserInfo;

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.userAssociationInfo = { ...success.body[0] };
            console.log(success.body[0]);
            this.userID = success.body[0].UserID;
            this.populateUserApplications();
            this.spinnerService.setIsLoading(false);

            resolve();
          },
          (err) => {
            // error
            this.spinnerService.setIsLoading(false);
            reject(err);
          }
        );
    });
    return promise;
  }

  // the method is invoked to store the OKTA response received from /userinfo endpoint
  public setOktaUserInfo(userInfo) {
    this.oktaUserInfo = userInfo;
  }

  private populateUserApplications() {
    this.userAssociationInfo.ApplicationList.forEach((app) => {
      const routeName = this.getApplicationRoute(app.ApplicationName);
      this.userAccessibleApplication[routeName.replace("/", "")] = true;

      this.userApplications.push({
        ApplicationID: app.ApplicationID,
        Name: app.ApplicationName,
        Route: routeName,
      });
    });
  }

  public getApplicationRoute(appName: string): string {
    const routeName = appName.trim().toLowerCase().replace(" ", "");
    return "/" + routeName;
  }
}
