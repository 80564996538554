import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
export interface DialogData {}

interface Status {
  value: string;
  viewValue: string;
}
interface State {
  value: string;
  viewValue: string;
}
interface Certification {
  value: string;
  viewValue:string;
}

@Component({
  selector: "app-edit-record",
  templateUrl: "./edit-record.component.html",
  styleUrls: ["./edit-record.component.css"]
})

export class EditRecordComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {}
  states: State [] = [
    { value: "AL", viewValue: "Alabama"},
    { value: "AK", viewValue: "Alaska"},
    { value: "AZ", viewValue: "Arizona"},
    { value: "AR", viewValue: "Arkansas"},
    { value: "CA", viewValue: "California"},
    { value: "CO", viewValue: "Colorado"},
    { value: "CT", viewValue: "Connecticut"},
    { value: "DE", viewValue: "Delaware"},
    { value: "DC", viewValue: "District of Columbia"},
    { value: "FL", viewValue: "Florida"},
    { value: "GA", viewValue: "Georgia"},
    { value: "HI", viewValue: "Hiwaii"},
    { value: "ID", viewValue: "Idaho"},
    { value: "IL", viewValue: "Illinois"},
    { value: "IN", viewValue: "Indiana"},
    { value: "IA", viewValue: "Iowa"},
    { value: "KS", viewValue: "Kansas"},
    { value: "KY", viewValue: "Kentucky"},
    { value: "LA", viewValue: "Louisiana"},
    { value: "ME", viewValue: "Maine"},
    { value: "MD", viewValue: "Maryland"},
    { value: "MA", viewValue: "Massachusetts"},
    { value: "MI", viewValue: "Michigan"},
    { value: "MN", viewValue: "Minnesota"},
    { value: "MS", viewValue: "Mississippi"},
    { value: "MO", viewValue: "Missouri"},
    { value: "MT", viewValue: "Montana"},
    { value: "NE", viewValue: "Nebraska"},
    { value: "NV", viewValue: "Nevada"},
    { value: "NH", viewValue: "New Hampshire"},
    { value: "NJ", viewValue: "New Jersey"},
    { value: "NM", viewValue: "New Mexico"},
    { value: "NY", viewValue: "New York"},
    { value: "NC", viewValue: "North Carolia"},
    { value: "ND", viewValue: "North Dakota"},
    { value: "OH", viewValue: "Ohio"},
    { value: "OK", viewValue: "Oklahoma"},
    { value: "OR", viewValue: "Oregon"},
    { value: "PA", viewValue: "Pennsylvania"},
    { value: "RI", viewValue: "Rhode Island"},
    { value: "SC", viewValue: "South Carolina"},
    { value: "SD", viewValue: "South Dakota"},
    { value: "TN", viewValue: "Tennessee"},
    { value: "TX", viewValue: "Texas"},
    { value: "UT", viewValue: "Utah"},
    { value: "VT", viewValue: "Vermont"},
    { value: "VA", viewValue: "Virginia"},
    { value: "WA", viewValue: "Washington"},
    { value: "WV", viewValue: "West Virginia"},
    { value: "WI", viewValue: "Wisconsin"},
    { value: "WY", viewValue: "Wyoming"}
  ]
  //status dropdown options
  statuses: Status[] = [
    { value: "Active", viewValue: "Active" },
    { value: "Retired", viewValue: "Retired" },
    { value: "No Hire", viewValue: "No Hire" },
    { value: "Deceased", viewValue: "Deceased" }
  ];
    //self certification dropdown options
    certifications: Certification[] = [
      { value: "NA", viewValue: "NA" },
      { value: "NI", viewValue: "NI" }
    ];
  //autoformats ssn input
  ssnMask(event) {
    var val = event.target.value.replace(/\D/g, "");
    var newVal = "";
    if (val.length > 4) {
      event.target.value = val;
    }
    if (val.length > 3 && val.length < 6) {
      newVal += val.substr(0, 3) + "-";
      val = val.substr(3);
    }
    if (val.length > 5) {
      newVal += val.substr(0, 3) + "-";
      newVal += val.substr(3, 2) + "-";
      val = val.substr(5);
    }
    newVal += val;
    event.target.value = newVal.substring(0, 11);
  }
}
