import { Component, OnInit } from "@angular/core";
import { AuthService } from "./services/auth.service";
import { BootstrapService } from "./services/bootstrap.service";
import { OktaAuthService } from "@okta/okta-angular";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "rdb-project";

  constructor(
    protected authService: AuthService,
    private bootstrapService: BootstrapService,
    private oktaAuthService: OktaAuthService
  ) {}
  ngOnInit() {
    // this.authService.signedInFlag = true;
    this.oktaAuthService.setFromUri("/default-redirect");
    this.bootstrapService.callServices();
  }
}
