import { Component, OnInit, Inject, DoCheck, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { PersonOfInterest } from 'src/app/models/person-of-interest.model';
import { DialogService } from 'src/app/services/dialog.service';
import { PersonOfInterestService } from 'src/app/services/person-of-interest.service';
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Base64Service } from 'src/app/services/base64.service';
import { PickList } from 'src/app/models/pickList.model';
import { GridApi } from 'ag-grid-community';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { HyperLinkComponent } from 'src/app/hyperlink.component';
import { ViewPhotoModalComponent } from 'src/app/renderer/view-photo/view-photo-modal.component';
import { Photos } from 'src/app/models/photo.model';
import { Attachments } from 'src/app/models/attachments.model';
import { UserService } from 'src/app/services/user.service';
import { SearchService } from 'src/app/services/search.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { element } from 'protractor';
import { cloneDeep } from "lodash";
import { EventModalComponent } from "src/app/renderer/event/event.modal.component";
import { Event } from "src/app/models/event.model";
import { AddressModalComponent } from "src/app/renderer/address/address.modal.component";
import { Address } from "src/app/models/address.model";
import { Phone } from "src/app/models/phone.model";
import { POIRequester } from "src/app/models/poi-requester.model";
import { DatePipe } from "@angular/common";
import { TreeDropdownDynamicDatabaseService } from "src/app/services/tree-dropdown-dynamic-database.service";
import { HttpErrorResponse } from "@angular/common/http";
import moment from "moment";
export class Region{
  PersonOfInterestRegionID: number
    RegionID: string
    Active: number
}

@Component({
  selector: "app-update-person-of-interest",
  templateUrl: "./update-person-of-interest-modal.component.html",
  styleUrls: ["./update-person-of-interest-modal.component.css"],
})
export class UpdatePersonOfInterestModalComponent implements OnInit {
  emailPattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  emailFormControl = new FormControl("", [
    Validators.email,
    Validators.pattern(this.emailPattern),
  ]);
  protected step = 0;
  protected personOfInterest: PersonOfInterest;
  protected genderList: PickList[];
  //protected restrictedReasonList: PickList[];
  protected restrictedTypeList: PickList[];
  protected restrictedReasonDropDown: PickList[];
  protected restrictedTypeDropDown: PickList[];
  protected raceList: PickList[];
  protected hairList: PickList[];
  protected eyesList: PickList[];
  protected countryList: PickList[];
  protected stateList: Array<String>;
  protected cityList: Array<String>;
  protected isEditable:boolean = true;
  protected zipList: Array<String>;
  protected alias: string = '';
  protected restricted: boolean = false;
  //protected isRestrictedReasonloading: boolean = false;
  protected isGenderLoading: boolean = false;
  protected attachmentGridApi: GridApi;
  protected addressGridApi: GridApi;
  protected eventGridApi: GridApi;
  protected defaultImageIndex = undefined;
  protected maxDate;

  protected birthYearRange: Array<number>;
  private minYear = 1900;
  private maxYear = new Date().getFullYear();

  protected birthMonthRange: Array<string>;
  protected birthDayRange: Array<number>;
  protected feet:number = 0;
  protected inches: number =0;
  protected fileNames : String[] = [];
  // protected newNotes: string ="";
  protected height:string = "35px";
  protected POIRequesters: POIRequester[]= [];

  viewOutput: MatDialogRef<ViewPhotoModalComponent>; // set values photo as default or Remove photo

  frameworkComponents: any;
  rowDataClicked = {};
  primaryPhotoId: number = 1;
  photoArray : Array<Photos> = [];
  protected addressCountry: any;
  protected addressState: any;
  protected addressCity: any;
  protected addressZip: any;
  
  path: string;
  attachmentArray: Array<Attachments> = [];
  aliasesArray: Array<{
    Active: number
    AliasID: number
    Name: string
    UserID: number
  }> = [];
  deletedArray: Array<{
    Active: number
    AliasID: number
    Name: string
    UserID: number
  }> = [];
  newPhotoArray : Array<Photos> = [];
  newAttachmentsArray : Array<Attachments> = [];
  regionObjects : Array<{
    PersonOfInterestRegionID: number
    RegionID: string
    Active: number
  }> = [];
  protected selectedAddressIndex : number;
  protected addressRowSelected : boolean = false;
  protected selectedEventIndex : number;
  protected eventRowSelected : boolean = false;
  addressRowData : Array<Address> = [];
  eventRowData : Array<Event> = [];
  phone:  Phone = new Phone();
  rowData: Attachments[] = [];
  protected rowClassRules;
  selectedRegions: any[] = [];
  originalRegions: Region[] = [];

  @ViewChild("formRef") updatePoiForm: NgForm;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PersonOfInterest,
    protected dialogRef: MatDialogRef<UpdatePersonOfInterestModalComponent>,
    protected dialog: MatDialog,
    protected requiredFieldService: RequiredFieldsService,
    protected personOfInterestService: PersonOfInterestService,
    protected commonService: CommonService,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected base64Service: Base64Service,
    protected userService: UserService,
    protected searchService:SearchService,
    protected imageCompress:NgxImageCompressService,
    protected datePipe:DatePipe,
    protected treeDropdownService: TreeDropdownDynamicDatabaseService

  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      hyperLinkComponent: HyperLinkComponent,
    };
     this.rowClassRules = {
      "row-ignore": (params) => {
        var condition = params.data.Default === true;
        return condition;
      }
    }
    this.step = 0;
    this.restricted = false;


    this.birthYearRange = [];

    

    // populating the birth year range array []
    for (let i = this.minYear; i <= this.maxYear; i++) {
      this.birthYearRange[i - this.minYear] = i;
    }

    // birth month range
    this.birthMonthRange = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    this.birthDayRange = [];

    // populating birth day range
    for (let i = 0; i <= 30; i++) {
      this.birthDayRange[i] = i + 1;
    }
  }
  columnDefs = [
    {
      headerName: "Name",
      field: "BaseName",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      editable: true,
      resizable: true,
      width: 200,
    },
    {
      headerName: "Name",
      field: "LogicalFileName",
      cellStyle: { color: "#000" },
      hide: true,
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Description",
      field: "Description",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      editable: true,
      resizable: true,
      width: 467,
    },
    {
      headerName: "",      
      cellRenderer: "hyperLinkComponent",
      resizable: true,
      cellRendererParams: {
        onClick: this.onBtnClick.bind(this),
        label: "Remove",
      },
      width: 100,
    },
    
    {
      headerName: "",
      cellRenderer: "buttonRenderer",
      resizable: true,
      cellRendererParams: {
        onClick: this.onBtnClick.bind(this),
        label: "Remove",
      },
      width: 100,
    },
    {
      headerName: "",
      field: "Active",
      resizable: true,
      hide: true,
    },
  ];

 addressColumnDefs = [

    
    {
      headerName: "",
      checkboxSelection: true,
      cellStyle: { color: "#000" },
      hide: false,     
      sortable: true,
      resizable: true,
      width: 30,
    },
    
    {
      headerName: "Address Type",
      field: "AddressType",
      cellStyle: { color: "#000" },
      hide: false,     
      sortable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.getPickListData(
         this.searchService.getTableName(),
        "AddressType"
      ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressType ? addressType : null;
        }
      },
      resizable: true,
      width: 100,
    },
    {
      headerName: "Default",     
      field:"Default",
      resizable: true,
      cellRenderer:(data) => {
        if(data.value){
          return "Yes";
        }else{
          return "No";
        }        
      },
      width: 70,
    },
    {
      headerName: "Address",
      field: "Address",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      width: 467,
    },
    {
      headerName: "Address 2",
      field: "Address2",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      width: 467,
    },    
    {
      headerName: "Country",
      field: "Country",
       hide: false,
      sortable: true,
      resizable: true,
       cellRenderer: (data) => {
        if (data) {
          this.addressCountry = this.commonService.getPickListData(
         this.searchService.getTableName(),
        "Country"
      ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return this.addressCountry ? this.addressCountry : null;
        }
      },
      width: 100,
    },
    
    {
      headerName: "State",
      field: "State",
      hide: false,
      sortable: true,
      resizable: true,
       cellRenderer: (data) => {
        if (data) {
        this.addressState = this.commonService.getPickListData(
        this.searchService.getTableName(),
        "State"
      ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return this.addressState ? this.addressState : null;
        }
      },
      width: 100,
    },
   

    {
      headerName: "City",
      field: "City",
      hide: false,
      sortable: true,
      resizable: true,
       cellRenderer: (data) => {
        if (data) {
         this.addressCity = this.commonService.getPickListData(
         this.searchService.getTableName(),
        "City"
      ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return this.addressCity ? this.addressCity : null;
        }
      },
      width: 100,
    },
    
    {
      headerName: "Zip",
      field: "Zip",
      hide: false,
      sortable: true,
      resizable: true,
       cellRenderer: (data) => {
        if (data) {
          this.addressZip = this.commonService.getPickListData(
         this.searchService.getTableName(),
        "Zip"
      ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return this.addressZip ? this.addressZip : null;
        }
      },
      width: 100,
    },
    
  ];

  eventColumnDefs = [
    {
      headerName: "Notes Type",
      field: "Type",
      checkboxSelection: true,
      cellStyle: { color: "#000" },
      hide: false,     
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let eventType = this.commonService.getPickListData(
         this.searchService.getTableName(),
        "EventType"
      ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return eventType ? eventType : null;
        }
      },
      width: 100,
    },
    
    {
      headerName: "Site",
      field: "SiteID",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.siteList.filter((e) => e.SiteID == +data.value)
            .map((e) => e.Name)[0];
          return addressType ? addressType : null;
        }
      },
      resizable: true,
      width: 100,
    },
    {
      headerName: "Description",
      field: "Description",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      width: 467,
    },    
    {
      headerName: "Start Time",
      field: "StartTime",
       hide: false,
       cellRenderer: (data) => {
            const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy");
            return formattedDate ? formattedDate : null;
          },
      sortable: true,
      resizable: true,
      width: 100,
    },
    {
      headerName: "End Time",
      field: "EndTime",
       hide: false,
       cellRenderer: (data) => {
            const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy");
            return formattedDate ? formattedDate : null;
          },
      sortable: true,
      resizable: true,
      width: 100,
    },
    
  ];
  onBtnClick(e) {
    this.rowDataClicked = e.rowData;
  }

  onSliderClick(e, x) {
    this.rowDataClicked = e.rowData;
  }

  ngDoCheck() {
    let regionObject = [];

    this.treeDropdownService.getSelectedDataRegions().subscribe( data => {
    if(data) {
      this.regionObjects = [];
      data.forEach(region => {
        let object = {
          "PersonOfInterestRegionID": 0, 
          "RegionID": region.itemId, 
          "Active": 1
        }
        let isExist = false;
        regionObject.forEach(ele => {
          if(ele.RegionID === object.RegionID) {
            isExist = true;
          }
        });
        if(!isExist){
          regionObject.push(object);
        }
      });
      this.regionObjects = regionObject;
    } 
    });
  }

  ngOnInit(): void {   
   
   
  this.personOfInterest = { ...this.data };
  this.getPOIRegion();
  this.personOfInterestService.getPersonOfInterest(this.personOfInterest.PersonOfInterestID).then(() =>{  
      this.personOfInterest = this.personOfInterestService.personOfInterest[0];
          
      if( this.personOfInterestService.personOfInterest[0].POIRequesterArray && this.personOfInterestService.personOfInterest[0].POIRequesterArray) {
        this.personOfInterest.POIRequesterArray = this.personOfInterestService.personOfInterest[0].POIRequesterArray[0];
        this.isEditable = false;
       } else{
         this.personOfInterest.POIRequesterArray =  new POIRequester(0, "", "", "", "", "", "", "", "");
       }
        
      
      const activeAlias  = this.personOfInterest.AliasArray.filter((f) => f.Active && f.Autologous === 0);
      activeAlias.forEach(element => {
        var neededData = {
          Active : element.Active,
          AliasID : element.AliasID,
          Name : element.Name,
          UserID :  this.userService.userID
        };
        this.aliasesArray.push(neededData)
      });
    if(this.personOfInterest.PhotosArray.length > 0 || this.personOfInterest.AttachmentsArray.length > 0){
    this.commonService.getAllFiles(this.personOfInterest.PhotosArray.filter((f) => f.Active),this.personOfInterest.AttachmentsArray.filter((f) => f.Active),this.personOfInterest.PersonOfInterestID).then(() =>{
      this.personOfInterest.PhotosArray = this.commonService.allFiles[0].Photos;
      this.personOfInterest.AttachmentsArray = this.commonService.allFiles[0].Attachments;
      this.personOfInterest.AttachmentsArray.forEach(element => {
        element.BaseName = element.LogicalFileName.replace(/\.[^/.]+$/, ""),
        element.Ext = element.LogicalFileName.substring(element.LogicalFileName.lastIndexOf('.')+1, element.LogicalFileName.length)|| ""
        element.OrginalFileName = element.LogicalFileName
      });
      this.photoArray = this.personOfInterest.PhotosArray;
      var i:number = 0;
      this.photoArray.forEach(element => {
        element.IsPrimary = (Number(element.PhotoID) == Number(this.personOfInterest.PrimaryPhotoID))? true : false;        
        element.BaseName = element.LogicalFileName.replace(/\.[^/.]+$/, ""),
        element.Ext = element.LogicalFileName.substring(element.LogicalFileName.lastIndexOf('.')+1, element.LogicalFileName.length)|| ""
      });
      var photo : Photos[] = this.photoArray.filter((f)=> f.IsPrimary == true);
      if(photo.length > 0){
        var index = this.photoArray.indexOf(photo[0]);
        this.photoArray.splice(index, 1);
        this.photoArray.unshift(photo[0]);
      }
      this.photoArray.forEach(element => {
        element.Index = i;
        i++;
      });
      this.photoArray[0].IsPrimary = true;
      //this.personOfInterest.PrimaryPhotoID =  this.personOfInterest.PrimaryPhotoArray.
    }).catch((err)=>{
      this.notificationService.failure(err.msg);
    }).finally(() => {
      // this.rowData = this.personOfInterest.AttachmentsArray;
      // this.attachmentArray = this.personOfInterest.AttachmentsArray;
      // this.addressRowData = this.personOfInterest.POIAddress;
      // this.eventRowData = this.personOfInterest.POIEvent;
    });
  }
    }).catch((err)=>{
      this.notificationService.failure(err.msg);
    }).finally(() => {
      this.rowData = this.personOfInterest.AttachmentsArray;
      this.attachmentArray = this.personOfInterest.AttachmentsArray;
       this.addressRowData = this.personOfInterest.POIAddress;
      this.eventRowData = this.personOfInterest.POIEvent;
      
    });
    this.searchService.setPageName("POIRequester");
    this.searchService
    .smartSearchAPI(null)
      .then(() => {
        this.POIRequesters = this.searchService.searchRecords;
        
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
    //intializes dropdowns
    this.searchService.setPageName("Person Of Interest");

    if (
      this.commonService.pickList != undefined &&
      this.commonService.pickList.filter(
        (f) => f.TableName === this.searchService.getPageName()
      ).length > 0
    ) {
      this.genderList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Gender"
      );
      this.raceList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Race"
      );
      this.hairList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Hair"
      );
      this.eyesList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Eyes"
      );
      // this.restrictedReasonList = this.commonService.getPickListData(
      //   "PersonOfInterest",
      //   "RestrictedReason"
      // );
      this.restrictedTypeList = this.commonService.getPickListData(
        "PersonOfInterest",
        "RestrictionType"
      );
       this.countryList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Country"
      );
      
      
      this.genderList.unshift(new PickList());
      this.raceList.unshift(new PickList());
      this.hairList.unshift(new PickList());
      this.eyesList.unshift(new PickList());
      this.restrictedTypeList.unshift(new PickList());
   //   this.restrictedReasonList.unshift(new PickList());
      this.countryList.unshift(new PickList());
    } else {
      this.commonService
        .getPickListApi()
        .then(() => {
          if (
            this.commonService.pickList != undefined &&
            this.commonService.pickList.length > 0
          ) {
            this.genderList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Gender"
            );
            this.raceList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Race"
            );
            this.hairList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Hair"
            );
            this.eyesList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Eyes"
            );
            this.restrictedTypeList = this.commonService.getPickListData(
              "PersonOfInterest",
              "RestrictionType"
            );
            this.restrictedTypeDropDown = this.restrictedTypeList.filter(obj => Object.keys(obj).length !== 0);
             this.countryList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Country"
            );
            this.genderList.unshift(new PickList());
            this.raceList.unshift(new PickList());
            this.hairList.unshift(new PickList());
            this.eyesList.unshift(new PickList());
            this.restrictedTypeList.unshift(new PickList());
           // this.restrictedReasonList.unshift(new PickList());
            this.countryList.unshift(new PickList())
          }
        })
        .catch((err) => {
          this.notificationService.failure(err);
        });
    }
    
  }

  getPOIRegion() {
    this.personOfInterestService.getPOIRegion(this.personOfInterest.RecordID).then((result) => {
      this.selectedRegions = result.filter((f) => f.Active);
      this.originalRegions = result;
    });
    this.selectedRegions = this.selectedRegions.filter((f) => f.Active);

  }

  onAddressCellValueChanged(params) {
    var index :number = 0;
   if(params.colDef.field == "Default"){
      this.addressRowData.forEach(element => {     
      if(params.node.rowIndex != index){
       element.Default = false;
      }else{
       element.Default = true;
      }       
      index++;
    });
    this.addressGridApi.setRowData(this.addressRowData); 
   }
  }
  


  // Method to Update Parking Lot
  onUpdatePOI() {
   this.dialogService
      .openConfirmDialog(
        "Confirm Person Of Interest Update",
        "Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
    this.personOfInterest.BirthDay = this.updatePoiForm.form.value.birthDayNum;
    this.personOfInterest.BirthMonth = this.updatePoiForm.form.value.birthMonthNum;
    this.personOfInterest.BirthYear = this.updatePoiForm.form.value.birthYearNum;
    this.personOfInterest.AliasArray = this.aliasesArray.concat(this.deletedArray);
    this.personOfInterest.PhotosArray = [];
    this.personOfInterest.AttachmentsArray = [];
    this.newPhotoArray = [];
    this.newAttachmentsArray = [];
    var photoCount = 0;
    var attachmentCount = 0;
    this.photoArray.forEach(element => {
      element.Index = photoCount;
      element.LogicalFileName = element.BaseName+"."+element.Ext
      photoCount++;
    });
    this.attachmentArray.forEach(element => {
      element.Index = attachmentCount;
      attachmentCount++;
    });
    this.photoArray.forEach(element => {
      if((element.PhotoID > 0 ) || (element.PhotoID <=0 && element.Active)){
        this.personOfInterest.PhotosArray.push(element);       
      }
    });
    this.photoArray.forEach(element => {
      if((element.PhotoID <=0 && element.Active)){
        this.newPhotoArray.push(element);      
      }
    });
    this.attachmentArray.forEach(element => {
      if((element.AttachmentID > 0 ) || (element.AttachmentID <= 0 && element.Active)){
        this.personOfInterest.AttachmentsArray.push(element)        
      }
    });
    
    this.attachmentArray.forEach(element => {
      if((element.AttachmentID <=0 && element.Active)){
        this.newAttachmentsArray.push(element);       
      }
    });
   
    // if(this.newNotes){
    //   this.personOfInterest.POINote.push({NoteID:0,Note:this.newNotes,XID:"",Active:true,CreatedBy:null,CreatedDate:null})
    // }
    this.personOfInterest.POIAddress = this.addressRowData;
    this.personOfInterest.POIEvent = this.eventRowData;
    this.regionObjects.forEach(element => {
      var originalReg = this.originalRegions.find((f) => f.RegionID == element.RegionID);
      var index = -1;
     if(originalReg){       
       index = this.originalRegions.findIndex((f) => f.RegionID == element.RegionID);
       element.PersonOfInterestRegionID = originalReg.PersonOfInterestRegionID;
       this.originalRegions.splice(index,1)
     }
    });
    this.originalRegions.forEach(element => {
      element.Active = 0;
      this.regionObjects.push(element)
    });
    this.personOfInterest.POIRegion = this.regionObjects;
    this.personOfInterest.RestrictedOn = moment(this.personOfInterest.RestrictedOn).format("YYYY-MM-DD");
    this.personOfInterest.RestrictedEndDate = moment(this.personOfInterest.RestrictedEndDate).format("YYYY-MM-DD");
      this.personOfInterestService.updatePersonOfInterestModalComponent(
        this.personOfInterest,this.newPhotoArray,this.newAttachmentsArray
      ).then().catch((err) => {
        this.notificationService.failure(err)
      }).finally(()=> {
        this.onNoClick();
      });
        } 
      });

  }

 
  ngAfterViewChecked() {
    this.setRequiredFields();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setRequiredFields() {
    this.requiredFieldService.setRequiredFields(
      "PersonOfInterest",
      this.updatePoiForm
    );
  }
  onCellValueChanged(params){
    if (params.colDef.field === "Active")
    this.removeAttachment(params.node.rowIndex);
    if (params.colDef.field === "BaseName"){
      params.node.setDataValue("LogicalFileName",params.data.BaseName+"."+params.data.Ext);
     }
     if (params.colDef.field === "LogicalFileName"){
     }
    

  }
  onRowSelected(params){
   
    var slctdData = this.attachmentGridApi.getSelectedRows();
 }
 
 onAddressRowSelected(params) {
    var slctdData = this.addressGridApi.getSelectedRows();
    this.selectedAddressIndex = params.rowIndex;
    this.addressRowSelected = !this.addressRowSelected;

  }

  onEventRowSelected(params) {
    var slctdData = this.eventGridApi.getSelectedRows();
    this.selectedEventIndex = params.rowIndex;
    this.eventRowSelected = !this.eventRowSelected;

  }

  makeDefault(event){
    var defaultIndex;
    var defaultAddress;
    var index :number = 0;  
    this.addressRowData.forEach(element => {            
      if(this.selectedAddressIndex != index){
       element.Default = false;
      }else{
       element.Default = true;
       defaultIndex = index;
       defaultAddress = element;
      }       
      index++;
    });   
    this.addressRowData.splice(defaultIndex,1)
    this.addressRowData.unshift(defaultAddress)
    this.addressGridApi.setRowData(this.addressRowData); 
    this.addressRowSelected  = !this.addressRowSelected;
   
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

    //get grid
  onAttachmentGridReady(params) {
    this.attachmentGridApi = params.api;
  }

  onAddressGridReady(params) {
    this.addressGridApi = params.api;
  }
  onEventGridReady(params) {
    this.eventGridApi = params.api;
  }

  addFieldValue() {
    this.aliasesArray.push({ Name: this.alias, AliasID: 0, Active: 1, UserID: this.userService.userID });
    this.alias = "";
  }

  deleteFieldValue(index: number) {
    if (this.aliasesArray[index].AliasID > 0) {
      this.aliasesArray[index].Active = 0;
      this.deletedArray.push(this.aliasesArray[index]);
    } else {
      this.aliasesArray = this.aliasesArray.filter(
        (e) => e !== this.aliasesArray[index]
      );
    }
    this.aliasesArray = this.aliasesArray.filter((f) => f.Active);
  }

  //Add Phone

  addPhoneValue() {
    this.phone.Active = 1;
    this.phone.PhoneID = 0;
    this.phone.XID = "";
    this.personOfInterest.POIPhone.push(this.phone);
    this.phone = new Phone();
  }

  // Remove Phone from UI
  deletePhoneValue(index: number) { 
     
    if(this.personOfInterest.POIPhone[index].PhoneID == 0 ){
       this.personOfInterest.POIPhone = this.personOfInterest.POIPhone.filter(
      (e) => e !== this.personOfInterest.POIPhone[index]
    );
    }else{
      this.personOfInterest.POIPhone[index].Active = 0;  
    }
  }

  async uploadPhotos(event) {
    var count: number = 0;
    var orientation = -1;

    if (event.target.files) {
      for (var i = 0; i < event.target.files.length; i++) {
        count++;
        const base64: any = await this.base64Service.encodeFileToBase64(
          event.target.files[i]
        );
    
    await this.imageCompress.compressFile(base64, orientation,32, 32).then(
      (result) => {
        var file = this.dataURLtoFile(result,"Thumbnail_"+event.target.files[0].name);
        this.photoArray.push({
          XID: "",
          Active: true,
          PhotoID: 0,
          Description: "",
          LogicalFileName: event.target.files[i].name,
          S3KeyName: "",
          UserID: this.userService.userID,
          IsPrimary: false,
          SignedURL: base64,
          SignedURLThumbnail:"",
          File:event.target.files[i],
          ThumbnailFile: file,
          Index:0,
          BaseName:"",
          Ext:""
        });
      }
    ).catch((err) => {
      console.log(err)
    }); 
      }

    }
    var index:number = 0;
    this.photoArray.forEach(element => {
      element.BaseName = element.LogicalFileName.replace(/\.[^/.]+$/, "");
      element.Ext = element.LogicalFileName.substring(element.LogicalFileName.lastIndexOf('.')+1, element.LogicalFileName.length)|| ""; 
      element.Index = index;
      index++;      
    });
    this.photoArray[0].IsPrimary = true;
  }
  async addAttachment(event) {
    if (event.target.files) {
      const filesLength: number = event.target.files.length;      
      
      for (var i = 0; i < filesLength; i++) {
        this.path = "";
        var blob = new Blob([event.target.files[i]]);
        var url = window.URL.createObjectURL(blob);

      
    await  this.attachmentArray.push({
          Active: true,
          AttachmentID: 0,
          Description: "",
          LogicalFileName: event.target.files[i].name,
          S3KeyName: "",
          UserID: this.userService.userID,
          XID: "",
          Path: event.target.files[i],
          SignedURL: url,
          File: event.target.files[i],
          Index: 0,          
          BaseName:event.target.files[i].name.replace(/\.[^/.]+$/, ""),
          Ext:event.target.files[i].name.substring(event.target.files[i].name.lastIndexOf('.')+1, event.target.files[i].name.length)|| "",
          OrginalFileName:event.target.files[i].name
        });
      }
      this.rowData = this.attachmentArray;
      this.attachmentGridApi.setRowData(this.rowData);
      this.attachmentGridApi.refreshCells();

     
    }
  }

  viewImg(photo) {
    this.viewOutput = this.dialog.open(ViewPhotoModalComponent, {
      data: photo,
    });
    this.viewOutput.afterClosed().subscribe((action) => {
      if (action) {
        const index = this.photoArray.indexOf(photo);
        if (index !== -1) {
          const oldData = this.photoArray[index];
          if (action === "setPrimayPhoto") {
            this.photoArray.forEach((element) => {
              element.IsPrimary = false;
            });
            this.photoArray.splice(index, 1);

            this.photoArray.unshift({
              XID: oldData.XID,
              Active: oldData.Active,
              PhotoID: oldData.PhotoID,
              Description: oldData.Description,
              LogicalFileName: oldData.LogicalFileName,
              S3KeyName: oldData.S3KeyName,
              UserID: this.userService.userID,
              IsPrimary: true,
              SignedURL:oldData.SignedURL,
              SignedURLThumbnail:oldData.SignedURLThumbnail,
              File: oldData.File,
              ThumbnailFile: oldData.ThumbnailFile,
              Index:oldData.Index,              
              BaseName:oldData.BaseName,
              Ext:oldData.Ext
            });
            var i:number = 0;
            this.photoArray.forEach(element => {
              element.Index = i;
              i++;
            });
            this.photoArray = cloneDeep(this.photoArray);
          } else if (action === "removePhoto") {
            this.photoArray[index] = {
              XID: oldData.XID,
              Active: false,
              PhotoID: oldData.PhotoID,
              Description: oldData.Description,
              LogicalFileName: oldData.LogicalFileName,
              S3KeyName: oldData.S3KeyName,
              UserID: this.userService.userID,
              IsPrimary: false,
              SignedURL:oldData.SignedURL,
              SignedURLThumbnail: oldData.SignedURLThumbnail,
              File: oldData.File,
              ThumbnailFile: oldData.ThumbnailFile,
              Index:oldData.Index,
              BaseName:oldData.BaseName,
              Ext:oldData.Ext
            };
            var i:number = 0;
            this.photoArray.forEach(element => {
              element.Index = i;
              i++;
            });
            this.photoArray = cloneDeep(this.photoArray);
          } else if (action === "removeDefault") {
            this.photoArray[index] = {
              XID: oldData.XID,
              Active: oldData.Active,
              PhotoID: oldData.PhotoID,
              Description: oldData.Description,
              LogicalFileName: oldData.LogicalFileName,
              S3KeyName: oldData.S3KeyName,
              UserID: this.userService.userID,
              IsPrimary: false,
              SignedURL:oldData.SignedURL,
              SignedURLThumbnail: oldData.SignedURLThumbnail,
              File: oldData.File,
              ThumbnailFile: oldData.ThumbnailFile,
              Index:oldData.Index,
              BaseName:oldData.BaseName,
              Ext:oldData.Ext
            };
            var i:number = 0;
            this.photoArray.forEach(element => {
              element.Index = i;
              i++;
            });
            this.photoArray = cloneDeep(this.photoArray);
          }
          
        }
        
      }
    });
  }

  //make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  

  //sets the default image
  public setDefaultImage(imageIndex: number) {
    this.defaultImageIndex = imageIndex;
  }

  //removes the attachment from the Array

  public removeAttachment(index: number) {
    this.rowData.splice(index, 1);
    this.attachmentGridApi.setRowData(this.rowData);
    this.attachmentGridApi.refreshCells();
  }

  protected validateDOBDate() {
    const birthYear: number =
      this.updatePoiForm.form.value.birthYearNum === ""
        ? undefined
        : +this.updatePoiForm.form.value.birthYearNum;

    const birthMonth: number =
      this.updatePoiForm.form.value.birthMonthNum === ""
        ? undefined
        : +this.updatePoiForm.form.value.birthMonthNum;

    let birthDay: number =
      this.updatePoiForm.form.value.birthDayNum === ""
        ? undefined
        : +this.updatePoiForm.form.value.birthDayNum;

    this.resetBirthDayRange(birthMonth, birthYear);
  }

  // resets the birth day range after the DOB drop down is changed
  protected resetBirthDayRange(monthNum?: number, year?: number) {
    let maxDate = -1;

    // month and year is not specified
    if (monthNum === undefined && year === undefined) {
      maxDate = 31;
    }

    // only year is specified
    else if (monthNum === undefined && year !== undefined) {
      maxDate = 31;
    }
    // its feb and year not specified
    else if (monthNum === 2 && year === undefined) {
      maxDate = 29;
    }

    // not feb and year is not specified
    else if (monthNum !== 2 && year === undefined) {
      maxDate = new Date(new Date().getFullYear(), monthNum, 0).getDate();
    }

    // everything is set
    else {
      maxDate = new Date(year, monthNum, 0).getDate();
    }

    this.birthDayRange = [];
    for (let i = 1; i <= maxDate; i++) {
      this.birthDayRange[i - 1] = i;
    }
  }

  //Convert base64 to file 
  dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

 convertPoundToKilo(){
    this.personOfInterest.WeightKilograms = +(this.personOfInterest.WeightPounds/2.2046).toFixed(2);
  }

  convertKiloToPound(){
    this.personOfInterest.WeightPounds= +(this.personOfInterest.WeightKilograms*2.2046).toFixed(2);
  }

  convertInchToMeter(){
    this.personOfInterest.HeightMeters = +(this.personOfInterest.HeightInches/39.370).toFixed(2);
  }

  convertMeterToInch(){
    this.personOfInterest.HeightInches = +(this.personOfInterest.HeightMeters*39.370).toFixed(2);
  }

 
 

  addAddress() {
    // open widget configure dialog
    var address = new Address();
    address.AddressID = 0;
    address.AddressType = 0;
    address.Address = "";
    address.Address2 = "";
    address.Active = true;
    address.Default = false;
    address.City = 0;
    address.Country = 0;
    address.State = 0;
    address.Zip = 0;
    address.isEdit = false;

    const dialogRef = this.dialog
      .open(AddressModalComponent, {
        disableClose: true,
        data: address,        
      })
      .afterClosed()
      .subscribe((data) => {
        if(data){
          if(this.addressRowData.length == 0)data.Default = true;
          this.loadAddressDetails();
            setTimeout(() => {
              this.addressRowData.push(data);
              this.addressGridApi.setRowData(this.addressRowData);
            }, 2000)
        }
      });
  }

  editAddress() {
    var data = this.addressGridApi.getSelectedRows()[0];
    data.isEdit = true;
    const dialogRef = this.dialog
      .open(AddressModalComponent, {
        disableClose: true,
        data: this.addressGridApi.getSelectedRows()[0],
        // backdropClass: "backdropBackground",
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.addressRowData[this.selectedAddressIndex] = data;
          this.loadAddressDetails();
          setTimeout(() => {
            this.addressGridApi.setRowData(this.addressRowData);
            this.addressRowSelected = !this.addressRowSelected
          }, 2000)
        } else {
          this.addressRowData[this.selectedAddressIndex] = data;
          this.addressGridApi.setRowData(this.addressRowData);
          this.addressRowSelected = !this.addressRowSelected
        }

      });
  }

  
  loadAddressDetails() {
    this.commonService
      .getPickListApi()
      .then(() => {
        this.addressCountry = this.commonService.getPickListData(
          this.searchService.getTableName(),
          "Country"
        );
        this.addressState = this.commonService.getPickListData(
          this.searchService.getTableName(),
          "State"
        );
        this.addressCity = this.commonService.getPickListData(
          this.searchService.getTableName(),
          "City"
        );
        this.addressZip = this.commonService.getPickListData(
          this.searchService.getTableName(),
          "Zip"
        )
      })
      .catch((err) => {
        this.notificationService.failure(err);
      });
  }
 

  addEvent() {
    // open widget configure dialog
    const data = new Event();
    data.IsEdit = false;
    const dialogRef = this.dialog
      .open(EventModalComponent, {
        disableClose: true,
        data: data,
      })
      .afterClosed()
      .subscribe((data) => {
        if(data){
            data.Active = true;
          this.eventRowData.unshift(data);
          this.eventGridApi.setRowData(this.eventRowData); 
        }
      
       
      });
  }

  editEvent() {
    var data = this.eventGridApi.getSelectedRows()[0];
    data.IsEdit  = true;
    const dialogRef = this.dialog
      .open(EventModalComponent, {
        disableClose: true,
        data: this.eventGridApi.getSelectedRows()[0],
        //backdropClass: "backdropBackground",
      })
      .afterClosed()
      .subscribe((data) => {    
        if(data)  {
          this.eventRowData[this.selectedEventIndex] = data;
          this.eventGridApi.setRowData(this.eventRowData); 
          this.eventRowSelected  = !this.eventRowSelected
        }else{
          this.eventRowData[this.selectedEventIndex] = data;
          this.eventGridApi.setRowData(this.eventRowData); 
          this.eventRowSelected  = !this.eventRowSelected
        }
      
      });
  }

  // removeNotes(index:number){
  //   this.personOfInterest.POINote.splice(index,1);
  // }

  // On POIRequestor Change

  onPoiRequesterChange(event){
    this.isEditable = false;
    this.personOfInterest.POIRequesterArray = event.value;
  }
  panelExpanded(){}
  
  formatSSNValue(value: string) {
    // Remove any non-alphanumeric characters
    value = value.replace(/\W/g, '');

    //for adding - after 1st 3 & next 2 cahracters
    if (value.length > 3 && value[3] !== '-') {
      value = value.substring(0, 3) + '-' + value.substring(3);
    }
    if (value.length > 6 && value[6] !== '-') {
      value = value.substring(0, 6) + '-' + value.substring(6);
    }
    // Limit to 9 characters
    this.personOfInterest.SSN = value.substring(0, 11);
  }
}
