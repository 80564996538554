
import { Injectable, Output ,EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material";
import { CommonAlertDialogComponent } from "../common-alert-dialog/common-alert-dialog.component";
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component';

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(private dialog: MatDialog) {
    
  }
  message: string;
  title: string;

  // opens confirm dialog box
  openConfirmDialog(title: string, messsag: string) {
    return this.dialog.open(CommonConfirmDialogComponent, {
      width: "400px",
      height: "300px",
      panelClass: "commonconfirm-dialog-container",
      disableClose: true,
      position: { top: "19%" },
      data: {
        title: title,
        message: messsag,
      },
    });
  }

  // display alert popup
  openAlertDialogDialog(
    title: string,
    html: string,
    w: string = "400px",
    h: string = "200px"
  ) {
    return this.dialog.open(CommonAlertDialogComponent, {
      panelClass: "commonconfirm-dialog-container",
      disableClose: true,
      position: { top: "19%" },
      data: {
        title: title,
        message: html,
        width: w,
        height: h,
      },
    });
  }
}
