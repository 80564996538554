import { NgModule } from "@angular/core";
import {
  Routes,
  Router,
  RouterModule,
  PreloadAllModules,
} from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DriverComponent } from "./driver/driver.component";
import { LoginRegistrationComponent } from "./login-registration/login-registration.component";
import { Driver } from "./models/driver.model ";
import { PersonOfInterestComponent } from "./person-of-interest/person-of-interest.component";
import {
  OktaAuthGuard,
  OktaLoginRedirectComponent,
  OktaCallbackComponent,
} from "@okta/okta-angular";
import { DefaultRedirectComponent } from "./default-redirect/default-redirect.component";
import { LoggedInUserGuard } from "./guards/loggedInUserGuard";
import { UserRegisteredGuard } from "./guards/userRegisteredGuard";
import { ApplicationGuard } from "./guards/application.guard";

/**
 * The route naming convention that we need to follow for the applications are:
 *  a. Trim the application name
 *  b. Convert the name to lower case
 *  c. Replace any spaces '' i.e., " " => ''
 *  Example: ApplicationName : 'Person Of Interest'.trim().toLowerCase().replace(' ', '')
 */

const routes: Routes = [
  {
    path: "login",
    component: OktaLoginRedirectComponent,
    canActivate: [LoggedInUserGuard],
  },
  {
    path: "implicit/callback",
    component: OktaCallbackComponent,
  },
  {
    path: "default-redirect",
    component: DefaultRedirectComponent,
    pathMatch: "full",
    canActivate: [OktaAuthGuard],
  },
  {
    path: "transportationpeople",
    component: DriverComponent,
    pathMatch: "full",
    canActivate: [OktaAuthGuard, UserRegisteredGuard, ApplicationGuard],
  },
  {
    path: "personofinterest",
    component: PersonOfInterestComponent,
    pathMatch: "full",
    canActivate: [OktaAuthGuard, UserRegisteredGuard, ApplicationGuard],
  },
  {
    path: "",
    component: LoginRegistrationComponent,
    pathMatch: "full",
    canActivate: [LoggedInUserGuard],
  },
];

@NgModule({
  // imports: [
  //   RouterModule.forRoot(routes, {
  //     preloadingStrategy: PreloadAllModules,
  //   }),
  // ],
  // exports: [RouterModule],

  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  // constructor(router: Router) {}
}
