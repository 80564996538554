import { Injectable, EventEmitter, Output } from "@angular/core";
import { PersonOfInterest } from "../models/person-of-interest.model";
import { HttpHeaders, HttpClient, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "./notification.service";
import { Driver } from "../models/driver.model ";
import { UserService } from "./user.service";
import { CommonService } from "./common.service";
import { SearchService } from "./search.service";
import { Photos } from "../models/photo.model";
import { Attachments } from "../models/attachments.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DriverService {
  driver: Driver[];
  driverApiData: Driver[];
  @Output() driverDataChanged: EventEmitter<boolean>;
  SSNDuplicatecount: any;
  duplicateSSN: any;
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private userService: UserService,
    private commonService: CommonService,
    private searchService: SearchService
  ) {
    this.driver = [];
    this.driverDataChanged = new EventEmitter<boolean>();
  }

  // Method to return Driver array
  public getDriver(): Driver[] {
    return this.driver;
  }
  getDriverByID(driverID: number) {
    if (!this.driver.length) return [];
    return this.driver.filter((ua) => ua.TransportationPeopleID === driverID);
  }

  // Method to add a Driver
  public createDriver(driver: Driver) {
    this.createDriverAPI(driver).then(() => {});
  }

  public createDriverAPI(driver: Driver) {
    //const apiURL = "https://mibqahhybk.execute-api.us-west-2.amazonaws.com/dev/transportationPeople/transportationPeopleCreate";
    const apiURL = environment.getAPI("transportationPeopleCreate");

    const postBody = {
      ApplicationName: this.searchService.getTableName(),
      XID: "",
     // ResourceCode: driver.ResourceCode,
      GroupCode: "DRIVR",
      FirstName: driver.FirstName,
      MiddleName: driver.MiddleName,
      LastName: driver.LastName,
      SSN: driver.SSN,
      DLNumber: driver.DLNumber,
      DLExpirationDate: driver.DLExpirationDate,
      DLClassification: driver.DLClassification,
      DLState: driver.DLState,
      //DLEndorsement: driver.DLEndorsement,
      DLEndorsement: driver.DLEndorsement.map((a) => ({
        TransportationPeopleDLEndorsementID: 0,       
        DLEndorsementID:a.DLEndorsementID,
        DLEndorsementName: a.DLEndorsementName,
        Active : 1
      })),
      DLMedCertExpirationDt: driver.DLMedCertExpirationDt,
      //DLRestrictions: driver.DLRestrictions,
      DLRestrictions: driver.DLRestrictions.map((a) => ({
        TransportationPeopleDLRestrictionID: 0,       
        DLRestrictionID:a.DLRestrictionID,
        DLRestrictionName: a.DLRestrictionName,
        Active : 1
      })),
      Local399Grouping: driver.Local399Grouping,
      Status: driver.Status,
      BirthDate: driver.BirthDate,
      ResourceType: driver.ResourceType,
      Memo: driver.Memo,
      Email: driver.Email,
      EmgContactName: driver.EmgContactName,
      EmgContactRelationship: driver.EmgContactRelationship,
      EmgContactPhone: driver.EmgContactPhone,
      HireDate: driver.HireDate,
      SelfCertification: driver.SelfCertification,
      LastWorked: driver.LastWorked,
      PullNoticeDate: driver.PullNoticeDate,
      AnnualReviewDate: driver.AnnualReviewDate,
      PrimaryPhotoID: driver.PrimaryPhotoID,
      Active: "1",
      UserID: this.userService.userID,
      PhotoArray: driver.PhotosArray.map((pa) => ({
        PhotoID: 0,
        XID: "",
        LogicalFileName: pa.LogicalFileName,
        Description: pa.Description,
        S3KeyName: pa.S3KeyName,
        Active: pa.Active,
        UserID: this.userService.userID,
        IsPrimary: pa.IsPrimary,
        Index: pa.Index,
      })),
      AttachmentArray: driver.AttachmentsArray.map((a) => ({
        AttachmentID: 0,
        XID: "",
        LogicalFileName: a.LogicalFileName,
        Description: a.Description,
        S3KeyName: a.S3KeyName,
        Active: a.Active,
        UserID: this.userService.userID,
        Index: a.Index,
      })),
      TransportationPeopleAddress : driver.TransportationPeopleAddress.map((a) => ({
        Default:a.Default,
        AddressID: 0,       
        AddressType:a.AddressType,
        Address:a.Address,
        Address2:'',
        City:a.City,
        State:a.State,
        Zip:a.Zip,
        Country:a.Country,
        Active : a.Active
      })),
      TransportationPeoplePhone: driver.TransportationPeoplePhone.map((a) => ({
        PhoneID: 0,       
        PhoneType:a.PhoneType,
        PhoneNum:a.PhoneNum,
        Active : 1
      })),
      TransportationPeopleNote :driver.TransportationPeopleNote.map((a) => ({
        NoteID: 0,
        Note: a.Note,
        Active : 1
      })),
      TransportationPeopleEvent: [],
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            const res = success.body;
            var photosCount: number = 0;
            var attachmentsCount: number = 0;

            if (driver.PhotosArray) {
              driver.PhotosArray.forEach((element) => {
                var photo: any = res.PhotoArray.filter(
                  (f) => f.Index === element.Index
                );
                this.commonService
                  .uploadFiles(photo[0].signedURL, element.File)
                  .then((res) => {
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                this.commonService
                  .uploadFiles(
                    photo[0].signedURLThumbnail,
                    element.ThumbnailFile
                  )
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                photosCount++;
              });
            }
            if (driver.AttachmentsArray) {
              driver.AttachmentsArray.forEach((element) => {
                var attachment: any = res.AttachmentArray.filter(
                  (f) => f.Index == element.Index
                );
                this.commonService
                  .uploadFiles(attachment[0].signedURL, element.File)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                attachmentsCount++;
              });
            }
            this.driverDataChanged.emit(true);
            this.notificationService.success(success.body.Message);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }


  searchSssnAPI(SSN: string): Promise<any> {
    const apiURL = environment.getAPI("transportationPeopleCheckDuplicateSSN");
    const body = {
      SSN: SSN
    };
    const httpOptions: { headers; observe } = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
          observe: "response",
        };
    return new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: any) => {
            // success
            this.duplicateSSN = success.body;
            resolve(success); // Resolve with full response
          },
          (err: HttpErrorResponse) => {
            // error
            console.error(err.error);
            console.error(err);
            if (err.status === 422) {
              // Handle 422 error if needed
            }
            reject(err);
          }
        );
    });
  }
  
  public updateDriver(
    driver: Driver,
    newPhotoArray: Photos[],
    newAtaachmentArray: Attachments[]
  ) {
    //const apiURL = "https://mibqahhybk.execute-api.us-west-2.amazonaws.com/dev/transportationPeople/transportationPeopleUpdate";
    const apiURL = environment.getAPI("transportationPeopleUpdate");
    const postBody = {
      ApplicationName: this.searchService.getTableName(),
      TransportationPeopleID: driver.TransportationPeopleID,
      XID: driver.XID,
      // ResourceCode: driver.ResourceCode,
      GroupCode: "DRIVR",
      FirstName: driver.FirstName,
      MiddleName: driver.MiddleName,
      LastName: driver.LastName,
      SSN: driver.SSN,
      DLNumber: driver.DLNumber,
      DLExpirationDate: driver.DLExpirationDate,
      DLClassification: driver.DLClassification,
      DLState: driver.DLState,
      //DLEndorsement: driver.DLEndorsement,
      DLEndorsement: (driver.DLEndorsement)?
      driver.DLEndorsement.map((a) => ({
        TransportationPeopleDLEndorsementID: a.TransportationPeopleDLEndorsementID,       
        DLEndorsementID:a.DLEndorsementID,
        DLEndorsementName: a.DLEndorsementName,
        Active : a.Active
      })): null,
      DLMedCertExpirationDt: driver.DLMedCertExpirationDt,
      //DLRestrictions: driver.DLRestrictions,
      DLRestrictions: (driver.DLRestrictions)?
      driver.DLRestrictions.map((a) => ({
        TransportationPeopleDLRestrictionID: a.TransportationPeopleDLRestrictionID,       
        DLRestrictionID:a.DLRestrictionID,
        DLRestrictionName: a.DLRestrictionName,
        Active : a.Active
      })): null,
      Local399Grouping: driver.Local399Grouping,
      Status: driver.Status,
      BirthDate: driver.BirthDate,
      ResourceType: driver.ResourceType,
      Memo: driver.Memo,
      Email: driver.Email,
      EmgContactName: driver.EmgContactName,
      EmgContactRelationship: driver.EmgContactRelationship,
      EmgContactPhone: driver.EmgContactPhone,
      HireDate: driver.HireDate,
      SelfCertification: driver.SelfCertification,
      LastWorked: driver.LastWorked,
      PullNoticeDate: driver.PullNoticeDate,
      AnnualReviewDate: driver.AnnualReviewDate,
      PrimaryPhotoID: driver.PrimaryPhotoID,
      Active: driver.Active,
      UserID: this.userService.userID,
      PhotoArray: driver.PhotosArray.map((pa) => ({
        PhotoID: pa.PhotoID,
        XID: pa.XID,
        LogicalFileName: pa.LogicalFileName,
        Description: pa.Description,
        S3KeyName: pa.S3KeyName,
        Active: pa.Active,
        UserID: this.userService.userID,
        IsPrimary: pa.IsPrimary,
        Index: pa.Index,
      })),
      AttachmentArray: driver.AttachmentsArray.map((a) => ({
        AttachmentID: a.AttachmentID,
        XID: a.XID,
        LogicalFileName: a.LogicalFileName,
        Description: a.Description,
        S3KeyName: a.S3KeyName,
        Active: a.Active,
        UserID: this.userService.userID,
        Index: a.Index,
      })),
       TransportationPeopleAddress : driver.TransportationPeopleAddress.map((a) => ({
        Default:a.Default,
        AddressID: a.AddressID,       
        AddressType:a.AddressType,
        Address:a.Address,
        Address2:'',
        City:a.City,
        State:a.State,
        Zip:a.Zip,
        Country:a.Country,
        Active : a.Active
      })),
      TransportationPeoplePhone: driver.TransportationPeoplePhone.map((a) => ({
         PhoneID: a.PhoneID,       
        PhoneType:a.PhoneType,
        PhoneNum:a.PhoneNum,
        Active : a.Active
      })),
      TransportationPeopleNote :driver.TransportationPeopleNote.map((a) => ({
        NoteID: a.NoteID,
        Note: a.Note,
        Active : a.Active
      })),
      TransportationPeopleEvent :[],
    };

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            const res = success.body;
            var photosCount: number = 0;
            var attachmentsCount: number = 0;

            if (newPhotoArray) {
              newPhotoArray.forEach((element) => {
                var photo: any = res.PhotoArray.filter(
                  (f) => f.Index == element.Index
                );
                this.commonService
                  .uploadFiles(photo[0].signedURL, element.File)
                  .then((res) => {
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                this.commonService
                  .uploadFiles(
                    photo[0].signedURLThumbnail,
                    element.ThumbnailFile
                  )
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                photosCount++;
              });
            }
            if (newAtaachmentArray) {
              newAtaachmentArray.forEach((element) => {
                var attachment: any = res.AttachmentArray.filter(
                  (f) => f.Index == element.Index
                );
                this.commonService
                  .uploadFiles(attachment[0].signedURL, element.File)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                attachmentsCount++;
              });
            }
            this.notificationService.success(success.body.Message);
            this.driverDataChanged.emit(true);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public deleteDriver(driver: Driver) {
    //const apiURL = "https://mibqahhybk.execute-api.us-west-2.amazonaws.com/dev/transportationPeople/transportationPeopleUpdate";
    const apiURL = environment.getAPI("transportationPeopleUpdate");

    const postBody = {
      ApplicationName: this.searchService.getTableName(),
      TransportationPeopleID: driver.TransportationPeopleID,
      XID: driver.XID,
      ResourceCode: driver.ResourceCode,
      GroupCode: driver.GroupCode,
      FirstName: driver.FirstName,
      MiddleName: driver.MiddleName,
      LastName: driver.LastName,
      SSN: driver.SSN,
      DLNumber: driver.DLNumber,
      DLExpirationDate: driver.DLExpirationDate,
      DLClassification: driver.DLClassification,
      DLState: driver.DLState,
      //DLEndorsement: driver.DLEndorsement,
      DLEndorsement:  (driver.DLEndorsement)?
      driver.DLEndorsement.map((a) => ({
        TransportationPeopleDLEndorsementID: a.TransportationPeopleDLEndorsementID,       
        DLEndorsementID:a.DLEndorsementID,
        DLEndorsementName: a.DLEndorsementName,
        Active : a.Active
      })): null,
      DLMedCertExpirationDt: driver.DLMedCertExpirationDt,
      //DLRestrictions: driver.DLRestrictions,
      DLRestrictions: (driver.DLRestrictions)?
      driver.DLRestrictions.map((a) => ({
        TransportationPeopleDLRestrictionID: a.TransportationPeopleDLRestrictionID,       
        DLRestrictionID:a.DLRestrictionID,
        DLRestrictionName: a.DLRestrictionName,
        Active : a.Active
      })): null,
      Local399Grouping: driver.Local399Grouping,
      Status: driver.Status,
      BirthDate: driver.BirthDate,
      ResourceType: driver.ResourceType,
      Email: driver.Email,
      EmgContactName: driver.EmgContactName,
      EmgContactRelationship: driver.EmgContactRelationship,
      EmgContactPhone: driver.EmgContactPhone,
      HireDate: driver.HireDate,
      SelfCertification: driver.SelfCertification,
      LastWorked: driver.LastWorked,
      PullNoticeDate: driver.PullNoticeDate,
      AnnualReviewDate: driver.AnnualReviewDate,
      PrimaryPhotoID: driver.PrimaryPhotoID,
      Active: 0,
      UserID: this.userService.userID,
      PhotoArray: driver.PhotosArray.map((pa) => ({
        PhotoID: pa.PhotoID,
        XID: pa.XID,
        LogicalFileName: pa.LogicalFileName,
        Description: pa.Description,
        S3KeyName: pa.S3KeyName,
        Active: pa.Active,
        UserID: this.userService.userID,
        IsPrimary: pa.IsPrimary,
        Index: pa.Index,
      })),
      AttachmentArray: driver.AttachmentsArray.map((a) => ({
        AttachmentID: a.AttachmentID,
        XID: a.XID,
        LogicalFileName: a.LogicalFileName,
        Description: a.Description,
        S3KeyName: a.S3KeyName,
        Active: a.Active,
        UserID: this.userService.userID,
        Index: a.Index,
      })),
      TransportationPeopleAddress :(driver.TransportationPeopleAddress)?
       driver.TransportationPeopleAddress.map((a) => ({
        Default:a.Default,
        AddressID: a.AddressID,       
        AddressType:a.AddressType,
        Address:a.Address,
        Address2:'',
        City:a.City,
        State:a.State,
        Zip:a.Zip,
        Country:a.Country,
        Active : a.Active
      })): null,
      TransportationPeoplePhone: (driver.TransportationPeoplePhone) ?
      driver.TransportationPeoplePhone.map((a) => ({
        PhoneID: a.PhoneID,       
        PhoneType:a.PhoneType,
        PhoneNum:a.PhoneNum,
        Active : a.Active
      })) : null,
      TransportationPeopleNote :(driver.TransportationPeopleNote)?
      driver.TransportationPeopleNote.map((a) => ({
        NoteID: a.NoteID,
        Note: a.Note,
        Active : a.Active
      })): null,
       TransportationPeopleEvent :[],
    };

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            const res = success.body;

            this.notificationService.success(success.body.Message);
            this.driverDataChanged.emit(true);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  //Api to fetch Transportation People based on ID

   public getTransportationPeople(TransportationPeopleID:number) {
    //const apiURL = "https://mibqahhybk.execute-api.us-west-2.amazonaws.com/dev/transportationPeople/getTransportationPeople";
    const apiURL = environment.getAPI("getTransportationPeople");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<any>((resolve, reject) => {
      this.http
        .post(
          apiURL,
          { TransportationPeopleID: TransportationPeopleID },
          httpOptions
        )
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.driver = success.body;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }
}
