import { Injectable, EventEmitter, Output } from "@angular/core";
import { PersonOfInterest } from "../models/person-of-interest.model";
import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";
import { NotificationService } from "./notification.service";
import { UserService } from "./user.service";
import { CommonService } from "./common.service";
import { SearchService } from "./search.service";
import { Photos } from "../models/photo.model";
import { Attachments } from "../models/attachments.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PersonOfInterestService {
  personOfInterest: PersonOfInterest[];
  personOfInterestApiData: PersonOfInterest[];
  @Output() personOfInterestDataChanged: EventEmitter<boolean>;
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private userService: UserService,
    private commonService: CommonService,
    private searchService: SearchService
  ) {
    this.personOfInterest = [];
    this.personOfInterestDataChanged = new EventEmitter<boolean>();
  }


  // Method to add a Person Of Interest
  public createPersonOfInterest(
    personOfInterest: PersonOfInterest,
    aliases: Array<String>
  ) {
    this.createPersonOfInterestAPI(personOfInterest, aliases).then(() => {});
  }

  public createPersonOfInterestAPI(
    personOfInterest: PersonOfInterest,
    aliases: Array<String>
  ) {
    //const apiURL = "https://mibqahhybk.execute-api.us-west-2.amazonaws.com/dev/personofinterest/personOfInterestCreate";
    const apiURL = environment.getAPI("personOfInterestCreate");
    const postBody = {
      XID: personOfInterest.XID,
      ApplicationName: this.searchService.getTableName(),
      PersonOfInterestID: personOfInterest.PersonOfInterestID,
      FirstName: personOfInterest.FirstName,
      MiddleName: personOfInterest.MiddleName,
      LastName: personOfInterest.LastName,
      Suffix: personOfInterest.Suffix,
      Company: personOfInterest.Company,
      Email: personOfInterest.Email,
      DriverLicenseNum: personOfInterest.DriverLicenseNum,
      BirthDay: personOfInterest.BirthDay,
      BirthMonth: personOfInterest.BirthMonth,
      BirthYear: personOfInterest.BirthYear,
      Gender: personOfInterest.Gender,
      HeightInches: personOfInterest.HeightInches,
      WeightPounds: personOfInterest.WeightPounds,
      HeightMeters: personOfInterest.HeightMeters,
      WeightKilograms: personOfInterest.WeightKilograms,
      Hair: personOfInterest.Hair,
      Eyes: personOfInterest.Eyes,
      Race: personOfInterest.Race,
      SSN: personOfInterest.SSN,
      Notes: personOfInterest.Notes,
      NotificationEmail: personOfInterest.NotificationEmail,
      PrimaryPhotoID: personOfInterest.PrimaryPhotoID,
      Active: 1,
      RestrictionType: personOfInterest.RestrictionType,
      RestrictedOn: personOfInterest.RestrictedOn,
      RestrictedEndDate: personOfInterest.RestrictedEndDate,
      RestrictedReason: personOfInterest.RestrictedReason,
      UserID: this.userService.userID,
      AliasArray: aliases.map((eps) => ({
        AliasID: 0,
        Name: eps,
        Active: 1,
        Autologous: 0,
        UserID: this.userService.userID,
      })),
      PhotoArray: personOfInterest.PhotosArray.map((pa) => ({
        PhotoID: pa.PhotoID,
        XID: pa.XID,
        LogicalFileName: pa.LogicalFileName,
        Description: pa.Description,
        S3KeyName: pa.S3KeyName,
        Active: 1,
        UserID: this.userService.userID,
        IsPrimary: pa.IsPrimary,
        Index: pa.Index,
      })),

      AttachmentArray: personOfInterest.AttachmentsArray.map((a) => ({
        AttachmentID: a.AttachmentID,
        XID: a.XID,
        LogicalFileName: a.LogicalFileName,
        Description: a.Description,
        S3KeyName: a.S3KeyName,
        Active: 1,
        UserID: this.userService.userID,
        Index: a.Index,
      })),

      POIRequesterArray: [
        {
          POIRequesterID: personOfInterest.POIRequesterArray.POIRequesterID,
          FirstName: personOfInterest.POIRequesterArray.FirstName,
          LastName: personOfInterest.POIRequesterArray.LastName,
          Email: personOfInterest.POIRequesterArray.Email,
          Phone: personOfInterest.POIRequesterArray.Phone,
          Company: personOfInterest.POIRequesterArray.Company,
          Department: personOfInterest.POIRequesterArray.Department,
          Position: personOfInterest.POIRequesterArray.Position,
          Active: 1,
          UserID: this.userService.userID,
        },
      ],
      POIAddress:
        personOfInterest.POIAddress.map((a) => ({     
        Default:a.Default ? 1:0,      
        AddressID: 0,       
        AddressType:a.AddressType,
        Address:a.Address,
        Address2:a.Address2,
        City:a.City,
        State:a.State,
        Zip:a.Zip,
        Country:a.Country,
        Active : 1
      })),
      POIPhone: personOfInterest.POIPhone.map((a) => ({           
        PhoneID: 0,       
        PhoneType:a.PhoneType,
        PhoneNum:a.PhoneNum,
        Active : 1
      })),
      POINote: personOfInterest.POINote.map((a) => ({
        NoteID: 0,
        Note: a.Note,
        Active : 1
      })) ,
      POIEvent: personOfInterest.POIEvent.map((a) => ({
        EventID : 0,
        Type: a.Type,
        SiteID: a.SiteID,
        Description: a.Description,
        StartTime: a.StartTime,
        EndTime: a.EndTime,
        POINoteEvent : a.POINoteEvent.map((eventNote) => ({
          NoteEventID: 0,
          EventID: 0,
          Note: eventNote.Note,
          Active: 1,        
        })),
        Active: 1,
      })),
      POIRegion : personOfInterest.POIRegion
    };
    console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            console.log(success.body);
            const res = success.body;

            if (personOfInterest.PhotosArray) {
              personOfInterest.PhotosArray.forEach((element) => {
                var photo: any = res.PhotoArray.filter(
                  (f) => f.Index == element.Index
                );
                console.log(photo);
                console.log(element.File);
                this.commonService
                  .uploadFiles(photo[0].signedURL, element.File)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                this.commonService
                  .uploadFiles(
                    photo[0].signedURLThumbnail,
                    element.ThumbnailFile
                  )
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
            }
            if (personOfInterest.AttachmentsArray) {
              personOfInterest.AttachmentsArray.forEach((element) => {
                var attachment: any = res.AttachmentArray.filter(
                  (f) => f.Index == element.Index
                );
                console.log(attachment);
                this.commonService
                  .uploadFiles(attachment[0].signedURL, element.File)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
            }
            this.personOfInterestDataChanged.emit(true);
            this.notificationService.success(success.body.Message);

            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public updatePersonOfInterestModalComponent(
    personOfInterest: PersonOfInterest,
    newPhotosArray: Array<Photos>,
    newAttachmentsArray: Array<Attachments>
  ) {
    //const apiURL = "https://mibqahhybk.execute-api.us-west-2.amazonaws.com/dev/personofinterest/personOfInterestUpdate"
    const apiURL = environment.getAPI("personOfInterestUpdate");
    const postBody = {
      XID: personOfInterest.XID,
      ApplicationName: this.searchService.getTableName(),
      PersonOfInterestID: personOfInterest.PersonOfInterestID,
      FirstName: personOfInterest.FirstName,
      MiddleName: personOfInterest.MiddleName,
      LastName: personOfInterest.LastName,
      Suffix: personOfInterest.Suffix,
      Company: personOfInterest.Company,
      Email: personOfInterest.Email,
      DriverLicenseNum: personOfInterest.DriverLicenseNum,
      BirthDay: personOfInterest.BirthDay,
      BirthMonth: personOfInterest.BirthMonth,
      BirthYear: personOfInterest.BirthYear,
      Gender: personOfInterest.Gender,
      HeightInches: personOfInterest.HeightInches,
      WeightPounds: personOfInterest.WeightPounds,
      HeightMeters: personOfInterest.HeightMeters,
      WeightKilograms: personOfInterest.WeightKilograms,
      Hair: personOfInterest.Hair,
      Eyes: personOfInterest.Eyes,
      Race: personOfInterest.Race,
      SSN: personOfInterest.SSN,
      Notes: personOfInterest.Notes,
      NotificationEmail: personOfInterest.NotificationEmail,
      PrimaryPhotoID: personOfInterest.PrimaryPhotoID,
      Active: 1,
      RestrictionType: personOfInterest.RestrictionType,
      RestrictedOn: personOfInterest.RestrictedOn,
      RestrictedEndDate: personOfInterest.RestrictedEndDate,
      RestrictedReason: personOfInterest.RestrictedReason,
      UserID: this.userService.userID,
      AliasArray: personOfInterest.AliasArray.map((eps) => ({
        AliasID: eps.AliasID,
        Name: eps.Name,
        Active: eps.Active,
        Autologous: 0,
        UserID: this.userService.userID,
      })),
      PhotoArray: personOfInterest.PhotosArray.map((pa) => ({
        PhotoID: pa.PhotoID,
        XID: pa.XID,
        LogicalFileName: pa.LogicalFileName,
        Description: pa.Description,
        S3KeyName: pa.S3KeyName,
        Active: pa.Active,
        UserID: this.userService.userID,
        IsPrimary: pa.IsPrimary,
        Index: pa.Index,
      })),

      AttachmentArray: personOfInterest.AttachmentsArray.map((a) => ({
        AttachmentID: a.AttachmentID,
        XID: a.XID,
        LogicalFileName: a.LogicalFileName,
        Description: a.Description,
        S3KeyName: a.S3KeyName,
        Active: a.Active,
        UserID: this.userService.userID,
        Index: a.Index,
      })),

      POIRequesterArray: [
        {
          POIRequesterID: personOfInterest.POIRequesterArray.POIRequesterID,
          FirstName: personOfInterest.POIRequesterArray.FirstName,
          LastName: personOfInterest.POIRequesterArray.LastName,
          Email: personOfInterest.POIRequesterArray.Email,
          Phone: personOfInterest.POIRequesterArray.Phone,
          Company: personOfInterest.POIRequesterArray.Company,
          Department: personOfInterest.POIRequesterArray.Department,
          Position: personOfInterest.POIRequesterArray.Position,
          Active: personOfInterest.POIRequesterArray.Active,
          UserID: this.userService.userID,
        },
      ],
      POIAddress:
       personOfInterest.POIAddress.map((a) => ({   
        Default:a.Default?1:0,        
        AddressID: a.AddressID,       
        AddressType:a.AddressType,
        Address:a.Address,
        Address2:a.Address2,
        City:a.City,
        State:a.State,
        Zip:a.Zip,
        Country:a.Country,
        Active:a.Active? 1:0
      }))
      ,
      POIPhone: personOfInterest.POIPhone.map((a) => ({           
        PhoneID: a.PhoneID,       
        PhoneType:a.PhoneType,
        PhoneNum:a.PhoneNum,
        Active : a.Active
      })),
      POINote: personOfInterest.POINote.map((a) => ({
        NoteID: a.NoteID,
        Note: a.Note,
        Active : a.Active
      })) ,
      POIEvent: personOfInterest.POIEvent.map((a) => ({
        EventID : a.EventID,
        Type: a.Type,
        SiteID: a.SiteID,
        Description: a.Description,
        StartTime: a.StartTime,
        EndTime: a.EndTime,
        POINoteEvent : a.POINoteEvent.map((eventNote) => ({
          NoteEventID: eventNote.NoteID,
          EventID: eventNote.EventID,
          Note: eventNote.Note,
          Active: eventNote.Active? 1:0,        })),
        Active: a.Active ? 1:0,
      })),
      POIRegion: personOfInterest.POIRegion
    };

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            console.log(success);
            const res = success.body;
            this.notificationService.success(success.body.Message);
            console.log(personOfInterest.PhotosArray);

            if (newPhotosArray.length > 0) {
              newPhotosArray.forEach((element) => {
                console.log(element.File);
                var photo: any = res.PhotoArray.filter(
                  (f) => f.LogicalFileName === element.LogicalFileName
                );
                console.log(photo);
                this.commonService
                  .uploadFiles(photo[0].signedURL, element.File)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                this.commonService
                  .uploadFiles(
                    photo[0].signedURLThumbnail,
                    element.ThumbnailFile
                  )
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
            }
            if (newAttachmentsArray.length > 0) {
              newAttachmentsArray.forEach((element) => {
                console.log(element.AttachmentID);
                var attachment: any = res.AttachmentArray.filter(
                  (f) => f.LogicalFileName == element.LogicalFileName
                );
                console.log(attachment);
                this.commonService
                  .uploadFiles(attachment[0].signedURL, element.File)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
            }
            this.personOfInterestDataChanged.emit(true);
            this.notificationService.success(success.body.Message);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public deletePOI(personOfInterest: PersonOfInterest) {
    const apiURL = environment.getAPI("personOfInterestUpdate");
    //const apiURL = "https://mibqahhybk.execute-api.us-west-2.amazonaws.com/dev/personofinterest/personOfInterestUpdate";
    console.log("Request Body" + personOfInterest );
    const postBody = {
      XID: personOfInterest.XID,
      ApplicationName: this.searchService.getTableName(),
      PersonOfInterestID: personOfInterest.PersonOfInterestID,
      FirstName: personOfInterest.FirstName,
      MiddleName: personOfInterest.MiddleName,
      LastName: personOfInterest.LastName,
      Suffix: personOfInterest.Suffix,
      Company: personOfInterest.Company,
      Email: personOfInterest.Email,
      DriverLicenseNum: personOfInterest.DriverLicenseNum,
      BirthDay: personOfInterest.BirthDay,
      BirthMonth: personOfInterest.BirthMonth,
      BirthYear: personOfInterest.BirthYear,
      Gender: personOfInterest.Gender,
      HeightInches: personOfInterest.HeightInches,
      WeightPounds: personOfInterest.WeightPounds,
      HeightMeters: personOfInterest.HeightInches,
      WeightKilograms: personOfInterest.WeightPounds,
      Hair: personOfInterest.Hair,
      Eyes: personOfInterest.Eyes,
      Race: personOfInterest.Race,
      SSN: personOfInterest.SSN,
      Notes: personOfInterest.Notes,
      NotificationEmail: personOfInterest.NotificationEmail,
      PrimaryPhotoID: personOfInterest.PrimaryPhotoID,
      Active: 0,
      RestrictionType: personOfInterest.RestrictionType,
      RestrictedOn: personOfInterest.RestrictedOn,
      RestrictedEndDate: personOfInterest.RestrictedEndDate,
      RestrictedReason: personOfInterest.RestrictedReason,
      UserID: this.userService.userID,
      AliasArray: personOfInterest.AliasArray.map((eps) => ({
        AliasID: eps.AliasID,
        Name: eps.Name,
        Active: 0,
        Autologous: 0,
        UserID: this.userService.userID,
      })),
      PhotoArray: personOfInterest.PhotosArray.map((pa) => ({
        PhotoID: pa.PhotoID,
        XID: pa.XID,
        LogicalFileName: pa.LogicalFileName,
        Description: pa.Description,
        S3KeyName: pa.S3KeyName,
        Active: 0,
        UserID: this.userService.userID,
        IsPrimary: pa.IsPrimary,
        Index: pa.Index,
      })),

      AttachmentArray: personOfInterest.AttachmentsArray.map((a) => ({
        AttachmentID: a.AttachmentID,
        XID: a.XID,
        LogicalFileName: a.LogicalFileName,
        Description: a.Description,
        S3KeyName: a.S3KeyName,
        Active: 0,
        UserID: this.userService.userID,
        Index: a.Index,
      })),

      POIRequesterArray: [
       
      ],
      POIAddress: personOfInterest.POIAddress ?
        personOfInterest.POIAddress.map((a) => ({   
        Default:a.Default?1:0,        
        AddressID: a.AddressID,       
        AddressType:a.AddressType,
        Address:a.Address,
        Address2:a.Address2,
        City:a.City,
        State:a.State,
        Zip:a.Zip,
        Country:a.Country,
        Active:a.Active ? 1: 0
      })) : null,
      POIPhone: personOfInterest.POIPhone ? 
       personOfInterest.POIPhone.map((a) => ({           
        PhoneID: a.PhoneID,       
        PhoneType:a.PhoneType,
        PhoneNum:a.PhoneNum,
      })) : null,
    };

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            console.log(success);
            const res = success.body;
            this.notificationService.success(success.body.Message);
            console.log(personOfInterest.PhotosArray);

            this.personOfInterestDataChanged.emit(true);
            this.notificationService.success(success.body.Message);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }


  
  //Api to fetch PersonOfInterestBased on ID

   public getPersonOfInterest(personOfInterestID:number) {
    //const apiURL = "https://mibqahhybk.execute-api.us-west-2.amazonaws.com/dev/personofinterest/getPOI";
    const apiURL = environment.getAPI("getPOI");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<any>((resolve, reject) => {
      this.http
        .post(
          apiURL,
          { PersonOfInterestID: personOfInterestID },
          httpOptions
        )
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            console.log(success.body)
            this.personOfInterest = success.body;
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getPOIRegion(POIId): Promise<any> {
    //const apiURL = 'https://mibqahhybk.execute-api.us-west-2.amazonaws.com/dev/personofinterest/getPOIRegion';
    const apiURL = environment.getAPI("getPOIRegion");
    const postBody = {
      "PersonOfInterestID": POIId
    };

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (response: HttpResponse<any>) => {
            // success
            // console.log(this.filterData);
            resolve(response.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getRegionTree(): Promise<any> {
    //const apiURL = 'https://mibqahhybk.execute-api.us-west-2.amazonaws.com/dev/master/getRegionTree';
    const apiURL = environment.getAPI("getRegionTree");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, httpOptions)
        .toPromise()
        .then(
          (response: HttpResponse<any>) => {
            // success
            // console.log(this.filterData);
            resolve(response);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }
}
