import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PickList } from 'src/app/models/pickList.model';

/**
 * @title Autocomplete with add new item option
 */
@Component({
  selector: 'app-autocomplete-generic',
  templateUrl: 'mat-autocomplete.component.html',
  styleUrls: ['mat-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatAutocompleteComponent),
      multi: true,
    },
  ],
})
export class MatAutocompleteComponent 
implements OnInit , ControlValueAccessor{
  itemCtrl: FormControl;
  filteredItems: Observable<any[]>;
  showAddButton: boolean = false;
  @Input() itemList: PickList[]; // required: it is the basket list (universal).

  prompt = 'Press <enter> to add "';

  items: PickList[] = [];

  constructor() {
    
    this.itemCtrl = new FormControl();
    this.filteredItems = this.itemCtrl.valueChanges
      .pipe(
      startWith(''),
      map(item => item ? this.filterItems(item) : this.items.slice())
      );
  }

  ngOnInit(){
    console.log(this.itemList)
    this.items = this.itemList;
     this.filteredItems = this.itemCtrl.valueChanges
      .pipe(
      startWith(''),
      map(item => item ? this.filterItems(item) : this.items.slice())
      );    

  }

  filterItems(item: PickList) {
    let results = this.items.filter(item =>
      item.FieldName.toLowerCase().indexOf(item.FieldName.toLowerCase()) === 0);

    this.showAddButton = results.length === 0;
    // if (this.showAddButton) {
    //   results = [this.prompt + item.FieldName + '"'];
    // }

    return results;
  }

  optionSelected(option) {
    if (option.value.indexOf(this.prompt) === 0) {
      this.addOption();
    }
  }

  addOption() {
    let option = this.removePromptFromOption(this.itemCtrl.value);
    if (!this.items.some(entry => entry === option)) {
      const index = this.items.push(option) - 1;
      this.itemCtrl.setValue(this.items[index]);
    }
  }

  removePromptFromOption(option) {
    if (option.startsWith(this.prompt)) {
      option = option.substring(this.prompt.length, option.length -1);
    }
    return option;
  }

  // the method writes from form model into the view/dom property
  writeValue(value: string[]): void {
    
  }

  // invokes a callback listener whenever a control is changed
  registerOnChange(fn: any): void {
    
  }

  registerOnTouched(fn: any): void {
    // do nothing
  }
}

