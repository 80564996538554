import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AgGridModule } from "ag-grid-angular";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NavbarComponent } from "./navbar/navbar.component";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatTabsModule } from "@angular/material/tabs";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MatAutocompleteModule,
  MatCardModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatListModule,
  MatRadioModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTooltipModule,
} from "@angular/material";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule } from "@angular/material";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { NewRecordComponent } from "./new-record/new-record.component";
import { EditRecordComponent } from "./edit-record/edit-record.component";
import { AuthService } from "./services/auth.service";
import { LoginRegistrationComponent } from "./login-registration/login-registration.component";
import { PersonOfInterestComponent } from "./person-of-interest/person-of-interest.component";
import { CreatePersonOfInterestModalComponent } from "./person-of-interest/modals/create-person-of-interest/create-person-of-interest-modal.component";
import { UpdatePersonOfInterestModalComponent } from "./person-of-interest/modals/update-person-of-interest/update-person-of-interest-modal.component";
import { ViewPersonOfInterestModalComponent } from "./person-of-interest/modals/view-person-of-interest/view-person-of-interest-modal.component";
import { ButtonRendererComponent } from "./renderer/button-renderer.component";
import { CommonConfirmDialogComponent } from "./common-confirm-dialog/common-confirm-dialog.component";
import { HyperLinkComponent } from "./hyperlink.component";
import { DatePipe } from "@angular/common";
import { ProfileRenderer } from "./renderer/profile-renderer.component";
import { AvatarModule } from "ngx-avatar";
import { CreateDriverModalComponent } from "./driver/modals/create-driver/create-driver-modal.component";
import { UpdateDriverModalComponent } from "./driver/modals/update-driver/update-driver-modal.component";
import { ViewPhotoModalComponent } from "./renderer/view-photo/view-photo-modal.component";
import { DriverComponent } from "./driver/driver.component";
import { ViewDriverModalComponent } from "./driver/modals/view-driver/view-driver-modal.component";
import { environment } from "../environments/environment";
import { DefaultRedirectComponent } from "./default-redirect/default-redirect.component";
import { LoggedInUserGuard } from "./guards/loggedInUserGuard";
import { UserRegisteredGuard } from "./guards/userRegisteredGuard";
import { ViewControlGuard } from "./guards/viewControlGuard";
import { NgxImageCompressService } from 'ngx-image-compress';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { CommonAlertDialogComponent } from './common-alert-dialog/common-alert-dialog.component';
import { SearchFilterModalComponent } from './search-filter/search-filter.modal.component';
import { TreeViewComponent } from "./renderer/tree-view/tree-view.component";
import {MatTreeModule} from '@angular/material/tree';
import { AddressModalComponent } from "./renderer/address/address.modal.component";
import { MatAutocompleteComponent } from "./renderer/mat-autocomplete/mat-autocomplete.component";
import { EventModalComponent } from "./renderer/event/event.modal.component";
import { SliderRendererComponent } from "./renderer/slider-renderer.component";
import { TokenInterceptor } from "./interceptors/token.intercept";
import { SessionInterceptor } from './interceptors/session.interceptors';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavbarComponent,
    NewRecordComponent,
    EditRecordComponent,
    LoginRegistrationComponent,
    PersonOfInterestComponent,
    DriverComponent,
    CommonConfirmDialogComponent,
    CommonAlertDialogComponent,
    SearchFilterModalComponent,
    CreatePersonOfInterestModalComponent,
    UpdatePersonOfInterestModalComponent,
    ViewPersonOfInterestModalComponent,
    CreateDriverModalComponent,
    UpdateDriverModalComponent,
    ViewDriverModalComponent,
    ButtonRendererComponent,
    SliderRendererComponent,
    HyperLinkComponent,
    ViewPhotoModalComponent,
    ProfileRenderer,
    DefaultRedirectComponent,
    AddressModalComponent,
    MatAutocompleteComponent,
    EventModalComponent,
    TreeViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    MatMenuModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTabsModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([
      ButtonRendererComponent,
      HyperLinkComponent,
      ProfileRenderer,
      SliderRendererComponent
    ]),
    MatMenuModule,
    MatTooltipModule,
    AvatarModule,
    OktaAuthModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatListModule,
    MatTreeModule,
    MatCardModule,
    MatSlideToggleModule
  ],
  providers: [
    AuthService,
    DatePipe,
    { provide: OKTA_CONFIG, useValue: environment.oktaConfig },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    LoggedInUserGuard,
    UserRegisteredGuard,
    ViewControlGuard,
    NgxImageCompressService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NewRecordComponent,
    EditRecordComponent,
    LoginRegistrationComponent,
    CreatePersonOfInterestModalComponent,
    UpdatePersonOfInterestModalComponent,
    ViewPersonOfInterestModalComponent,
    ViewDriverModalComponent,
    ButtonRendererComponent,
    SliderRendererComponent,
    ProfileRenderer,
    CommonConfirmDialogComponent,
    ViewPhotoModalComponent,
    SearchFilterModalComponent,
    TreeViewComponent
  ],
})
export class AppModule {}
