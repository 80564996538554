import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {
  getMatFormFieldDuplicatedHintError,
  MatDialog,
  MatDialogConfig,
} from "@angular/material";
import { NewRecordComponent } from "../new-record/new-record.component";
import { EditRecordComponent } from "../edit-record/edit-record.component";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { Router } from "@angular/router";
import { CreatePersonOfInterestModalComponent } from "./modals/create-person-of-interest/create-person-of-interest-modal.component";
import { PersonOfInterestService } from "../services/person-of-interest.service";
import { SearchService } from "../services/search.service";
import { NotificationService } from "../services/notification.service";
import { AuthService } from "../services/auth.service";
import { UpdatePersonOfInterestModalComponent } from "./modals/update-person-of-interest/update-person-of-interest-modal.component";
import { PersonOfInterest } from "../models/person-of-interest.model";
import { DatePipe } from "@angular/common";
import { ProfileRenderer } from "../renderer/profile-renderer.component";
import { ViewPersonOfInterestModalComponent } from "./modals/view-person-of-interest/view-person-of-interest-modal.component";
import { Photos } from "../models/photo.model";
import { Attachments } from "../models/attachments.model";
import { CommonService } from "../services/common.service";
import { UserService } from "../services/user.service";
import { POIRequester } from '../models/poi-requester.model';
import { DialogService } from '../services/dialog.service';
import { SearchFilterModalComponent } from '../search-filter/search-filter.modal.component';
import { TreeDropdownDynamicDatabaseService } from "../services/tree-dropdown-dynamic-database.service";
import { GridApi } from "ag-grid-community";
import { SpinnerService } from "../services/spinner.service";
const moment = _rollupMoment || _moment;

@Component({
  selector: "app-person-of-interest",
  templateUrl: "./person-of-interest.component.html",
  styleUrls: ["./person-of-interest.component.css"],
})
export class PersonOfInterestComponent implements OnInit {
  rowData: any = [];
  data: any;
  params: [];
  
  selectedPoi: PersonOfInterest;
  clickedPoi: PersonOfInterest;
  isRowSelected: boolean = false;
  private gridApi: GridApi;
  protected count: number = 0;

  @ViewChild("searchField") searchField: ElementRef;
  protected selectedApp: number;
  frameworkComponents: any;
  protected userApplications: Array<{
    ApplicationID: number;
    Name: string;
    Route: string;
  }>;

  //ag-grid
  columnDefs = [
    {
      headerName: "Person Of Interest ID",
      field: "PersonOfInterestID",
      cellStyle: { color: "#ffff" },
      hide: true,
      sortable: true,
    },
    {
      headerName: "RecordID",
      field: "RecordID",
      cellStyle: { color: "#ffff" },
      hide: "true",
      sortable: true,
    },
    {
      headerName: "Personal Information",
      children: [
        {
          cellRenderer: "profileRenderer",
          autoHeight: true,
          checkboxSelection: true,
          width: 120,
        },

        {
          headerName: "First Name",
          field: "FirstName",
          cellStyle: { color: "#ffff" },
          hide: false,
          sortable: true,
          //checkboxSelection: true,
        },
        {
          headerName: "Middle Name",
          field: "MiddleName",
          cellStyle: { color: "#ffff" },
          hide: false,
          sortable: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Last Name",
          field: "LastName",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
        },
        
        {
          headerName: "Suffix",
          field: "Suffix",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
          sortable: true,
          columnGroupShow: "open",
        },
        {
          headerName: "SSN",
          field: "SSN",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
          sortable: true,
        },

        {
          headerName: "Gender",
          field: "GenderName",
          // width: 175,
          cellClass: (params) => {
            return params.data.GenderActive === 0 ? "inActiveLinkedField" : "";
          },
          cellStyle: { color: "#ffff" },
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Birth Day",
          field: "BirthDay",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
          sortable: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Birth Month",
          field: "BirthMonth",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
          sortable: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Birth Year",
          field: "BirthYear",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
          sortable: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Aliases",
          field: "Aliases",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
        },
      ],
    },

    //Profile

    {
      headerName: "Profile",
      children: [
        {
          headerName: "Height(in)",
          field: "HeightInches",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Height(mt)",
          field: "HeightMeters",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Weight(lb)",
          field: "WeightPounds",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Weight(kg)",
          field: "WeightKilograms",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Hair",
          field: "HairColor",
          cellClass: (params) => {
            return params.data.HairActive === 0 ? "inActiveLinkedField" : "";
          },
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
        },
        {
          headerName: "Eyes",
          field: "EyeColor",
          cellClass: (params) => {
            return params.data.EyesActive === 0 ? "inActiveLinkedField" : "";
          },
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
        },
        {
          headerName: "Race",
          field: "RaceName",
          cellClass: (params) => {
            return params.data.RaceActive === 0 ? "inActiveLinkedField" : "";
          },
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
        },
        {
          headerName: "Driver License Number",
          field: "DriverLicenseNum",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
        },
      ],
    },

    //Contact information

    // {
    //   headerName: "Contact Information",
    //   children: [
    //     {
    //       headerName: "Email",
    //       field: "Email",
    //       cellStyle: { color: "#ffff" },
    //       hide: false,
    //       resizable: true,
    //     },
    //     {
    //       headerName: "Phone",
    //       field: "Phone",
    //       cellStyle: { color: "#ffff" },
    //       hide: false,
    //       resizable: true,
    //     },
    //     {
    //       headerName: "Company",
    //       field: "Company",
    //       cellStyle: { color: "#ffff" },
    //       hide: false,
    //       resizable: true,
    //     },
    //     {
    //       headerName: "Address",
    //       field: "Address",
    //       cellStyle: { color: "#ffff" },
    //       hide: false,
    //       resizable: true,
    //       sortable: true,
    //     },
    //     {
    //       headerName: "Address2",
    //       field: "Address2",
    //       cellStyle: { color: "#ffff" },
    //       hide: false,
    //       resizable: true,
    //       sortable: true,
    //       columnGroupShow: "open",
    //     },

    //     {
    //       headerName: "Country",
    //       field: "Country",
    //       cellStyle: { color: "#ffff" },
    //       hide: false,
    //       resizable: true,
    //       sortable: true,
    //       columnGroupShow: "open",
    //     },
    //     {
    //       headerName: "State",
    //       field: "State",
    //       cellStyle: { color: "#ffff" },
    //       hide: false,
    //       resizable: true,
    //       sortable: true,
    //       columnGroupShow: "open",
    //     },
    //     {
    //       headerName: "City",
    //       field: "City",
    //       cellStyle: { color: "#ffff" },
    //       hide: false,
    //       resizable: true,
    //       sortable: true,
    //       columnGroupShow: "open",
    //     },
    //     {
    //       headerName: "Zip",
    //       field: "Zip",
    //       cellStyle: { color: "#ffff" },
    //       hide: false,
    //       resizable: true,
    //       sortable: true,
    //       columnGroupShow: "open",
    //     },
    //   ],
    // },

    //Requester Information
    {
      headerName: "Requester Information",
      children: [
        {
          headerName: "First Name",
          field: "Requester.FirstName",
          cellStyle: { color: "#ffff" },
          hide: false,
          sortable: true,
        },
        {
          headerName: "Last Name",
          field: "Requester.LastName",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
        },
        {
          headerName: "Email",
          field: "Requester.Email",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
        },
        {
          headerName: "Phone",
          field: "Requester.Phone",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
        },
        {
          headerName: "Company",
          field: "Requester.Company",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
        },
        {
          headerName: "Department",
          field: "Requester.Department",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Position",
          field: "Requester.Position",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
          columnGroupShow: "open",
        },
      ],
    },

    //Notification and Restriction
    {
      headerName: "Notification and Restrictions",
      children: [
        {
          headerName: "Restriction Type",
          field: "RestrictionTypeName",
          cellClass: (params) => {
            return params.data.RestrictionTypeActive === 0
              ? "inActiveLinkedField"
              : "";
          },
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
        },
        {
          headerName: "Restricted On Date",
          field: "RestrictedOn",
          cellStyle: { color: "#ffff" },
          valueGetter: (params) => {
            return (params.data.RestrictedOn === '0000-00-00 00:00:00' || !params.data.RestrictedOn || params.data.RestrictedOn === '') ? ' ' : moment(params.data.RestrictedOn).format('MM/DD/YYYY');
          },
          hide: false,
          resizable: true,
          sortable: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Restricted End Date",
          field: "RestrictedEndDate",
          cellStyle: { color: "#ffff" },
          valueGetter: (params) => {
            return (params.data.RestrictedEndDate === '0000-00-00 00:00:00' || !params.data.RestrictedEndDate || params.data.RestrictedEndDate === '') ? ' ' : moment(params.data.RestrictedEndDate).format('MM/DD/YYYY');
          },
          hide: false,
          resizable: true,
          sortable: true,
          columnGroupShow: "open",
        },
        // {
        //   headerName: "Restricted Reason",
        //   field: "RestrictedReasonName",
        //   cellClass: (params) => {
        //     return params.data.RestrictedReasonActive === 0
        //       ? "inActiveLinkedField"
        //       : "";
        //   },
        //   cellStyle: { color: "#ffff" },
        //   hide: false,
        //   resizable: true,
        // },
        // {
        //   headerName: "Notes",
        //   field: "Notes",
        //   cellStyle: { color: "#ffff" },
        //   hide: false,
        //   resizable: true,
        //   sortable: true,
        //   columnGroupShow: "open",
        // },
        {
          headerName: "Notification Email",
          field: "NotificationEmail",
          cellStyle: { color: "#ffff" },
          hide: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: "GenderActive",
          field: "GenderActive",
          hide: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: "HairActive",
          field: "HairActive",
          hide: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: "EyesActive",
          field: "EyesActive",
          hide: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: "RaceActive",
          field: "RaceActive",
          hide: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: "RestrictionTypeActive",
          field: "RestrictionTypeActive",
          hide: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: "RestrictedReasonActive",
          field: "RestrictedReasonActive",
          hide: true,
          resizable: true,
          sortable: true,
        },
      ],
    },
  ];

  constructor(
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private http: HttpClient,
    private router: Router,
    protected authService: AuthService,
    protected notificationService: NotificationService,
    protected searchService: SearchService,
    protected personOfInterestService: PersonOfInterestService,
    protected commonService: CommonService,
    protected userService: UserService,
    protected dialogService: DialogService,
    protected treeDropdownService: TreeDropdownDynamicDatabaseService,
    protected spinnerService: SpinnerService
  ) {
    this.selectedPoi = null;
    this.selectedApp = 2;
    this.frameworkComponents = {
      profileRenderer: ProfileRenderer,
    };
    this.searchService.setPageName("Person Of Interest");
    
    
    

  }


  onApplicationChange(){
  this.searchService.switchApplication(this.selectedApp)
  }

  //when checkbox is checked, updates row data
  onSelectionChanged($event) {
    const selectedRows: PersonOfInterest[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      console.log("onselection",this.isRowSelected)
      this.selectedPoi = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
  }

  //opens edit modal, send row data to modal
  updateRecord() {
    this.dialog.open(UpdatePersonOfInterestModalComponent, {
      data: this.selectedPoi,
    });
  }

  createRecord() {
    this.gridApi.deselectAll();
    const dialogNew = this.dialog.open(
      CreatePersonOfInterestModalComponent,
      {}
    );
  }

  removeRecord() {
    this.dialog.open(UpdatePersonOfInterestModalComponent, {
      data: this.selectedPoi,
    });
  }

  //make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  //get grid
  ngOnInit() {

    this.spinnerService.setIsLoading(true);

    this.getDropdownData();
    // setting the page name
    this.searchService.setPageName("Person Of Interest");
    this.searchService
    .smartSearchAPI(null)
          .then(() => {
            this.data = this.searchService.searchRecords;
            this.data = this.data.filter((f) => f.Active)
              if (this.data) {
                this.data.forEach(element => {                
                  var photoArray: Photos[] = this.setPhotoArray( element.PhotoIDs,element.PhotoXIDs,element.PhotoS3KeyNames,element.PhotoDescriptions,element.PhotoLogicalFileNames,element.PhotoActives,element.PrimaryPhotoID);  
                  element.PrimaryPhotoArray = [];
                  if(photoArray){
                    var primaryPhotoArray: Photos[] = photoArray.filter((f) => f.IsPrimary == true && f.Active); //get PrimaryPhoto Array
                    element.PrimaryPhotoArray = primaryPhotoArray;
                  }
                  element.PhotosArray = photoArray;
                  element.AttachmentsArray = this.setAttachmentArray(element.AttachmentIDs,element.AttachmentXIDs,element.AttachmentS3KeyNames, element.AttachmentDescriptions, element.AttachmentLogicalFileNames, element.AttachmentActives); 
                  element.AliasArray = this.setAliasArray( element.AliasIDs,element.AliasNames,element.AliasActives);
                  element.Aliases = element.AliasNames;
                  element.RecordID = element.PersonOfInterestID;  
                  element.Requester = new POIRequester(element.RequesterID,element.RequesterFirstName,element.RequesterLastName,element.RequesterEmail,element.RequesterPhone,element.RequesterCompany,element.RequesterDepartment,element.RequesterPosition,element.RequesterActive)               
                  
                });
              
                if(this.data){
                this.commonService.getPrimaryPhotoFiles(this.data).then(() =>{
                    this.commonService.primaryPhotoFileRecords.forEach(element => {
                      if(element.Photos.length > 0){
                        this.data.filter((f) => f.PersonOfInterestID == element.RecordID)[0].PrimaryPhotoArray[0].SignedURLThumbnail = element.Photos[0].SignedURLThumbnail;
                        this.data.filter((f) => f.PersonOfInterestID == element.RecordID)[0].PrimaryPhotoArray[0].SignedURL = element.Photos[0].SignedURL; 
                      }         
                    });
              
                  }).catch((err)=>{
                    this.notificationService.failure(err.message);
                  }).finally(()=>{
                    this.rowData = this.data;
                  });
                  
                } 
                
              } 
              this.spinnerService.setIsLoading(false);           
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
            this.spinnerService.setIsLoading(false);
          });

    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.data = this.searchService.searchRecords;
            this.data = this.data.filter((f) => f.Active)
            if (this.data) {
              this.data.forEach(element => {
               
                var photoArray: Photos[] = this.setPhotoArray( element.PhotoIDs,element.PhotoXIDs,element.PhotoS3KeyNames,element.PhotoDescriptions,element.PhotoLogicalFileNames,element.PhotoActives,element.PrimaryPhotoID);  
                element.PrimaryPhotoArray = [];
                if(photoArray.length > 0){
                  var primaryPhotoArray: Photos[] = photoArray.filter((f) => f.IsPrimary == true && f.Active); //get PrimaryPhoto Array
                  element.PrimaryPhotoArray = primaryPhotoArray;
                }
                element.PhotosArray = photoArray;
                element.AttachmentsArray = this.setAttachmentArray(element.AttachmentIDs,element.AttachmentXIDs,element.AttachmentS3KeyNames, element.AttachmentDescriptions, element.AttachmentLogicalFileNames, element.AttachmentActives); 
                element.AliasArray = this.setAliasArray( element.AliasIDs,element.AliasNames,element.AliasActives);
                element.Aliases = element.AliasNames;
                element.RecordID = element.PersonOfInterestID;  
                element.Requester = new POIRequester(element.RequesterID,element.RequesterFirstName,element.RequesterLastName,element.RequesterEmail,element.RequesterPhone,element.RequesterCompany,element.RequesterDepartment,element.RequesterPosition,element.RequesterActive)                              
                
               });
                  
              //call getPOIFiles Api to set base64 for primary Photo Id , hence pass only the primaryPhoto into primaryPhotoArray 
             
              if(this.data){
                this.commonService.getPrimaryPhotoFiles(this.data).then(() =>{
                  if(this.commonService.primaryPhotoFileRecords.length > 0){
                    this.commonService.primaryPhotoFileRecords.forEach(element => {
                      if(element.Photos.length > 0){
                        if(element.RecordID !== undefined) {
                        this.data.filter((f) => f.PersonOfInterestID
                         == element.RecordID)[0].PrimaryPhotoArray[0].SignedURLThumbnail = element.Photos[0].SignedURLThumbnail;
                        this.data.filter((f) => f.PersonOfInterestID == element.RecordID)[0].PrimaryPhotoArray[0].SignedURL 
                        = element.Photos[0].SignedURL; 
                      }     
                    }   
                    });
                  }
            
                }).catch((err)=>{
                  this.notificationService.failure(err.msg);
                }).finally(()=>{
                  this.rowData = this.data;
                  // go to first page on the ag grid after the search results are fetched
                  this.gridApi.paginationGoToPage(0);
                });;
              } 
              
            }
            this.spinnerService.setIsLoading(false);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
            this.spinnerService.setIsLoading(false);
          });
      }
    });

    this.personOfInterestService.personOfInterestDataChanged.subscribe(
      (dataChanged: boolean) => {
        if (dataChanged) {
          this.searchService
            .smartSearchAPI(null)
            .then(() => {
              this.data = this.searchService.searchRecords;
              this.data = this.data.filter((f) => f.Active)
              if (this.data) {
                this.data.forEach(element => {
                  var photoArray: Photos[] = this.setPhotoArray( element.PhotoIDs,element.PhotoXIDs,element.PhotoS3KeyNames,element.PhotoDescriptions,element.PhotoLogicalFileNames,element.PhotoActives,element.PrimaryPhotoID);  
                  element.PrimaryPhotoArray = [];
                  if(photoArray){
                    var primaryPhotoArray: Photos[] = photoArray.filter((f) => f.IsPrimary == true); //get PrimaryPhoto Array
                    element.PrimaryPhotoArray = primaryPhotoArray;
                  }
                  element.PhotosArray = photoArray;
                  element.AttachmentsArray = this.setAttachmentArray(element.AttachmentIDs,element.AttachmentXIDs,element.AttachmentS3KeyNames, element.AttachmentDescriptions, element.AttachmentLogicalFileNames, element.AttachmentActives); 
                  element.AliasArray = this.setAliasArray( element.AliasIDs,element.AliasNames,element.AliasActives);
                  element.Aliases = element.AliasNames;
                  element.RecordID = element.PersonOfInterestID;  
                  element.Requester = new POIRequester(element.RequesterID,element.RequesterFirstName,element.RequesterLastName,element.RequesterEmail,element.RequesterPhone,element.RequesterCompany,element.RequesterDepartment,element.RequesterPosition,element.RequesterActive)               
                 });
                    
                //call getPOIFiles Api to set base64 for primary Photo Id , hence pass only the primaryPhoto into primaryPhotoArray 
               
                if(this.data){
                  this.commonService.getPrimaryPhotoFiles(this.data).then(() =>{
                    this.commonService.primaryPhotoFileRecords.forEach(element => {
                        if(element.Photos.length > 0){
                          this.data.filter((f) => f.PersonOfInterestID == element.RecordID)[0].PrimaryPhotoArray[0].SignedURLThumbnail = element.Photos[0].SignedURLThumbnail;
                          this.data.filter((f) => f.PersonOfInterestID == element.RecordID)[0].PrimaryPhotoArray[0].SignedURL = element.Photos[0].SignedURL; 
                        }       
                      });
                    
              
                  }).catch((err)=>{
                    this.notificationService.failure(err.msg);
                  }).finally(()=>{
                    this.rowData = this.data;
                  });;
                } 
              }
              this.spinnerService.setIsLoading(false);
            })
            
        }
      }
    );
  }

  onGridReady(params) {
    this.gridApi = params.api;

    setTimeout(() => {
      params.api.showLoadingOverlay();
     }, 0);
     params.api.hideOverlay();
  }

  getDropdownData() {
    this.personOfInterestService.getRegionTree().then((result) => {
      this.treeDropdownService.setData(result);
    });
  }

  
  onSearchBtnClick() {
    const key = this.searchField.nativeElement.value.trim()
      ? this.searchField.nativeElement.value.trim()
      : null;
    this.searchService.smartSearchAPI(key);
    this.searchService.searchInitiated.emit(true);
  }
  // opens view poi modal on grid row click
  viewPoi(event: any) {
    this.clickedPoi = event.data;
    this.dialog.open(ViewPersonOfInterestModalComponent, {
      data: this.clickedPoi,
    });
  }

  setPhotoArray(PhotoIDs: string,PhotoXIDs: string,PhotoS3KeyNames : string,PhotoDescriptions : string,PhotoLogicalFileNames : string,PhotoActives : string, PrimaryPhotoID: number): Photos[]{
    var photosArray: Photos[] = [] ;
    
    if(PhotoIDs == null){      
      return photosArray;
    } else {
      var photoIdArray = PhotoIDs.split(",");
      var photoS3KeyNamesArray = PhotoS3KeyNames.split(",");
      var photoDescriptionsArray = PhotoDescriptions.split(",");
      var photoLogicalFileNamesArray = PhotoLogicalFileNames.split(",");
      var photoActivesArray = PhotoActives.split(",");
      var photoXID = PhotoXIDs.split(",");
      var index: number = 0;
      photoIdArray.forEach(element => {       
        var photo : Photos = {"XID":photoXID[index],"File": null,"ThumbnailFile" : null,"PhotoID": Number(photoIdArray[index]), Active: Boolean(Number(photoActivesArray[index])),Description: photoDescriptionsArray[index]
       , SignedURL:"",
        SignedURLThumbnail:"",
        LogicalFileName: photoLogicalFileNamesArray[index],
        S3KeyName: photoS3KeyNamesArray[index],
        UserID: 1,        
        IsPrimary : (Number(photoIdArray[index]) == PrimaryPhotoID)? true : false,
        Index: 0,
        BaseName:photoLogicalFileNamesArray[index].replace(/\.[^/.]+$/, ""),
        Ext:photoLogicalFileNamesArray[index].substring(photoLogicalFileNamesArray[index].lastIndexOf('.')+1, photoLogicalFileNamesArray[index].length)|| "",
        
      }
      photosArray.push(photo);
      index++;
       
      });
      return photosArray;
    }
  }

  setAttachmentArray(AttachmentIDs: string,AttachmentXIDs: string,AttachmentS3KeyNames : string,AttachmentDescriptions : string,AttachmentLogicalFileNames : string,AttachmentActives : string):Attachments[]{
    var attachmentsArray: Attachments[] = [] ;
   
    if(AttachmentIDs == null){      
      return attachmentsArray;
    } else {
      var attachmentIdArray = AttachmentIDs.split(",");
      var attachmentS3KeyNamesArray = AttachmentS3KeyNames.split(",");
      var attachmentDescriptionsArray = AttachmentDescriptions.split(",");
      var attachmentLogicalFileNamesArray = AttachmentLogicalFileNames.split(",");
      var attachmentActivesArray = AttachmentActives.split(",");
      var attcahmnetXidsArray = AttachmentXIDs.split(",")
      var index: number = 0;
      attachmentIdArray.forEach((element) => {
        var attachment: Attachments = {
          XID: attcahmnetXidsArray[index],
          AttachmentID: Number(attachmentIdArray[index]),
          Active: Boolean(Number(attachmentActivesArray[index])),
          Description: attachmentDescriptionsArray[index],
          LogicalFileName: attachmentLogicalFileNamesArray[index],
          S3KeyName: attachmentS3KeyNamesArray[index],
          UserID: 1,
          Path: "",
          SignedURL: "",
          File: null,
          Index:0,
          BaseName:attachmentLogicalFileNamesArray[index].replace(/\.[^/.]+$/, ""),
          Ext:attachmentLogicalFileNamesArray[index].substring(attachmentLogicalFileNamesArray[index].lastIndexOf('.')+1, attachmentLogicalFileNamesArray[index].length)|| "",
          OrginalFileName:attachmentLogicalFileNamesArray[index]
        };
        index++;
        attachmentsArray.push(attachment);
      });
      return attachmentsArray;
    }
  }

  setAliasArray(AliasIDs: string, AliasNames: string, AliasActives: string) {
    var aliasArray: Array<{
      Active: number;
      AliasID: number;
      Name: string;
      UserID: number;
    }> = [];

    if (AliasIDs == null) {
      return aliasArray;
    } else {
      var aliasIdArray = AliasIDs.split(",");
      var aliasNamesArray = AliasNames.split(",");
      var aliasActivesArray = AliasActives.split(",");
      var index: number = 0;
      aliasIdArray.forEach((element) => {
        var alias = {
          Active: Number(aliasActivesArray[index]),
          AliasID: Number(aliasIdArray[index]),
          Name: aliasNamesArray[index],
          UserID: 1,
        };
        index++;
        aliasArray.push(alias);
      });
      return aliasArray
    }
  }


  heightFormatter(params: any){
    if(params.value){
      var realFeet = ((params.value*0.393700) / 12);      
      var feet = Math.floor(realFeet);
      var inches = Math.round((realFeet - feet) * 12);
      return
      feet +
        "' " +
        inches? (inches) +
        '"' :"";
    }
  }

 deletePOI() {
    this.dialogService
      .openConfirmDialog(
        "Confirm Person Of Interest Delete",
        "Are you sure you want to delete selected Person Of Interest ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {        
         
              this.personOfInterestService.deletePOI(
                this.selectedPoi
              ).then().catch((err) => {
                this.notificationService.failure(err)
              });
              
            }  
      });

   
  }

  // open search filter modal
  onFilterBtnClick() {
    const dialogRef = this.dialog.open(SearchFilterModalComponent, {
      disableClose: false,
      data: { pageName: this.searchService.getPageName() },
      backdropClass: "backdropBackground",
    });
  }

  
}
