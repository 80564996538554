// Author: T4professor

import { Component, Inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DialogService } from '../services/dialog.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-slider-renderer',  
  styleUrls: ["./slider-renderer.component.css"],
  templateUrl: "./slider-renderer.component.html",
 
})

export class SliderRendererComponent implements ICellRendererAngularComp {
  params;
  label: string;
  constructor(@Inject(MAT_DIALOG_DATA)
  
  protected dialog: MatDialog,
  private dialogService: DialogService){
      
  }
  

  agInit(params): void {
    this.params = params;
    console.log(this.params);
  }

  refresh(params: any): boolean {
    return true;
  }

  onClick($event) {
    this.params.data.Default = true;
    this.params.node.setDataValue("Default",true);       
   
  }
}