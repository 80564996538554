import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
  AfterContentInit,
  AfterViewChecked,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgForm, FormControl, Validators } from "@angular/forms";

import { MatInput } from "@angular/material";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { RequiredFieldsService } from "../services/required-fields.service";

export interface DialogData {}

interface Status {
  value: string;
  viewValue: string;
}
interface State {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-new-record",
  templateUrl: "./new-record.component.html",
  styleUrls: ["./new-record.component.css"],
})
export class NewRecordComponent
  implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild("formRef") newRecordForm: NgForm;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private requiredFieldService: RequiredFieldsService
  ) {}

  ngOnInit() {
    // console.log(this.newRecordForm.value);
    // this.setRequiredFields();
  }

  ngAfterViewInit() {
    // this.setRequiredFields();
  }

  ngAfterViewChecked() {
    this.setRequiredFields();
  }

  states: State[] = [
    { value: "AL", viewValue: "Alabama" },
    { value: "AK", viewValue: "Alaska" },
    { value: "AZ", viewValue: "Arizona" },
    { value: "AR", viewValue: "Arkansas" },
    { value: "CA", viewValue: "California" },
    { value: "CO", viewValue: "Colorado" },
    { value: "CT", viewValue: "Connecticut" },
    { value: "DE", viewValue: "Delaware" },
    { value: "DC", viewValue: "District of Columbia" },
    { value: "FL", viewValue: "Florida" },
    { value: "GA", viewValue: "Georgia" },
    { value: "HI", viewValue: "Hiwaii" },
    { value: "ID", viewValue: "Idaho" },
    { value: "IL", viewValue: "Illinois" },
    { value: "IN", viewValue: "Indiana" },
    { value: "IA", viewValue: "Iowa" },
    { value: "KS", viewValue: "Kansas" },
    { value: "KY", viewValue: "Kentucky" },
    { value: "LA", viewValue: "Louisiana" },
    { value: "ME", viewValue: "Maine" },
    { value: "MD", viewValue: "Maryland" },
    { value: "MA", viewValue: "Massachusetts" },
    { value: "MI", viewValue: "Michigan" },
    { value: "MN", viewValue: "Minnesota" },
    { value: "MS", viewValue: "Mississippi" },
    { value: "MO", viewValue: "Missouri" },
    { value: "MT", viewValue: "Montana" },
    { value: "NE", viewValue: "Nebraska" },
    { value: "NV", viewValue: "Nevada" },
    { value: "NH", viewValue: "New Hampshire" },
    { value: "NJ", viewValue: "New Jersey" },
    { value: "NM", viewValue: "New Mexico" },
    { value: "NY", viewValue: "New York" },
    { value: "NC", viewValue: "North Carolia" },
    { value: "ND", viewValue: "North Dakota" },
    { value: "OH", viewValue: "Ohio" },
    { value: "OK", viewValue: "Oklahoma" },
    { value: "OR", viewValue: "Oregon" },
    { value: "PA", viewValue: "Pennsylvania" },
    { value: "RI", viewValue: "Rhode Island" },
    { value: "SC", viewValue: "South Carolina" },
    { value: "SD", viewValue: "South Dakota" },
    { value: "TN", viewValue: "Tennessee" },
    { value: "TX", viewValue: "Texas" },
    { value: "UT", viewValue: "Utah" },
    { value: "VT", viewValue: "Vermont" },
    { value: "VA", viewValue: "Virginia" },
    { value: "WA", viewValue: "Washington" },
    { value: "WV", viewValue: "West Virginia" },
    { value: "WI", viewValue: "Wisconsin" },
    { value: "WY", viewValue: "Wyoming" },
  ];
  //options for status dropdown
  statuses: Status[] = [
    { value: "Active", viewValue: "Active" },
    { value: "Retired", viewValue: "Retired" },
    { value: "No Hire", viewValue: "No Hire" },
    { value: "Deceased", viewValue: "Deceased" },
  ];
  //autoformats ssn input
  ssnMask(event) {
    var val = event.target.value.replace(/\D/g, "");
    var newVal = "";
    if (val.length > 4) {
      event.target.value = val;
    }
    if (val.length > 3 && val.length < 6) {
      newVal += val.substr(0, 3) + "-";
      val = val.substr(3);
    }
    if (val.length > 5) {
      newVal += val.substr(0, 3) + "-";
      newVal += val.substr(3, 2) + "-";
      val = val.substr(5);
    }
    newVal += val;
    event.target.value = newVal.substring(0, 11);
  }

  public getForm() {
    console.log(this.newRecordForm.controls);

    // for (const field in this.newRecordForm.controls) {
    //   if (field === "firstName") {
    //     console.log(field);
    //     const control = this.newRecordForm.form.get(field); // 'control' is a FormControl
    //     control.setValidators([Validators.required]);

    //     this.newRecordForm.controls[field].updateValueAndValidity();
    //     console.log(document.getElementsByName(field)[0]);
    //   }
    // }

    this.setRequiredFields();
  }

  setRequiredFields() {
    this.requiredFieldService.setRequiredFields(
      "PersonOfInterest",
      this.newRecordForm
    );
  }
}
