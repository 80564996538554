import { Injectable } from "@angular/core";
import { PickList } from "../models/pickList.model";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  constructor() {}

  // the method should be called to search and sort a key from an array of strings
  public searchSortKeyUtil(strArray: Array<string>, input: string) {
    const weightedInp: Array<{ str: string; weight: number }> = [];

    // convert input and compared string to lowercase
    strArray = strArray
      .filter((s) => s.toLowerCase().includes(input.toLowerCase()))
      .sort();

    // split the each array element(sentence) into words
    strArray.forEach((s) => {
      const rawWords = s.split(" ");
      let w = 0;
      for (let i = 0; i < rawWords.length; i++) {
        // split the word into group of strings and numbers
        const words = rawWords[i].split(/(\d+)/);

        for (let j = 0; j < words.length; j++) {
          let word: any = words[j];

          if (word === "") continue;

          word = isNaN(+words[j]) ? words[j] : +words[j];
          word = word.toString();

          // console.log(s, words[j], word);

          // const isMatchFound = words[j].includes(input);
          const isMatchFound = word.toLowerCase().includes(input.toLowerCase());
          if (isMatchFound) {
            console.log(word, s);
            w += 10 / (word.length + j + i * 10);
          }
        }
      }

      weightedInp.push({
        str: s,
        weight: w,
      });
    });

    weightedInp.sort((a, b) => b.weight - a.weight);
    console.log(weightedInp);

    return weightedInp.map((wi) => wi.str);
  }

   // the method should be called to search and sort a key from an array of PickList
  public searchSortKeyUtilForPickList(strArray: Array<PickList>, input: string) {
    const weightedInp: Array<{ str: PickList; weight: number }> = [];

    // convert input and compared string to lowercase
    strArray = strArray
      .filter((s) => s.FieldName.toLowerCase().includes(input.toLowerCase()))
      .sort();

    // split the each array element(sentence) into words
    strArray.forEach((s) => {
      const rawWords = s.FieldName.split(" ");
      let w = 0;
      for (let i = 0; i < rawWords.length; i++) {
        // split the word into group of strings and numbers
        const words = rawWords[i].split(/(\d+)/);

        for (let j = 0; j < words.length; j++) {
          let word: any = words[j];

          if (word === "") continue;

          word = isNaN(+words[j]) ? words[j] : +words[j];
          word = word.toString();

          // console.log(s, words[j], word);

          // const isMatchFound = words[j].includes(input);
          const isMatchFound = word.toLowerCase().includes(input.toLowerCase());
          if (isMatchFound) {
            console.log(word, s);
            w += 10 / (word.length + j + i * 10);
          }
        }
      }

      weightedInp.push({
        str: s,
        weight: w,
      });
    });

    weightedInp.sort((a, b) => b.weight - a.weight);
    console.log(weightedInp);

    return weightedInp.map((wi) => wi.str);
  }
}
