import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { SearchService } from '../services/search.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-login-registration',
  templateUrl: './login-registration.component.html',
  styleUrls: ['./login-registration.component.css'],
})
export  class LoginRegistrationComponent implements OnInit {
  constructor(private router: Router, protected authService: AuthService,protected searchService:SearchService,protected commonService:CommonService) {
    this.isLoginBtnDisabled = false;
  }

  formLoading = false;
  labelPosition = 'after';
  public guestDomainRegistration: boolean;
  public wbDomain: boolean;
  public guestDomainLogin: boolean;
  public isLoginBtnDisabled: boolean;
  public selectedApp : number ;

  
  ngOnInit() { }
  public onLoadingClick() {
    this.formLoading = false;
  }

  public onLoginClick() {
    this.isLoginBtnDisabled = true;
    this.authService.signedInFlag = true;
    
  }
  onBlurRegistration(event: any) {
    const userEmail = event.target.value;
    const domain = userEmail.substring(userEmail.lastIndexOf('@') + 1);
    if (domain === 'warnerbros.com' || domain === 'wb.com' || domain === '') {
      this.guestDomainRegistration = false;
    } else {
      this.guestDomainRegistration = true;
    }
  }
  onBlurLogin(event: any) {
    const userEmail = event.target.value;
    const domain = userEmail.substring(userEmail.lastIndexOf('@') + 1);
    if (domain === 'warnerbros.com' || domain === 'wb.com' || domain === '') {
      this.guestDomainLogin = false;
    } else {
      this.guestDomainLogin = true;
    }
  }
  myLoadEvent() {
    this.formLoading = true;
  }
}
