import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Input, OnInit } from '@angular/core';
import { Component, Injectable } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { TodoItemFlatNode, TodoItemNode, TreeDropdownDynamicDatabaseService } from 'src/app/services/tree-dropdown-dynamic-database.service';

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.css']
})
export class TreeViewComponent implements OnInit {
  @Input() selectedRegions: any[] = [];
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';
  dropdownData = [];
  selectedItems: TodoItemFlatNode[] = [];
  selectedFInalItems: TodoItemFlatNode[] = [];

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
  constructor(private treeDropdownService: TreeDropdownDynamicDatabaseService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    treeDropdownService.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }
  ngOnInit(): void {
    this.treeDropdownService.initialize();
    setTimeout(() => {
      this.setSelectedNodes();
    });

  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.itemId = node.itemId;
    flatNode.expandable = !!node.children?.length;
    flatNode.parentId = node.parentId;
    flatNode.selected = false;
    flatNode.children = node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    this.setSelected(node);
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  // setSelected(node: TodoItemFlatNode): void {
  //   if (node.selected) {
  //     this.selectedItems.splice(this.selectedItems.indexOf(node));
  //   } else {
  //     if (this.selectedItems.indexOf(node) === -1) {
  //       node.selected = true;
  //       this.selectedItems.push(node);
  //     }
  //   }
  //   this.setFinalNodes(this.selectedItems);
  //   setTimeout(() => {
  //     this.treeDropdownService.setSelectedDataRegions(this.selectedFInalItems);
  //   });
  // }

  setSelected(node: TodoItemFlatNode): void {
    this.selectedItems.forEach(element => {
      if(!this.checklistSelection.isSelected(element)) {
        this.selectedItems.splice(this.selectedItems.indexOf(element));
      }
    });
    if (this.checklistSelection.isSelected(node)) {
      if (this.selectedItems.indexOf(node) === -1) {
        this.selectedItems.push(node);
      } else {
        this.selectedItems.splice(this.selectedItems.indexOf(node));
      }
    }
    this.selectedFInalItems = [];
    this.setFinalNodes(this.selectedItems);
    setTimeout(() => {
      this.treeDropdownService.setSelectedDataRegions(this.selectedFInalItems);
    });
  }

  setFinalNodes(items) {
    items.forEach(item => {
      if (item.children.length === 0 && this.selectedFInalItems.indexOf(item) === -1) {
        this.selectedFInalItems.push(item);
      } else {
        this.setFinalNodes(item.children);
      }
    });
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  setSelectedNodes() {
    this.selectedRegions.forEach(region => {
      this.treeControl.dataNodes.forEach(node => {
        if (node.itemId == region.RegionID) {
          if (this.selectedItems.indexOf(node) === -1) {
            this.todoItemSelectionToggle(node);
            this.treeControl.expand(node);
            this.treeControl.dataNodes.forEach(pNode => {
              if (pNode.itemId === node.parentId) {
                this.treeControl.expand(pNode);
              }
            });
          }
        }
      });
    });
  }
}
