import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class Base64Service {
  constructor() {}

  // converts a file to Base64 string
  public encodeFileToBase64(file: File) {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = (error) => reject(error);
    });

    return promise;
  }

  // decodes and download base64 URL strings
  public downloadFromBase64(base64: string, logicalFileName: string) {
    const linkSource = `${base64}`;
    const downloadLink = document.createElement("a");
    const fileName = logicalFileName;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}
