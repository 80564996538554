import { Injectable, OnInit } from "@angular/core";
import { RequiredFieldsService } from "./required-fields.service";
import { CommonService } from './common.service';

@Injectable({
  providedIn: "root",
})
export class BootstrapService {
  constructor(private requiredFieldsService: RequiredFieldsService, private commonService:CommonService) {}

  public async callServices() {
    this.requiredFieldsService.getRequiredFieldsAPI();
   

  }
}
