import {Component, OnInit} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import { link } from 'fs';
import { Attachments } from './models/attachments.model';
import { Base64Service } from './services/base64.service';
import { CommonService } from './services/common.service';
import { NotificationService } from './services/notification.service';

@Component({
    selector: 'child-cell',
    styleUrls: ["./hyperLink.component.css"],
    // template: `<div> <a style="color:#007bff;" (click)="download()"> {{logicalFileName}} </a> </div>`,   
    template : `<button class="download-btn" (click)="download()">
    <mat-icon>download</mat-icon>
</button>` ,     
                
    styles: [
        `.btn {
            line-height: 0.5
        }`
    ]
})
export class HyperLinkComponent implements OnInit,ICellRendererAngularComp {
    public params: any;
     logicalFileName;
     path;

    constructor (protected commonService:CommonService,protected notificationService:NotificationService){

    }
    ngOnInit(){
        console.log("Init")
    }

    agInit(params: any): void {
        console.log(params);
        this.params = params;
        this.path = this.params.data.SignedURL;
        this.logicalFileName = this.params.data.LogicalFileName;
    }

    

    refresh(params : any): boolean {
        this.logicalFileName = params.value;
        this.params = params;
        return true;
    }

    download(){
    console.log(this.params.data);
    console.log(this.params.data.AttachmentID > 0  && this.params.data.LogicalFileName != this.params.data.OrginalFileName)
    if(this.params.data.AttachmentID > 0  && this.params.data.LogicalFileName != this.params.data.OrginalFileName){
       
        var attachmentArray: Attachments[] = [] ;
        attachmentArray.push(this.params.data)
        console.log(attachmentArray)
        this.commonService.getAllFiles([],attachmentArray,0).then(() =>{
            console.log(this.commonService.allFiles);
            attachmentArray = [];
             attachmentArray = this.commonService.allFiles[0].Attachments.filter((f) => f.Active);
            
      
          }).catch((err)=>{
            this.notificationService.failure(err.msg);
          }).finally(() => {   
            const downloadLink = document.createElement("a");    
            downloadLink.download = attachmentArray[0].LogicalFileName;
            downloadLink.href = attachmentArray[0].SignedURL;
            console.log(downloadLink)
            downloadLink.click();
          });
    }else{            
        
        console.log(this.params.data.LogicalFileName)
        const linkSource = `${this.path}`;    
        const downloadLink = document.createElement("a");    
        downloadLink.download = this.params.data.LogicalFileName;
        console.log(linkSource)
        downloadLink.href = linkSource;
        console.log(downloadLink)
        downloadLink.click();
       
    }
    
    }

    
}

