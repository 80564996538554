import { Component, OnInit } from "@angular/core";
import { SearchService } from "../services/search.service";
import { UserService } from "../services/user.service";
import { AuthService } from "../services/auth.service";
import { environment } from "src/environments/environment";
import { UserActionService } from "../services/user-action.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  public menuOpen: boolean;
  public menuClosed: boolean;
  protected selectedApp: number;
  protected version: string;

  private oldAppID: number;

  frameworkComponents: any;

  constructor(
    protected searchService: SearchService,
    protected userService: UserService,
    private authService: AuthService,
    private userActionService: UserActionService
  ) {
    this.version = environment.VERSION + "-" + environment.environment; // read the application version
    this.selectedApp = this.searchService.selectedApplicationID;
    this.oldAppID = this.selectedApp;
  }

  ngOnInit() {
    // this.selectedApp = this.userService.userApplications.filter(
    //   (f) => f.Name === this.searchService.getPageName()
    // )[0].ApplicationID;
  }
  isOpened() {
    this.menuOpen = true;
  }
  isClosed() {
    this.menuOpen = false;
  }
  onApplicationChange() {
    const userApplications = this.userService.userApplications;

    const newAppName = userApplications.find(
      (ua) => ua.ApplicationID === this.selectedApp
    ).Name;
    const oldAppName = userApplications.find(
      (ua) => ua.ApplicationID === this.oldAppID
    ).Name;


    // log it into user actions
    this.userActionService.recordUserActionAPI(
      "Application Change",
      `Changed From: ${oldAppName} (${this.oldAppID}) To: ${newAppName} (${this.selectedApp})`
    );

    this.oldAppID = this.selectedApp;
    localStorage["AddressFieldRequired"] = this.selectedApp == 2 ? false : true;

    this.searchService.switchApplication(this.selectedApp);
  }

  protected logOut() {
    this.authService.logout();
  }
}
