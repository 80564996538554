import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpResponse,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { OktaAuthService } from "@okta/okta-angular";
import { UserActionService } from "./user-action.service";

@Injectable()
export class AuthService {
  signedInFlag: boolean;
  isAdmin: boolean = false;
  constructor(
    private http: HttpClient,
    private oktaAuthService: OktaAuthService,
    private userActionsService: UserActionService
  ) {
    this.signedInFlag = false;
    this.isAdmin = false;
    localStorage["isUserRegistered"] = false;
  }

  public async logout() {
    // this.signedInFlag = false;
    localStorage["isUserRegistered"] = false;
    this.oktaAuthService.logout();
    // this.oktaAuthService.logout();
    localStorage.clear();

    // log it into user action history
    await this.userActionsService.recordUserActionAPI("Logout", null);

    console.log("Logged out from the application.");

    // this.name = null;
  }
}
