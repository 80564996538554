export class Event {

    XID: string;
    EventID: number;
    Type: number;
    SiteID: number;
    Description: string;
    StartTime: string;
    EndTime : string;
    POINoteEvent : Array<{
        NoteID: number
        EventID: number;
        Note: string;
        Active: boolean;
    }>;
    Active:boolean;
    IsEdit: boolean;
    
}
