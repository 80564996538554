import { Component, OnInit, Inject, DoCheck } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { PersonOfInterest } from "src/app/models/person-of-interest.model";
import { DialogService } from "src/app/services/dialog.service";
import { PickList } from "src/app/models/pickList.model";
import { GridApi } from "ag-grid-community";
import { RequiredFieldsService } from "src/app/services/required-fields.service";
import { PersonOfInterestService } from "src/app/services/person-of-interest.service";
import { CommonService } from "src/app/services/common.service";
import { NotificationService } from "src/app/services/notification.service";
import { Base64Service } from "src/app/services/base64.service";
import { UpdatePersonOfInterestModalComponent } from "../update-person-of-interest/update-person-of-interest-modal.component";
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: "app-view-person-of-interest-modal",
  templateUrl: "./view-person-of-interest-modal.component.html",
  styleUrls: ["./view-person-of-interest-modal.component.css"],
})
export class ViewPersonOfInterestModalComponent implements OnInit {
  protected step = 0;
  protected personOfInterest: PersonOfInterest;
  protected genderList: PickList[];
  protected restrictedReasonList: PickList[];
  protected restrictedTypeList: PickList[];
  protected raceList: PickList[];
  protected hairList: PickList[];
  protected eyesList: PickList[];

  protected addedAliases: Array<String> = [];
  protected alias: string;
  protected restricted: boolean = false;
  protected isRestrictedReasonloading: boolean = false;
  protected isGenderLoading: boolean = false;
  protected gridApi: GridApi;
  protected defaultImageIndex = undefined;
  protected maxDate;
  protected height: string;
  protected SignedURL: string;

  protected monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  protected signedURL : string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PersonOfInterest,
    protected dialogRef: MatDialogRef<ViewPersonOfInterestModalComponent>,
    protected dialog: MatDialog,
    protected requiredFieldService: RequiredFieldsService,
    protected personOfInterestService: PersonOfInterestService,
    protected commonService: CommonService,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected base64Service: Base64Service,
    protected searchService:SearchService,
  ) {}

  ngOnInit(): void {
    this.personOfInterest = { ...this.data };
    if (this.personOfInterest.PrimaryPhotoArray.length > 0) {
      this.signedURL = this.personOfInterest.PrimaryPhotoArray[0].SignedURL;
    } else {
      this.signedURL = '../../../../assets/profile-picture/profileImage.png';
    }
    console.log(this.signedURL);
    console.log(this.personOfInterest);
    console.log(this.addedAliases);
    if (
      this.commonService.pickList != undefined &&
      this.commonService.pickList.filter((f)=>f.TableName === this.searchService.getPageName()).length > 0
    ) {
      this.genderList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Gender"
      );
      this.raceList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Race"
      );
      this.hairList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Hair"
      );
      this.eyesList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Eyes"
      );
      this.restrictedReasonList = this.commonService.getPickListData(
        "PersonOfInterest",
        "RestrictedReason"
      );
      this.restrictedTypeList = this.commonService.getPickListData(
        "PersonOfInterest",
        "RestrictionType"
      );
    } else {
      this.commonService
        .getPickListApi()
        .then(() => {
          if (
            this.commonService.pickList != undefined &&
            this.commonService.pickList.length > 0
          ) {
            this.genderList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Gender"
            );
            this.raceList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Race"
            );
            this.restrictedReasonList = this.commonService.getPickListData(
              "PersonOfInterest",
              "RestrictedReason"
            );
            this.restrictedTypeList = this.commonService.getPickListData(
              "PersonOfInterest",
              "RestrictionType"
            );
          }
        })
        .catch((err) => {
          this.notificationService.failure(err);
        });
    }

    // calculating height
    // var realFeet = ((this.data.Height*0.393700) / 12);      
    // var feet = Math.floor(realFeet);
    // var inches = Math.round((realFeet - feet) * 12);
    // this.height =
    // feet +
    //   "' " +
    //   (+inches) +
    //   '"';
  }
  columnDefs = [
    {
      headerName: "Name",
      field: "fileName",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      cellRenderer: "hyperLinkComponent",
      width: 200,
    },
    {
      headerName: "Description",
      field: "description",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      editable: true,
      resizable: true,
      width: 567,
    },
    {
      headerName: "",
      cellRenderer: "buttonRenderer",
      resizable: true,
      cellRendererParams: {
        onClick: this.onBtnClick.bind(this),
        label: "Remove",
      },
      width: 100,
    },
    {
      headerName: "",
      field: "delete",
      disabled: true,
      resizable: true,
      hide: true,
    },
  ];
  onBtnClick(e) {
    console.log("button Clicked");
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //close
  close() {
    this.onNoClick();
  }
  //opens edit modal, send row data to modal
  updatePoi() {
    this.onNoClick();
    this.dialog.open(UpdatePersonOfInterestModalComponent, {
      data: this.personOfInterest,
    });
  }
  panelExpanded(){}
}
