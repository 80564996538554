import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  NgZone,
} from "@angular/core";
import {
  MatExpansionPanel,
  MatDialogRef,
  MatCheckboxChange,
  MatSelectChange,
  MAT_DIALOG_DATA,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatAutocompleteTrigger,
  MatDialog,
} from "@angular/material";
import { NgForm, FormControl } from "@angular/forms";
import { SearchService } from "src/app/services/search.service";
import { Observable, ObservableLike } from "rxjs";
import { filter, map, startWith, take } from "rxjs/operators";
import { PickList } from "src/app/models/pickList.model";
import { CommonService } from "src/app/services/common.service";
import { UtilityService } from "src/app/services/utility.service";
import { NotificationService } from "src/app/services/notification.service";
import { DialogService } from "src/app/services/dialog.service";
import { Zipcode } from "src/app/models/zipcode.model";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { Event } from "src/app/models/event.model";

@Component({
  selector: "app-event-modal",
  templateUrl: "./event.modal.component.html",
  styleUrls: ["./event.modal.component.css"],
  providers: [
    
  ],
})
export class EventModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("filterForm") filterForm: NgForm;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }
  protected step = 0;
  protected loaded = true;
  protected disabled : boolean = false;
  protected hideEvent: boolean = true;
  protected newNotes: string = "";
  protected minDate;
  
  protected eventTypeList: PickList[] = [];
  protected siteList: any[]= [];
 

  @ViewChild("formRef") addressForm: NgForm;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Event,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<EventModalComponent>,
    public searchService: SearchService,
    public commonService:CommonService,
    public utilService:UtilityService,
    public notificationService:NotificationService,
    public dialogService:DialogService,
    private _ngZone: NgZone 
  ) {
    console.log(data);
  }

  async ngOnInit() {
    
    console.log(this.data);
    if(!this.data.IsEdit) {
      this.data.POINoteEvent = [];
    }

   this.commonService
        .getPickListApi()
        .then(() => {
          if (
            this.commonService.pickList != undefined &&
            this.commonService.pickList.length > 0
          ) {           
            
            this.eventTypeList = this.commonService.getPickListData(
              this.searchService.getTableName(),
              "EventType"
            );
           
           
          }
        })
        .catch((err) => {
          this.notificationService.failure(err);
        });

   this.commonService
        .getSiteListApi()
        .then(() => {
          if (
            this.commonService.siteList != undefined &&
            this.commonService.siteList.length > 0
          ) {         
            
            this.siteList = this.commonService.siteList;
           
          }
        })
        .catch((err) => {
          this.notificationService.failure(err);
        });
  
  }

  onNoClick() {  
    if(this.data.IsEdit)this.dialogRef.close(this.data);
    else this.dialogRef.close();
}

  onAddEvent(){ 
    if(!this.data.EventID){
      this.data.EventID = 0
      this.data.Active = true;
    }
    if(this.newNotes){
      const notes = {EventID: 0,NoteID: 0,Note:this.newNotes,Active:true};     
      this.data.POINoteEvent.unshift(notes);
    }
    this.dialogRef.close(this.data);
  }
  startDateChanged(event) {
    console.log(event)
    this.minDate = event.value;
  }
  

  


  
}
