import { Component, OnInit, Inject, DoCheck } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { DialogService } from "src/app/services/dialog.service";

@Component({
  selector: "app-view-photo-modal",
  templateUrl: "./view-photo-modal.component.html",
  styleUrls: ["./view-photo-modal.component.css"],
})
export class ViewPhotoModalComponent implements OnInit {
  photo: { id: number; url: string ,isPrimary:boolean, remove:boolean};
  step : number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialogRef: MatDialogRef<ViewPhotoModalComponent>,
    protected dialogService: DialogService,
    protected dialog: MatDialog
  ) {
    this.step = 0;
  }

  ngOnInit(): void {
    this.photo = { ...this.data };

    console.log(this.photo);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  

  //removes the images from the Array
  public removeImage() {
    this.dialogService
      .openConfirmDialog(
        "Delete Image",
        "Are you sure you want to Delete the Image ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm){
          this.dialogRef.close(`removePhoto`);
        } 
      });
  }

  //removes the images as default
  public removeDefaultImage() {
    this.dialogService
      .openConfirmDialog(
        "Remove Default",
        "Are you sure you want to remove Photo as default  ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm){
          this.dialogRef.close(`removeDefault`);
        } 
      });
  }

  //sets the default image
  public setDefaultImage(imageIndex: number) {
   this.dialogRef.close(`setPrimayPhoto`)
  }

  setStep(index: number): void {
    this.step = index;
  }
}

