import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';

/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[];
  itemId: string;
  parentId: string;
  item: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  itemId: string;
  level: number;
  expandable: boolean;
  selected: boolean;
  parentId: string;
  children: TodoItemNode[];
}
/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable({
  providedIn: 'root'
})
export class TreeDropdownDynamicDatabaseService {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  selectedDataRegions = new BehaviorSubject<any[]>([]);

  get data(): TodoItemNode[] { return this.dataChange.value; }
  treeData;

  constructor() {
  }

  setData(treeData) {
    this.treeData = treeData;
  }

  getSelectedDataRegions() {
    return this.selectedDataRegions;
  }

  setSelectedDataRegions(data) {
    this.selectedDataRegions.next(data);
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const data = this.buildFileTree(this.treeData, 0);


    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
   buildFileTree(obj: { [key: string]: any }, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();

      if (typeof value === 'object') {
        node.item = value.Name;
        node.itemId = value.RegionID;
        node.parentId = value.ParentRegionID;
        node.children = this.buildFileTree(value.Children, level + 1);
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({ item: name } as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }

}
