import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NgForm, Validators } from "@angular/forms";
import { MatInput, MatSelect } from "@angular/material";
import { coerceBooleanProperty } from "@angular/cdk/coercion";

@Injectable({
  providedIn: "root",
})
export class RequiredFieldsService {
  private requiredFields: Array<{ PageName: string; ControlName: string }>;

  constructor(private http: HttpClient) {}

  // reads the required fields from the S3
  public async getRequiredFieldsAPI() {
    const endPointURL = "assets/required-fields/required-fields.json";
    const response = await this.http
      .get<{ PageName: string; ControlName: string }[]>(endPointURL)
      .toPromise();

    this.requiredFields = response;
    return response;
  }

  // return the list of required controls by page name
  private getRequiredFieldByPage(pageName: string): string[] {
    return [
      ...this.requiredFields
        .filter((rf) => rf.PageName === pageName)
        .map((rf) => rf.ControlName),
    ];
  }

  // dynamically mark the required fields
  public setRequiredFields(pageName: string, ngForm: NgForm) {
    const requiredFieldNames = this.getRequiredFieldByPage(pageName).map((c) =>
      c.toLowerCase()
    );

    const fieldControls = ngForm.controls;
    // console.log(ngForm.controls);

    for (const field in fieldControls) {
      //   console.log(field);
      //   console.log(requiredFieldNames.includes(field.toLowerCase()));
      if (requiredFieldNames.includes(field.toLowerCase())) {
        // console.log(field);
        const control = ngForm.form.get(field); // 'control' is a FormControl
        control.setValidators([Validators.required]);
        control.updateValueAndValidity();
      }
    }

    this.fixAsteriskIssue();
  }

  // work around to display asterisk for required fields in reactive forms
  private fixAsteriskIssue() {
    /**
     * Fix for the MatInput required asterisk.
     */
    const requiredImplementation = {
      get: function (): boolean {
        if (this._required) {
          return this._required;
        }

        // The required attribute is set
        // when the control return an error from validation with an empty value
        if (
          this.ngControl &&
          this.ngControl.control &&
          this.ngControl.control.validator
        ) {
          const emptyValueControl = Object.assign({}, this.ngControl.control);
          (emptyValueControl as any).value = null;
          return (
            "required" in
            (this.ngControl.control.validator(emptyValueControl) || {})
          );
        }
        return false;
      },
      set: function (value: boolean) {
        this._required = coerceBooleanProperty(value);
      },
    };
    Object.defineProperty(
      MatInput.prototype,
      "required",
      requiredImplementation
    );
    Object.defineProperty(
      MatSelect.prototype,
      "required",
      requiredImplementation
    );
  }
}
