export class Address {
    XID: string;
    Active: boolean;
    AddressID: number;
    AddressType: number;
    Address: string;
    Address2: string;
    Country: number;
    State : number;
    City : number;
    Zip :number;
    Default: boolean;
    AddressTypeName:String;
    CountryName: string;
    StateName : string;
    CityName : string;
    ZipName :string;
    isEdit : boolean;
    
}
