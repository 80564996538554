export class PickList {  
  
  PickListID: number;
  TableName: string;
  FieldName: string;
  DisplayValue: string;
  ParentFieldName: string;
  ParentFieldValue: number;
  Active: number;
  


}
