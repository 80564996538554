import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import {
  MatExpansionPanel,
  MatDialogRef,
  MatCheckboxChange,
  MatSelectChange,
  MAT_DIALOG_DATA,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatAutocompleteTrigger,
  MatDialog,
} from "@angular/material";
import { NgForm, FormControl } from "@angular/forms";
import { SearchService } from "src/app/services/search.service";
import { Observable, ObservableLike, of } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";
import { PickList } from "src/app/models/pickList.model";
import { CommonService } from "src/app/services/common.service";
import { UtilityService } from "src/app/services/utility.service";
import { NotificationService } from "src/app/services/notification.service";
import { DialogService } from "src/app/services/dialog.service";
import { Zipcode } from "src/app/models/zipcode.model";

@Component({
  selector: "app-address-modal",
  templateUrl: "./address.modal.component.html",
  styleUrls: ["./address.modal.component.css"],
  providers: [
    
  ],
})
export class AddressModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("filterForm") filterForm: NgForm;

  protected step = 0;
  protected loaded = true;
  protected disabled : boolean = false;
  
  protected countryList: PickList[]= [];
  protected stateList: PickList[] = [];
  protected cityList: PickList[] = [];
  protected zipList: Zipcode[] = [];
  protected addressTypeList: PickList[] = [];
  protected showAddress2Field: boolean = false;

  countryControl = new FormControl();
  stateControl = new FormControl();
  cityControl = new FormControl();
  zipControl = new FormControl()
;
  protected filteredCountries: Observable<PickList[]>;
  protected filteredStates: Observable<PickList[]> = of([]);
  protected filteredCities: Observable<PickList[]> = of([]);
  protected filteredZips: Observable<Zipcode[]> = of([]);
 
  protected CityAssociated: boolean = false;

  showStateAddButton: boolean = false;
  showCityAddButton: boolean = false;
  showZipAddButton: boolean = false;
  showCountryAddButton: boolean = false;

  selectedCountryID: number = 0 ;
  selectedCityID:number = 0  ;
  selectedStateID: number = 0;
  selectedZipID: number = 0;

  selectedCountry: PickList ;
  selectedState: PickList ;
  selectedCity: PickList ;
  selectedZip: PickList ;

  @ViewChild("formRef") addressForm: NgForm;
  @ViewChild("countryTrigger")
  @ViewChild("cityTrigger")
  @ViewChild("stateTrigger")

  countryAutoCompleteTrigger: MatAutocompleteTrigger;
  cityAutoCompleteTrigger: MatAutocompleteTrigger;
  statesAutoCompleteTrigger: MatAutocompleteTrigger;
  addressControl = new FormControl();
  filteredaddressTypeList: Observable<any[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddressModalComponent>,
    public searchService: SearchService,
    public commonService:CommonService,
    public utilService:UtilityService,
    public notificationService:NotificationService,
    public dialogService:DialogService
  ) { }

  async ngOnInit() {

    if (localStorage.getItem("AddressFieldRequired") === "true") 
    this.showAddress2Field = true;
    this.addressTypeList = this.commonService.getPickListData(
      this.searchService.getTableName(),
      "AddressType"
    );
    if (this.addressTypeList && this.addressTypeList.length > 0) {
      this.filteredaddressTypeList = this.addressControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.DisplayValue),
        map(DisplayValue => DisplayValue ? this._filteredaddressTypeList(DisplayValue) : this.addressTypeList.slice())
      );
    }
    
    this.commonService
        .getPickListApi()
        .then(() => {
          if (
            this.commonService.pickList != undefined &&
            this.commonService.pickList.length > 0
          ) {
            
            this.countryList = this.commonService.getPickListData(
              this.searchService.getTableName(),
              "Country"
            );
            this.addressTypeList = this.commonService.getPickListData(
              this.searchService.getTableName(),
              "AddressType"
            );
            if (this.addressTypeList && this.addressTypeList.length > 0) {
              this.filteredaddressTypeList = this.addressControl.valueChanges.pipe(
                startWith(""),
                map(value => typeof value === 'string' ? value : value?.DisplayValue),
                map(DisplayValue => DisplayValue ? this._filteredaddressTypeList(DisplayValue) : this.addressTypeList.slice())
              );
            }
            this.stateList =[];
            this.cityList = [];
            this.zipList = [];
            this.stateControl.disable();
            this.cityControl.disable();
            this.zipControl.disable();

           
            this.filteredCountries = this.countryControl.valueChanges
            .pipe(
              startWith<string | PickList>(''),
              map(value => typeof value === 'string' ? value : value.DisplayValue),
              map(PickListID => PickListID ? this._filterCountries(PickListID) : this.countryList.slice())
            );
            
             this.commonService
        .getPickListAssociationApi()
        .then(() => { 
          if(this.data.Country > 0){
            this.countrySelectedID();
           
            if(this.data.State > 0){
              this.stateSelectedID();
              if(this.data.City > 0){
              this.citySelectedId();
            }
             if(this.data.Zip > 0){
              this.zipSelectedID();
            }
            }
            
          }
        })
        .catch((err) => {
          this.notificationService.failure(err);
        }); 

          
           
          }
        })
        .catch((err) => {
          this.notificationService.failure(err);
        });

  
  this.filteredStates = this.stateControl.valueChanges
            .pipe(
              startWith<string | PickList>(''),
              map(value => typeof value === 'string' ? value : value.DisplayValue), 
              map(PickListID => PickListID ? this._filterStates(PickListID) : this.stateList.slice())   
            );       
      
  this.filteredCities = this.cityControl.valueChanges
          .pipe(
            startWith<string | PickList>(''),
              map(value => typeof value === 'string' ? value : value.DisplayValue),
              map(PickListID => PickListID ? this._filterCities(PickListID) : this.cityList.slice())
          );

  this.filteredZips = this.zipControl.valueChanges
          .pipe(
            startWith<string | Zipcode>(''),
              map(value => typeof value === 'string' ? value : value.DisplayValue),
              map(PickListID => PickListID ? this._filterZips(PickListID) : this.zipList.slice())
          );

  
  }

  private _filteredaddressTypeList(value: string) {
    if (!this.addressTypeList) {
      return []; 
    }
    return this.addressTypeList.filter(option => 
      option.DisplayValue && option.DisplayValue.toLowerCase().includes(value.toLowerCase())
    );
  }

  displayFn1(selectedoption: number) {
    if(selectedoption) {
    return this.addressTypeList.find(x => x.PickListID === selectedoption).DisplayValue;
    }
  }

  displayCountry(pickList?: PickList): string | undefined {
    return pickList ? pickList.DisplayValue : undefined;
  }

  displayState(pickList?: PickList): string | undefined {
    return pickList ? pickList.DisplayValue : undefined;
  }
  displayCity(pickList?: PickList): string | undefined {
    return pickList ? pickList.DisplayValue : undefined;
  }

  displayZip(pickList?: PickList): string | undefined {
    return pickList ? pickList.DisplayValue : undefined;
  }

  private _filterCountries(value: string): PickList[] {
    this.selectedCountryID = 0;
    const filterValue = String(value);  
    this.resetState();
    this.resetCity();
    this.resetZip();
    var filteredList:PickList[] = [];
    this.countryList.forEach(element => {
      if(String(element.DisplayValue).toLowerCase().substring(0,filterValue.length) == filterValue.toLowerCase()){
        filteredList.push(element);        
      }
    });
    this.showCountryAddButton = true;
    //this.countryList.filter(option => String(option.DisplayValue).toLowerCase().substring(0,filterValue.length) == filterValue.toLowerCase());
    return filteredList;
     
  }

 private _filterStates(value: string): PickList[] {
   this.resetCity();
  
  this.selectedStateID = 0;
   
   if(value == "" || !value){
      this.selectedStateID = 0;
      this.cityControl.disable();
    }
    const filterValue = String(value);    
    var filteredList:PickList[] = [];
    this.stateList.forEach(element => {
      if(String(element.DisplayValue).toLowerCase().substring(0,filterValue.length) == filterValue.toLowerCase()){
        filteredList.push(element);        
      }
    });
    this.showStateAddButton = true;
    
    this.zipList = this.commonService.getZipAssociationList(this.selectedCountryID)
    this.filteredZips = this.zipControl.valueChanges
            .pipe(
              startWith<string | PickList>(''),
              map(value => typeof value === 'string' ? value : value.DisplayValue),
              map(PickListID => PickListID ? this._filterZips(PickListID) : this.zipList.slice())
            );
    
    return filteredList;
  }

  private _filterCities(value: string): PickList[] {
   this.selectedCityID = 0;
    const filterValue = String(value);
    var filteredList:PickList[] = [];
    this.cityList.forEach(element => {
      if(String(element.DisplayValue).toLowerCase().substring(0,filterValue.length) == filterValue.toLowerCase()){
        filteredList.push(element);        
      }
    });
   this.showCityAddButton = true;
    this.zipList = this.commonService.getZipAssociationList(this.selectedStateID)
    this.filteredZips = this.zipControl.valueChanges
            .pipe(
              startWith<string | PickList>(''),
              map(value => typeof value === 'string' ? value : value.DisplayValue),
              map(PickListID => PickListID ? this._filterZips(PickListID) : this.zipList.slice())
            );
    
    return filteredList;

  }


  private _filterZips(value: string): Zipcode[] {
    this.selectedZipID = 0
    const filterValue = String(value);
    var filteredList:Zipcode[] = [];
    this.zipList.forEach(element => {
      if(String(element.DisplayValue).toLowerCase().substring(0,filterValue.length) == filterValue.toLowerCase()){
        filteredList.push(element);        
      }
    });
    if(this.selectedStateID > 0 && this.selectedCityID > 0 )this.showZipAddButton = true;
    this.stateList = this.commonService.getStateOrCityorZipList(this.searchService.getTableName(),"State","Country",this.selectedCountryID)
    this.filteredStates = this.stateControl.valueChanges
            .pipe(
              startWith<string | PickList>(''),
              map(value => typeof value === 'string' ? value : value.DisplayValue),
              map(PickListID => PickListID ? this._filterStates(PickListID) : this.stateList.slice())
            );
    return filteredList;
  }

  

  onNoClick() {  
    if(this.data.isEdit)  this.dialogRef.close(this.data);
    else this.dialogRef.close();
  }

  //On Add New City
  
  onAddNewCity() {
    
    this.dialogService
      .openConfirmDialog(
        "Confirm Adding New City",
        "Are you sure you want to add New City "+this.cityControl.value+"?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          var newCity = new PickList();    
          
          newCity.PickListID = 0;
          newCity.TableName = this.searchService.getTableName();
          newCity.FieldName = "City";
          newCity.DisplayValue = this.cityControl.value;
          newCity.ParentFieldName = "State";
          newCity.ParentFieldValue = this.selectedStateID;
          newCity.Active = 1;
          var cityList = this.commonService.getCityAssociationList(this.selectedStateID);
          var cityExist = [];
          if(cityList.length > 0){
            cityExist = cityList.filter((f) => (f.DisplayValue).toLowerCase() == (this.cityControl.value).toLowerCase());
            if(cityExist.length > 0) 
            newCity.PickListID = cityExist[0].PickListID;
          } 
          this.commonService.setPickListAPI(
            newCity,0
          ).then((s)=> {
              
              if(this.commonService.newOptionID > 0){
                    newCity.PickListID = this.commonService.newOptionID;
                    if(cityExist.length <= 0) this.cityList.push(newCity);             
                    this.selectedCityID = this.commonService.newOptionID;
                    this.data.City = newCity.PickListID;
                    this.data.CityName = newCity.DisplayValue;
                    this.cityControl.setValue(newCity);
                    this.citySelected(this.cityControl);
                  }
          }).
          catch((err) => {
            this.notificationService.failure(err);

          })
        } 
      });

  }

  //On add new State

  onAddNewState() {
    this.dialogService
      .openConfirmDialog(
        "Confirm Adding New State",
        "Are you sure you want to add New State "+this.stateControl.value+"?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          var newState = new PickList();    
          
          newState.PickListID = 0;
          newState.TableName = this.searchService.getTableName();
          newState.FieldName = "State";
          newState.DisplayValue = this.stateControl.value;
          newState.ParentFieldName = "Country";
          newState.ParentFieldValue = this.selectedCountryID;
          newState.Active = 1;
          var stateList = this.commonService.getStateAssociatedCountry(this.selectedCountryID);
          var stateExist = [];
          if(stateList.length > 0){
            stateExist = stateList.filter((f) => (f.DisplayValue).toLowerCase() == (this.stateControl.value).toLowerCase());
            if(stateExist.length > 0) 
            newState.PickListID = stateExist[0].PickListID;
          } 
          this.commonService.setPickListAPI(
            newState,0
          ).then(()=> {
              
               if(this.commonService.newOptionID > 0){
                  newState.PickListID = this.commonService.newOptionID;
                  if(stateExist.length <= 0) this.stateList.push(newState);
                  this.selectedStateID = this.commonService.newOptionID;
                  this.data.State = newState.PickListID;
                  this.data.StateName = newState.DisplayValue;
                  this.stateControl.setValue(newState);
                  this.stateSelected(this.stateControl);
                }
          }).
          catch((err) => {
            this.notificationService.failure(err);

          })
        } 
      });

  }

  //On Add New Country

  onAddNewCountry() {
    this.dialogService
      .openConfirmDialog(
        "Confirm Adding New Country",
        "Are you sure you want to add New Country "+this.countryControl.value+"?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          var newCountry = new PickList();    
          
          newCountry.PickListID = 0;
          newCountry.TableName = this.searchService.getTableName();
          newCountry.FieldName = "Country";
          newCountry.DisplayValue = this.countryControl.value;
          newCountry.ParentFieldName = null;
          newCountry.ParentFieldValue = null;
          newCountry.Active = 1;
          var countryList = this.commonService.getCountryList();
          var countryExist = [];
          if(countryList.length > 0){
            countryExist = countryList.filter((f) => (f.DisplayValue).toLowerCase() == (this.countryControl.value).toLowerCase());
            if(countryExist.length > 0) 
            newCountry.PickListID = countryExist[0].PickListID;
          } 
          this.commonService.setPickListAPI(
            newCountry,0
          ).then(()=> {
              
               if(this.commonService.newOptionID > 0){
                  newCountry.PickListID = this.commonService.newOptionID;
                  if(countryExist.length <= 0) this.countryList.push(newCountry);
                  this.countryControl.setValue(newCountry)
                  this.countrySelected(this.countryControl);
                }
          }).
          catch((err) => {
            this.notificationService.failure(err);

          })
        } 
      });

  }

  //On Add New Zip

  onAddNewZip() {
    this.dialogService
      .openConfirmDialog(
        "Confirm Adding New Zip",
        "Are you sure you want to add New Zip "+this.zipControl.value+" ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          var newZip = new PickList();    
          
          newZip.PickListID = 0;
          newZip.TableName = this.searchService.getTableName();
          newZip.FieldName = "Zip";
          newZip.DisplayValue = this.zipControl.value;
          newZip.ParentFieldName = "City";
          newZip.ParentFieldValue = this.selectedCityID;
          newZip.Active = 1;
          var ZipList = this.commonService.getZipAssociationList(this.selectedCountryID);
          if(ZipList.length > 0){
            var zipExist = ZipList.filter((f) => (f.DisplayValue).toLowerCase() == (this.zipControl.value).toLowerCase());
            if(zipExist.length > 0) newZip.PickListID = zipExist[0].PickListID;
          } 
          this.commonService.setPickListAPI(
            newZip,this.selectedCityID
          ).then((s)=> {
              
               if(this.commonService.newAssociationID > 0){
                  this.commonService
                    .getPickListAssociationApi()
                    .then(() => { 
                         //filter Zip based on selected State if Zip is not chosen
                  this.zipList = [];
                  this.zipList = this.commonService.getZipAssociationList(this.selectedCityID);
                   
                    this.zipList.forEach(element => {
                      if(element.ZipAssociationID == this.commonService.newAssociationID){
                        this.zipControl.setValue(element);  
                        this.zipSelected(this.zipControl);
                      }
                    });
                 
                    })
                    .catch((err) => {
                      this.notificationService.failure(err);
                    });
                 
                 
                }
          }).
          catch((err) => {
            this.notificationService.failure(err);

          })
        } 
      });

  }

  
  //When Country is Selected

  countrySelected(option) {
    this.showCountryAddButton = false;
    this.selectedCountryID = option.value.PickListID;
    this.data.Country = option.value.PickListID;
    this.data.CountryName = option.value.DisplayValue;
    this.stateControl.enable();
    this.zipControl.enable();
    this.stateList = this.commonService.getStateOrCityorZipList(this.searchService.getTableName(),"State","Country",this.selectedCountryID)    

    this.filteredStates = this.stateControl.valueChanges
          .pipe(
            startWith<string | PickList>(''),
            map(value => typeof value === 'string' ? value : value.DisplayValue),
            map(PickListID => PickListID ? this._filterStates(PickListID) : this.stateList.slice())
          );

    this.zipList = this.commonService.getZipAssociationList(this.selectedCountryID);   

    this.filteredZips = this.zipControl.valueChanges
          .pipe(
            startWith<string | PickList>(''),
            map(value => typeof value === 'string' ? value : value.DisplayValue),
            map(PickListID => PickListID ? this._filterZips(PickListID) : this.zipList.slice())
          );
  }

  stateSelected(option) {
    this.selectedStateID = option.value.PickListID;
    this.data.State = option.value.PickListID;
    this.data.StateName = option.value.DisplayValue;
    this.showStateAddButton = false;    
    this.cityControl.enable();

    //filter Cities based on selected State
    this.cityList = this.commonService.getStateOrCityorZipList(this.searchService.getTableName(),"City","State",this.selectedStateID)
    
    this.filteredCities = this.cityControl.valueChanges
          .pipe(
            startWith<string | PickList>(''),
            map(value => typeof value === 'string' ? value : value.DisplayValue),
            map(PickListID => PickListID ? this._filterCities(PickListID) : this.cityList.slice())
          );

  //filter Zip based on selected State if Zip is not chosen
    this.zipList = [];

    this.zipList = this.commonService.getZipAssociationList(this.selectedStateID);
    this.filteredZips = this.zipControl.valueChanges
          .pipe(
            startWith<string | PickList>(''),
            map(value => typeof value === 'string' ? value : value.DisplayValue),
            map(PickListID => PickListID ? this._filterZips(PickListID) : this.zipList.slice())
          );
    
  }

  zipSelected(option) {
    this.selectedZipID = option.value.PickListID;
    this.data.Zip = option.value.PickListID;
    this.data.ZipName = option.value.DisplayValue;
    this.showZipAddButton = false;

    //filter State based on selected Zip if State is not chosen

   this.stateList = this.commonService.getStateAssociationList(option.value.ZipAssociationID);
   this.filteredStates = this.stateControl.valueChanges
            .pipe(
              startWith<string | PickList>(''),
              map(value => typeof value === 'string' ? value : value.DisplayValue),
              map(PickListID => PickListID ? this._filterStates(PickListID) : this.stateList.slice())
            );
            
    //autopopulate State
    
    this.stateControl.setValue(this.stateList[0]);
    this.stateSelected(this.stateControl);

    //filter City based on selected Zip if City is not chosen
    
    this.cityList = this.commonService.getCityAssociationListForZip(option.value.ZipAssociationID);
    this.filteredCities = this.cityControl.valueChanges
            .pipe(
              startWith<string | PickList>(''),
              map(value => typeof value === 'string' ? value : value.DisplayValue),
              map(PickListID => PickListID ? this._filterCities(PickListID) : this.cityList.slice())
            );
      //autopopulate City
        this.cityControl.enable();
        this.cityControl.setValue(this.cityList[0])
        this.citySelected(this.cityControl);
    
  }

  citySelected(option) {
    this.selectedCityID = option.value.PickListID;
    this.data.City = option.value.PickListID;
    this.data.CityName = option.value.DisplayValue;
    this.showCityAddButton = false; 
    //filter Zip based on selected City if Zip is not chosen
    
    this.zipList = this.commonService.getZipAssociationList(this.selectedCityID);
      this.filteredZips = this.zipControl.valueChanges
              .pipe(
                startWith<string | PickList>(''),
                map(value => typeof value === 'string' ? value : value.DisplayValue),
                map(PickListID => PickListID ? this._filterZips(PickListID) : this.zipList.slice())
              );
    
    this
  }

   countrySelectedID() {
    this.showCountryAddButton = false;
    this.selectedCountryID = this.data.Country;
    
    this.stateList = this.commonService.getStateOrCityorZipList(this.searchService.getTableName(),"State","Country",this.selectedCountryID)
    
    
    this.filteredStates = this.stateControl.valueChanges
          .pipe(
            startWith<string | PickList>(''),
            map(value => typeof value === 'string' ? value : value.DisplayValue),
            map(PickListID => PickListID ? this._filterStates(PickListID) : this.stateList.slice())
          );

    this.zipList = this.commonService.getZipAssociationList(this.selectedCountryID);   

    this.filteredZips = this.zipControl.valueChanges
          .pipe(
            startWith<string | PickList>(''),
            map(value => typeof value === 'string' ? value : value.DisplayValue),
            map(PickListID => PickListID ? this._filterZips(PickListID) : this.zipList.slice())
          );

    this.countryList.forEach(element => {
      if(element.PickListID == this.selectedCountryID) this.selectedCountry = element;
    });
    this.countryControl.setValue(this.selectedCountry);
    this.stateControl.enable();
    this.zipControl.enable();
    this.showCountryAddButton = false;
    
  }

  stateSelectedID() {
   this.selectedStateID = this.data.State;
    this.cityControl.enable();
    this.stateList.forEach(element => {
      if(element.PickListID == this.selectedStateID) this.selectedState = element;
    });
    this.showStateAddButton = false;  
    //filter Cities based on selected State
    this.cityList = this.commonService.getStateOrCityorZipList(this.searchService.getTableName(),"City","State",this.selectedStateID)
    
    this.filteredCities = this.cityControl.valueChanges
          .pipe(
            startWith<string | PickList>(''),
            map(value => typeof value === 'string' ? value : value.DisplayValue),
            map(PickListID => PickListID ? this._filterCities(PickListID) : this.cityList.slice())
          );

  //filter Zip based on selected State if Zip is not chosen
    this.zipList = [];

    this.zipList = this.commonService.getZipAssociationList(this.selectedStateID);
    this.filteredZips = this.zipControl.valueChanges
          .pipe(
            startWith<string | PickList>(''),
            map(value => typeof value === 'string' ? value : value.DisplayValue),
            map(PickListID => PickListID ? this._filterZips(PickListID) : this.zipList.slice())
          );
    
  }

  zipSelectedID() {
    this.selectedZipID = this.data.Zip;
    this.zipList.forEach(element => {
      if(element.PickListID == this.selectedZipID) this.selectedZip = element;
    });
    this.showZipAddButton = false;
    //filter State based on selected Zip if State is not chosen

  //  this.stateList = this.commonService.getStateAssociationList(this.data.Zip);
  //  this.filteredStates = this.stateControl.valueChanges
  //           .pipe(
  //             startWith<string | PickList>(''),
  //             map(value => typeof value === 'string' ? value : value.DisplayValue),
  //             map(PickListID => PickListID ? this._filterStates(PickListID) : this.stateList.slice())
  //           );
            
    //autopopulate State
    
    // this.stateControl.setValue(this.stateList[0])
    // this.stateSelected(this.stateControl);

    //filter City based on selected Zip if City is not chosen
    
    // this.cityList = this.commonService.getCityAssociationListForZip(this.data.Zip);
    // this.filteredCities = this.cityControl.valueChanges
    //         .pipe(
    //           startWith<string | PickList>(''),
    //           map(value => typeof value === 'string' ? value : value.DisplayValue),
    //           map(PickListID => PickListID ? this._filterCities(PickListID) : this.cityList.slice())
    //         );
    //   //autopopulate City
    //     this.cityControl.enable();
    //     this.cityControl.setValue(this.cityList[0])
    //     this.citySelected(this.cityControl);
    
  }

  citySelectedId() {
    this.selectedCityID = this.data.City;
    this.data.City = this.data.City;
     this.cityList.forEach(element => {
      if(element.PickListID == this.selectedCityID) this.selectedCity = element;
    });
    this.showCityAddButton = false; 
    this.zipList = this.commonService.getZipAssociationList(this.selectedCityID);
      this.filteredZips = this.zipControl.valueChanges
              .pipe(
                startWith<string | PickList>(''),
                map(value => typeof value === 'string' ? value : value.DisplayValue),
                map(PickListID => PickListID ? this._filterZips(PickListID) : this.zipList.slice())
              );
    
    
  }


  resetState(){    
    this.stateControl.setValue("");
    this.selectedStateID = 0;
    this.stateControl.disable();
  }
  resetCity(){
    this.cityControl.setValue("");
    this.selectedCityID = 0;
    this.cityControl.disable();
  }
  resetZip(){
    this.zipControl.setValue("");
    this.selectedZipID = 0;
    this.zipControl.disable();
  }

  onAddAddress(){ 
    if(this.zipControl.value == "") {
     this.data.Zip = 0;
     this.data.ZipName = "";
    }
    setTimeout(() => {
      this.dialogRef.close(this.data);
    },1000)
  }  
  
}
