import { Injectable, Output, EventEmitter } from "@angular/core";
import {
  HttpHeaders,
  HttpResponse,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { SpinnerService } from "../services/spinner.service";
import { Router } from "@angular/router";
import { UserService } from "./user.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  private pageName: string;
  public message: string;
  protected searchText: string;
  public searchRecords: Array<any>;
  public smartSearchPromise: Promise<any>;
  public key: string;
  private organizationID: number;
  private entryPointID: number;
  private isSearchBarHidden: boolean = false;

  public selectedApplicationID: number;

  private filterData: Array<{
    UserID: number;
    SearchFilterID: number;
    UserSearchFilterID: number;
    Label: string;
    Description: string;
    EnabledByDefault: number;
    Active: boolean;
  }>;

  // @Output() search: EventEmitter<string>;
  @Output() searchInitiated: EventEmitter<boolean>;
  @Output() entryPointDataChanged: EventEmitter<boolean>;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private spinnerService: SpinnerService,
    private userService: UserService,
    private router: Router
  ) {
    this.filterData = [];
    this.searchRecords = [];

    this.searchInitiated = new EventEmitter<boolean>();
    this.entryPointDataChanged = new EventEmitter<boolean>();
  }

  // get DB Table Name
  public getTableName(): string {
    let tableName = "";
    switch (this.pageName.toLowerCase()) {
      case "Person Of Interest".toLowerCase():
        tableName = "PersonOfInterest";
        break;
      case "Transportation People".toLowerCase():
        tableName = "TransportationPeople";
        break;
      case "POIRequester".toLowerCase():
        tableName = "POIRequester";
        break;
    }

    return tableName;
  }


  // call smart search records through api and return promise
  public smartSearchAPI(key: string): Promise<any> {
    this.key = key;
    const apiURL = environment.getAPI("getSmartRelevanceSearch");

    const isTimeField = new RegExp(
      /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
    ).test(this.key);

    if (isTimeField) {
      let tempDate = new Date();
      tempDate = new Date(
        tempDate.getFullYear() +
          "-" +
          tempDate.getMonth() +
          "-" +
          tempDate.getDate() +
          " " +
          this.key
      );
      this.key = this.datePipe
        .transform(tempDate, "yyyy-MM-dd HH:mm", "+0000")
        .split(" ")[1]
        .trim();
    }

    const body: {
      UserID: number;
      TableName: string;
      Key: string;
      ScreenName: string;
    } = {
      UserID: this.userService.userID,
      TableName: this.getTableName(),
      Key: this.key,
      ScreenName: this.pageName,
    };

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.searchRecords = success.body;
            this.spinnerService.setIsLoading(false);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    this.smartSearchPromise = promise;
    return promise;
  }
  eni;

  // calls search filter api and returns promise
  public getFiltersAPI(): Promise<any> {
    const apiURL = environment.getAPI("getSearchFilter");
    const body = {
      UserID: this.userService.userID,
      TableName: this.getTableName(),
      ScreenName: this.getPageName(),
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.filterData = success.body;
            // console.log(success.body);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // call update search filter api and returns promise
  public updateFilterAPI(filters: Array<any>): Promise<any> {
    const apiURL = environment.getAPI("userSearchFilterCreateUpdate");

    const body = [];

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    filters.forEach((item) => {
      body.push({
        UserID: this.userService.userID,
        SearchFilterID: item.SearchFilterID,
        UserSearchFilterID:
          item.UserSearchFilterID === null ? 0 : item.UserSearchFilterID,
        Active: item.Active,
      });
    });

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            // console.log(this.filterData);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // returns search filter array
  public getFilters() {
    return [...this.filterData];
  }

  // the method is invoked to set the page name and it assumes that the search bar would be displayed
  public setPageName(pageName: string) {
    this.pageName = pageName;
    this.key = null;
    this.isSearchBarHidden = false;
  }

  public switchApplication(applicationID: number) {
    const applicationRoute = this.userService.userApplications
      .filter((ua) => ua.ApplicationID === applicationID)
      .map((ua) => ua.Route);

    this.selectedApplicationID = applicationID;
    this.router.navigate(applicationRoute);
  }
  // the method is invoked to hide the search bar
  public hideSearch() {
    this.isSearchBarHidden = true;
  }

  // the method is invoked to return a boolean which tells if the search bar should be displayed in the nav bar
  public displaySearchBar(): boolean {
    return !this.isSearchBarHidden;
  }

  // return the page name
  public getPageName(): string {
    return this.pageName;
  }

  public setOrganizationID(orgId: number) {
    this.organizationID = orgId;
    this.key = null;
  }

  public getOrganizationID(): number {
    return this.organizationID;
  }

  public setEntryPointID(epId: number) {
    this.entryPointID = epId;
  }

  public getEntryPointID(): number {
    return this.entryPointID;
  }
}
