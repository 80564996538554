import { Injectable, EventEmitter, Output } from "@angular/core";
import { PersonOfInterest } from "../models/person-of-interest.model";
import {
  HttpHeaders,
  HttpClient,
  HttpResponse,
  HttpRequest,
} from "@angular/common/http";
import { NotificationService } from "./notification.service";
import { PickList } from "../models/pickList.model";
import { SearchService } from "./search.service";
import { Photos } from "../models/photo.model";
import { Attachments } from "../models/attachments.model";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";
import { PickListAssociation } from "../models/pickListAssociation.model";
import { zip } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public pickList: PickList[] = [];
  public siteList : any = [];
  public pickListAssociation : PickListAssociation[] = [];
  public UnitOfHeight: number = 1; // inches
  public primaryPhotoFileRecords: Array<any>;
  public allFiles: Array<any>;
  public recordID: number = 0;
  @Output() newOptionAdded: EventEmitter<boolean>;
  public newOptionID: number = 0;
  public newAssociationID: number = 0;
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private searchService: SearchService,
    private userService: UserService
  ) {
    this.newOptionAdded = new EventEmitter<boolean>();
  }

  public getPickListApi(tableName?) {
    const apiURL = environment.getAPI("getPickList");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

   const body = tableName ? { TableName: tableName } : { TableName: this.searchService.getTableName() }

    const promise = new Promise<
      {
        PickListID: number;
        TableName: string;
        FieldName: string;
        DisplayValue: number;
        Active: boolean;
        ParentFieldName:string,
        ParentFieldValue:number
      }[]
    >((resolve, reject) => {
      this.http
        .post(
          apiURL,
          body,
          httpOptions
        )
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.pickList = success.body.map((e) => ({
              PickListID: e.PickListID,
              TableName: e.TableName,
              FieldName: e.FieldName,
              DisplayValue: e.DisplayValue,
              Active: e.Active,
              ParentFieldName:e.ParentFieldName,
              ParentFieldValue:e.ParentFieldValue
            }));
          
            resolve();
            //return this.pickList;
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // get dropdowns based on fieldName from  pick list table
  public getPickListData(tableName, fieldName) {
    return this.pickList.filter(
      (f) => f.TableName == tableName && f.FieldName == fieldName
    );
  }
  public getPickListDataPhone() {
    return this.pickList;
  }

  // get State dropdown based on Organization
  public getStateOrCityorZipList(tableName, fieldName, parentFieldName, parentFieldValue) {
    
    return this.pickList.filter(
      (f) => f.TableName == tableName && f.FieldName == fieldName && f.ParentFieldName == parentFieldName && f.ParentFieldValue == parentFieldValue
    );
  }

  public getPrimaryPhotoFiles(rowsOfPage: any) {
    const apiURL = environment.getAPI("getFilesDetails");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const apiBody = rowsOfPage.map((record) => ({
      ApplicationName: this.searchService.getTableName(),
      RecordID: record.RecordID,
      Photos:
        record.PrimaryPhotoArray.length > 0
          ? record.PrimaryPhotoArray.map((a) => ({
              PhotoID: a.PhotoID,
              S3KeyName: a.S3KeyName,
              XID: a.XID,
              LogicalFileName: a.LogicalFileName,
              Description: a.Description,
              Active: a.Active,
            }))
          : [],
      Attachments: [],
    }));

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, apiBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.primaryPhotoFileRecords = success.body;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getAllFiles(
    photoArray: Photos[],
    attachmentArray: Attachments[],
    recordID: number
  ) {
    if (recordID == 0) {
      recordID = this.recordID;
    } else {
      this.recordID = recordID;
    }
    const apiURL = environment.getAPI("getFilesDetails");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const apiBody = [
      {
        ApplicationName: this.searchService.getTableName(),
        RecordID: recordID,
        Photos: photoArray.map((a) => ({
          PhotoID: a.PhotoID,
          S3KeyName: a.S3KeyName,
          XID: a.XID,
          LogicalFileName: a.LogicalFileName,
          Description: a.Description,
          Active: a.Active,
        })),

        Attachments: attachmentArray.map((a) => ({
          AttachmentID: a.AttachmentID,
          S3KeyName: a.S3KeyName,
          XID: a.XID,
          LogicalFileName: a.LogicalFileName,
          Description: a.Description,
          Active: a.Active,
        })),
      },
    ];

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, apiBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.allFiles = success.body;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public uploadFiles(presigned, file: File) {

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": file.type,
        Accept: "*/*",
        Referer: "",
      }),
      observe: "response",
    };
    const req = new HttpRequest("PUT", presigned, file, httpOptions);

    const promise = new Promise((resolve, reject) => {
      this.http
        .request(req)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // successs
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getPickListAssociationApi() {
     const apiURL = environment.getAPI("getPickListAssociation");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const promise = new Promise<
      PickListAssociation[]
    >((resolve, reject) => {
      this.http
        .post(
          apiURL,
          { TableName: this.searchService.getTableName() },
          httpOptions
        )
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.pickListAssociation = success.body;
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

   //get country list
   public getCountryList() {
    return this.pickListAssociation;
  }
  //get state associate with country
  public getStateAssociatedCountry(countryID) {
    var  list:PickListAssociation[] = this.pickListAssociation.filter(
      (f) =>  f.PickListID == countryID
    );
    if(list.length > 0)
    return list[0].AssociatedState;
    else return [];
  }
  // get City Associated dropdowns

  public getCityAssociationList(pickListID) {    
    var  list:PickListAssociation[] = this.pickListAssociation.filter(
      (f) =>  f.PickListID == pickListID
    );
    if(list.length > 0)
    return list[0].AssociatedCity;
    else return [];
  }  

  // get State Associated dropdowns

  public getStateAssociationList(zipAssociationID) {
    var  list:PickListAssociation[] = this.pickListAssociation.filter(
      (f) =>  f.ZipAssociationID == zipAssociationID
    );
    if(list.length > 0)
    return list[0].AssociatedState;
    else return [];
  } 

  // get City Associated dropdowns based on Zip

  public getCityAssociationListForZip(zipAssociationID) {    
    var  list :PickListAssociation[]= this.pickListAssociation.filter(
      (f) =>  f.ZipAssociationID == zipAssociationID
    );
    if(list.length > 0)
    return list[0].AssociatedCity;
    else return [];
  }  

   // get Zip Associated dropdowns 

  public getZipAssociationList(pickListID) {
   var  list:PickListAssociation[] = this.pickListAssociation.filter(
      (f) =>  f.PickListID == pickListID
    );
    if(list.length > 0)
    return list[0].AssociatedZip;
    else return [];
  } 

  //api to create picklist  

  public setPickListAPI(
    pickList : PickList,
    cityID : number
  ) {
   // const apiURL = "https://mibqahhybk.execute-api.us-west-2.amazonaws.com/dev/master/setPickList";
    const apiURL = environment.getAPI("setPickList");
    this.newOptionID =  0;
    this.newAssociationID = 0;
    const postBody = {
      TableName : pickList.TableName,
      FieldName :pickList.FieldName,
      DisplayValue :pickList.DisplayValue,
      Active :pickList.Active,
      UserID :this.userService.userID,
      ParentFieldName :pickList.ParentFieldName,
      ParentFieldValue :pickList.ParentFieldValue,
      City:cityID,
      PickListID: pickList.PickListID
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            const res = success.body;
           
            this.newOptionAdded.emit(true);
            this.newOptionID = success.body.PickListID;
            this.newAssociationID = success.body.ZipAssociationID;
            this.notificationService.success(success.body.Message);

            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }


  //api to fetch sites

   public getSiteListApi() {
    //const apiURL = "https://mibqahhybk.execute-api.us-west-2.amazonaws.com/dev/general/getSite";
    const apiURL = environment.getAPI("getSite");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<any>((resolve, reject) => {
      this.http
        .post(
          apiURL,
          { SiteID: null },
          httpOptions
        )
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.siteList = success.body;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }
}
