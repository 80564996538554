export const apiPaths = {
  //master
  getPickList: "general/master/getPickList",
  getRegionTree: "general/master/getRegionTree",
  getPickListAssociation : "general/master/getPickListAssociation",
  setPickList : "general/master/setPickList",

 //transportationPeople
  transportationPeopleCreate:
    "general/transportationPeople/transportationPeopleCreate",
  transportationPeopleUpdate:
    "general/transportationPeople/transportationPeopleUpdate",
    
  getTransportationPeople: "general/transportationPeople/getTransportationPeople",

  transportationPeopleCheckDuplicateSSN:"general/transportationPeople/transportationPeopleCheckDuplicateSSN",

  //personOfInterest
  personOfInterestCreate: "general/personofinterest/personOfInterestCreate",
  personOfInterestUpdate: "general/personofinterest/personOfInterestUpdate",
  getPOI: "general/personofinterest/getPOI",  
  getPOIRegion: "general/personofinterest/getPOIRegion",

 

  //general
  createUserActionHistory: "general/general/createUserActionHistory",
  getFilesDetails: "general/general/getFilesDetails",
  getSmartRelevanceSearch: "general/general/getSmartRelevanceSearch",  
  getSite: "general/general/getSite",

  //Others

   verifyUserADAndGetApplicationList:
    "general/login/verifyUserADAndGetApplicationList",
  getSearchFilter: "general/getSearchFilter",
  userSearchFilterCreateUpdate: "general/userSearchFilterCreateUpdate",
};


// export const apiPaths = {
//   //master
//   getPickList: "master/getPickList",
//   getRegionTree: "master/getRegionTree",
//   getPickListAssociation : "master/getPickListAssociation",
//   setPickList : "master/setPickList",

//  //transportationPeople
//   transportationPeopleCreate:
//     "transportationPeople/transportationPeopleCreate",
//   transportationPeopleUpdate:
//     "transportationPeople/transportationPeopleUpdate",
    
//   getTransportationPeople: "transportationPeople/getTransportationPeople",


//   //personOfInterest
//   personOfInterestCreate: "personofinterest/personOfInterestCreate",
//   personOfInterestUpdate: "personofinterest/personOfInterestUpdate",
//   getPOI: "personofinterest/getPOI",  
//   getPOIRegion: "personofinterest/getPOIRegion",

 

//   //
//   createUserActionHistory: "general/createUserActionHistory",
//   getFilesDetails: "general/getFilesDetails",
//   getSmartRelevanceSearch: "general/getSmartRelevanceSearch",  
//   getSite: "general/getSite",

//   //Others

//    verifyUserADAndGetApplicationList:
//     "login/verifyUserADAndGetApplicationList",
//   getSearchFilter: "getSearchFilter",
//   userSearchFilterCreateUpdate: "userSearchFilterCreateUpdate",
// };
