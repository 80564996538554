import { POIRequester } from './poi-requester.model';
import { Photos } from './photo.model';
import { Attachments } from './attachments.model';
import { Address } from './address.model';
import { Phone } from './phone.model';
import { Notes } from './note.model';
import { Event } from './event.model';

export class Driver {  
  
  constructor(    
    public TransportationPeopleID: number,
    public XID: String,
    public ResourceCode : string,
    public GroupCode : string,
    public FirstName: string,
    public MiddleName: string,
    public LastName: string,
    public SSN: string,
    public DLNumber: string,
    public DLExpirationDate : string,
    public DLClassification : number ,
    public DLState : number,
    //public DLEndorsement : number ,
    public DLEndorsement: Array<{
      TransportationPeopleDLEndorsementID: number;
      DLEndorsementID: number;
      DLEndorsementName: string;
      Active: number;
    }>,
    public DLMedCertExpirationDt : string ,
    //public DLRestrictions : number,
    public DLRestrictions: Array<{
      TransportationPeopleDLRestrictionID: number;
      DLRestrictionID: number;
      DLRestrictionName: string;
      Active: number;
    }>,
    public Local399Grouping : string,
    public Status : number,
    public BirthDate : string,
    public ResourceType : number,
    public Memo: string,
    public Email : string,
    public EmgContactName : string,
    public EmgContactRelationship: string,
    public EmgContactPhone : string,
    public HireDate : string,
    public SelfCertification : number,
    public LastWorked : string,
    public PullNoticeDate : string,
    public AnnualReviewDate : string,
    public PrimaryPhotoID : string,
    public Active: string,
    public UserID: string,   
    public PhotosArray: Array<Photos>,
    public AttachmentsArray: Array<Attachments>,
    public RecordID:string,
    public PrimaryPhotoArray:Array<Photos>,
    public TransportationPeopleAddress : Array<Address>,
    public TransportationPeoplePhone: Array<Phone>,
    public TransportationPeopleNote :Array<Notes>,
    public TransportationPeopleEvent: Array<Event>,
  ) {
    this.HireDate = new Date().toISOString();
    
  }
  



}
