import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatExpansionPanel,
  MatDialogRef,
  MatCheckboxChange,
} from "@angular/material";
import { NgForm } from "@angular/forms";
import { HttpErrorResponse } from '@angular/common/http';
import { PersonOfInterestComponent } from '../person-of-interest/person-of-interest.component';
import { DialogService } from '../services/dialog.service';
import { SearchService } from '../services/search.service';
import { NotificationService } from '../services/notification.service';


@Component({
  selector: "app-search-filter",
  templateUrl: "./search-filter.modal.component.html",
  styleUrls: ["./search-filter.modal.component.css"],
})
export class SearchFilterModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("filterForm") filterForm: NgForm;

  protected step = 0;
  protected loaded = false;
  protected filterCount = undefined;

  public isFilterLoading = true;
  public isSaving = false;
  public searchFilters = [];

  constructor(
    public dialogRef: MatDialogRef<PersonOfInterestComponent>,
    public searchService: SearchService,
    protected notificationService: NotificationService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.searchFilters = [];
    this.searchService
      .getFiltersAPI()
      .then(() => {
        this.isFilterLoading = false;
        this.searchFilters = [...this.searchService.getFilters()];

        // console.log(this.searchFilters);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  onNoClick(): void {
    // if (this.filterForm.dirty) {
    //   const popupRef = this.dialogService.openConfirmDialog(
    //     "Unsaved Changes Detected",
    //     "Are you sure you want to discard any changes?"
    //   );
    //   popupRef.afterClosed().subscribe((confirmation: boolean) => {
    //     if (confirmation) {
    //       this.dialogRef.close();
    //     }
    //   });
    // } else {
    //   this.dialogRef.close();
    // }
    this.dialogRef.close();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  onSaveFilter() {
    // console.log(this.searchFilters);
    this.isSaving = true;
    this.searchService
      .updateFilterAPI(this.searchFilters)
      .then(() => {
        this.notificationService.success(
          "Search Filters Have been Saved Successfully."
        );
        this.dialogRef.close(true);
        document.getElementById("searchButton").click();
      })
      .catch((err: HttpErrorResponse) => {
        this.isSaving = false;
        this.notificationService.failure(err.message);
      });
  }

  onSelectionChanged(event: MatCheckboxChange, index: number) {
    // console.log(event.checked);
    this.searchFilters[index].Active = event.checked;
  }

  // protected incrementFilterCount(label) {
  //   if (this.filterCount === undefined) this.filterCount = 0;
  //   else ++this.filterCount;

  //   console.log(label);
  //   console.log(this.filterCount);
  // }
}
