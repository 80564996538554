import { POIRequester } from './poi-requester.model';
import { Photos } from './photo.model';
import { Attachments } from './attachments.model';
import { Phone } from './phone.model';
import { Address } from './address.model';
import { Notes } from './note.model';
import { Event } from './event.model';


export class PersonOfInterest {  
  
  constructor(
  
  public PersonOfInterestID: number,
  public FirstName: string,  
  public MiddleName: string,
  public LastName: string,
  public Suffix: string,  
  public Company: string,
  public Phone: string,
  public Email: string,
  public DriverLicenseNum: string,
  public BirthDay : number,
  public BirthMonth : number,
  public BirthYear : number,
  public Gender: string,
  public HeightInches: number,
  public HeightMeters: number,
  public WeightPounds: number,
  public WeightKilograms: number,
  public Hair: number,
  public Eyes: number,
  public Race: number,
  public SSN: string,
  public Notes: string,
  public NotificationEmail: string,
  public PrimaryPhotoID: string,
  public RestrictionType: number,
  public RestrictedOn: string,
  public RestrictedEndDate: string,
  public RestrictedReason: number,
  public RestrictedReasonActive: number,
  public Aliases:string,
  public AliasArray: Array<{
    Active: number
    AliasID: number
    Name: string
    UserID: number
    Autologous?: number
  }>,
  public POIRequesterArray:POIRequester ,
  public PhotosArray: Array<Photos>,
  public AttachmentsArray: Array<Attachments>,
  public XID: string,
  public RecordID : string,
  public PrimaryPhotoArray:Array<Photos>,
  public POIAddress : Array<Address>,
  public POIPhone: Array<Phone>,
  public POINote :Array<Notes>,
  public POIEvent: Array<Event>,
  public POIRegion:Array<{
    PersonOfInterestRegionID: number
    RegionID: string
    Active: number
  }>
  ) {
    
    
  }
  



}
