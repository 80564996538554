import { debugOutputAstAsTypeScript } from '@angular/compiler';

export class POIRequester {  
  
  public POIRequesterID: number;
  public FirstName: string;
  public LastName: string;
  public Email: string;
  public Phone: string;
  public Company: string;
  public Department: string;
  public Position: string;
  public Active: string;
  

  constructor( POIRequesterID: number,
     FirstName: string,
     LastName: string,
     Email: string,
     Phone: string,
     Company: string,
     Department: string,
     Position: string,
     Active){
       this.POIRequesterID = POIRequesterID;
       this.FirstName = FirstName;
       this.LastName = LastName;
       this.Email = Email;
       this.Phone = Phone;
       this.Company = Company;
       this.Department =Department;
       this.Position = Position;
       this.Active = Active;

  }
 

}
