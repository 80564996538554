import { Component, OnInit, Inject, DoCheck } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { PersonOfInterest } from "src/app/models/person-of-interest.model";
import { DialogService } from "src/app/services/dialog.service";
import { PickList } from "src/app/models/pickList.model";
import { GridApi } from "ag-grid-community";
import { RequiredFieldsService } from "src/app/services/required-fields.service";
import { PersonOfInterestService } from "src/app/services/person-of-interest.service";
import { CommonService } from "src/app/services/common.service";
import { NotificationService } from "src/app/services/notification.service";
import { Base64Service } from "src/app/services/base64.service";
import { Driver } from 'src/app/models/driver.model ';
import { UpdateDriverModalComponent } from '../update-driver/update-driver-modal.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: "app-view-driver-modal",
  templateUrl: "./view-driver-modal.component.html",
  styleUrls: ["./view-driver-modal.component.css"],
})
export class ViewDriverModalComponent implements OnInit {
  protected step = 0;
  protected driver: Driver;
  protected genderList: PickList[];
  protected restrictedReasonList: PickList[];
  protected restrictedTypeList: PickList[];
  protected raceList: PickList[];
  protected hairList: PickList[];
  protected eyesList: PickList[];

  protected addedAliases: Array<String> = [];
  protected alias: string;
  protected restricted: boolean = false;
  protected isRestrictedReasonloading: boolean = false;
  protected isGenderLoading: boolean = false;
  protected gridApi: GridApi;
  protected defaultImageIndex = undefined;
  protected maxDate;
  protected signedURL;
  searchService: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Driver,
    protected dialogRef: MatDialogRef<ViewDriverModalComponent>,
    protected dialog: MatDialog,
    protected requiredFieldService: RequiredFieldsService,
    protected personOfInterestService: PersonOfInterestService,
    protected commonService: CommonService,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected base64Service: Base64Service,
    protected datePipe : DatePipe
  ) {}

  ngOnInit(): void {
    this.driver = { ...this.data };
    this.driver.BirthDate = this.formatDate(this.driver.BirthDate);
    this.driver.DLExpirationDate = this.formatDate(this.driver.DLExpirationDate);
    if (this.driver.PrimaryPhotoArray.length > 0) {
      this.signedURL = this.driver.PrimaryPhotoArray[0].SignedURL;
    } else {
      this.signedURL = '../../../../assets/profile-picture/profileImage.png';
    }
  
      this.commonService
        .getPickListApi()
        .then(() => {
          if (
            this.commonService.pickList != undefined &&
            this.commonService.pickList.length > 0
          ) {
            this.genderList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Gender"
            );
            this.raceList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Race"
            );
            this.restrictedReasonList = this.commonService.getPickListData(
              "PersonOfInterest",
              "RestrictedReason"
            );
            this.restrictedTypeList = this.commonService.getPickListData(
              "PersonOfInterest",
              "RestrictionType"
            );
          }
        })
        .catch((err) => {
          this.notificationService.failure(err);
        });
    

   
  }
  columnDefs = [
    {
      headerName: "Name",
      field: "fileName",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      cellRenderer: "hyperLinkComponent",
      width: 200,
    },
    {
      headerName: "Description",
      field: "description",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      editable: true,
      resizable: true,
      width: 567,
    },
    {
      headerName: "",
      cellRenderer: "buttonRenderer",
      resizable: true,
      cellRendererParams: {
        onClick: this.onBtnClick.bind(this),
        label: "Remove",
      },
      width: 100,
    },
    {
      headerName: "",
      field: "delete",
      disabled: true,
      resizable: true,
      hide: true,
    },
  ];
  onBtnClick(e) {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //close
  close() {
    this.onNoClick();
  }
  //opens edit modal, send row data to modal
  updateDriver() {
    this.onNoClick();
    this.dialog.open(UpdateDriverModalComponent, {
      data: this.driver,
    });
  }
  formatDate(date:string){
  
    const formattedDate =(date === '0000-00-00 00:00:00' || date === '') ? '' : moment(date).format('MM/DD/YYYY')
    //  this.datePipe.transform(date, "MM/dd/yyyy");
    // return formattedDate ? formattedDate : "";
    return formattedDate;
  }
  formatTime(date:string){
    const formattedTime =(date === '0000-00-00 00:00:00' || date === '') ? '' : moment(date).format('DD/MM/YYYY h:mm A')
    return formattedTime;
  }
  panelExpanded(){}
}
